import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton, Box } from '@material-ui/core'
import Controls from "./Controls";
import Alert from '@material-ui/lab/Alert';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import Announcement from "@material-ui/icons/Announcement";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";


const useStyles = makeStyles((theme) => ({
  dialog: {
    //padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "20rem",
    minWidth: "20rem",
  },
  dialogTitle: {
    textAlign: "center",
  },
  successIcon: {
    color: "#7cb342",
    fontSize: "8rem",
  },
  saveIcon: {
    color: "#ff6f00",
    fontSize: "8rem",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  titleIcon: {
    //backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,

    "&:hover": {
      //backgroundColor: theme.palette.secondary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function ConfirmDialog(props) {

    const { confirmDialog, setConfirmDialog, loading, setLoading } = props;
    const classes = useStyles()


    return (
      <Dialog
        open={confirmDialog.isOpen}
        onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        classes={{ paper: classes.dialog }}
      >
        <DialogContent className={classes.dialogContent}>
          <IconButton disableRipple className={classes.titleIcon}>
            {loading === "done" ? (
              <CheckCircleIcon className={classes.successIcon} />
            ) : (
              <Announcement className={classes.saveIcon} />
            )}
          </IconButton>
          <Typography variant="h6">{confirmDialog.title}</Typography>
          <Typography variant="body2">{confirmDialog.subTitle}</Typography>
        </DialogContent>
        {loading === "yes" ? (
          <Box width="100%" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          ""
        )}
        <DialogActions className={classes.dialogAction}>
          {loading === "done" ? (
            <Controls.Button
              text="Done"
              color="primary"
              onClick={() => {
                setLoading("");
                setConfirmDialog({ ...confirmDialog, isOpen: false });
              }}
            />
          ) : (
            <>
              <Controls.Button
                text="No"
                color="default"
                onClick={() =>
                  setConfirmDialog({ ...confirmDialog, isOpen: false })
                }
              />
              <Controls.Button
                text="Yes"
                color="primary"
                onClick={confirmDialog.onConfirm}
              />
            </>
          )}
        </DialogActions>
      </Dialog>
    );
}
