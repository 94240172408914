import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import Moment from "react-moment";
import { addComment, deleteComment } from "../apiCalls/projectCalls";
import { CircularProgress } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import WarningIcon from "@material-ui/icons/Warning";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  List,
  Button,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { fetchComments } from "../apiCalls/projectCalls";
import { isLoggedIn } from "../helpers/authenticationHelp";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";

function AlertDelete(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  commentList: {
    position: "relative",
    overflow: "auto",
    maxHeight: "70vh",
    padding: theme.spacing(2),
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const CommentSectionGRC = ({
  projectId,
  setCommentList,
  currentVersion,
  version,
  status
}) => {
  const classes = useStyles();
  const [commentsList, setCommentsList] = useState("");
  const [loading, setLoading] = useState(true);
  const [commentLoading, setCommentLoading] = useState(true);
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [comId, setComId] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [decision, setDecision] = useState("");
  const [response, setResponse] = useState({ request: false, error: false });
  const [canComment, setCanComment] = useState(false);
  const [final, setFinal] = useState(false);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  const handleChange = (event) => {
    setDecision(event.target.value);
    setError("");
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setComId("");
  };
  const deleteButton = (e) => {
    e.preventDefault();
    setOpenAlert(false);
    setCommentLoading(true);
    deleteComment(comId, projectId).then((data) => {
      if (data) {
        if (!data.error) {
          setComId("");
          setCanComment(false);
          setRefresh(!refresh);
          setOpen(false);
          setOpenAlert(true);
        } else {
          setError("comment-delete-error");
          setOpen(false);
          setOpenAlert(true);
          setCommentLoading(true);
        }
      } else {
        setError("comment-delete-error");
        setOpen(false);
        setOpenAlert(true);
      }
    });
  };

  const insertComment = (e) => {
    e.preventDefault();
    setCommentLoading(true);
    if (comment.trim().length < 5 || comment.trim().length > 1000) {
      setError("comment");
      setCommentLoading(false);
    } else if (
      decision !== "Approved" &&
      decision !== "Approved with Minor Changes" &&
      decision !== "Represent" &&
      decision !== "Rejected"
    ) {
      setError("decision");
      setCommentLoading(false);
    } else {
      setCommentLoading(true);

      const data = { comment, decision, final };
      addComment(data, projectId).then((data) => {
        if (data) {
          if (!data.error) {
            setComment("");
            setDecision("");
            setRefresh(!refresh);
            setResponse({ request: true, error: false });
            setCanComment(false);
          } else {
            setCommentLoading(false);
            setResponse({ request: true, error: true });
          }
        }
      });
    }
  };
  useEffect(() => {
    fetchComments(projectId, currentVersion).then((data) => {
      if (data) {
        if (!data.error) {
          if (data.length < 1) {
            setCanComment(true);
          } else {
            if (currentVersion !== version) {
              setCanComment(false);
            } else {
              let test = false;
              data.map((comment, index) => {
                if (
                  comment.author._id === isLoggedIn().user._id &&
                  comment.version === version
                ) {
                  test = true;
                }
                if (!test && index === data.length - 1) setCanComment(true);
              });
            }
          }
          setCommentList({ loaded: true, data: data });
          setCommentsList(data);
          setCommentLoading(false);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    });
  }, [refresh, currentVersion]);
  return (
    <>
      {response.request && !commentLoading && (
        <>
          {response.error ? (
            <Alert
              style={{ marginBottom: 20 }}
              onClose={() => setResponse({ request: false, error: false })}
              severity="error"
            >
              Comment Insertion Failed
            </Alert>
          ) : (
            <Alert
              onClose={() => setResponse({ request: false, error: false })}
              severity="success"
            >
              Comment Added Successfully!
            </Alert>
          )}
        </>
      )}
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {error === "comment-delete-error" ? (
          <AlertDelete onClose={handleCloseAlert} severity="error">
            Comment Deletion Failed!
          </AlertDelete>
        ) : (
          <AlertDelete onClose={handleCloseAlert} severity="success">
            Comment Deleted Successfully!
          </AlertDelete>
        )}
      </Snackbar>
      {loading && (
        <Paper className={classes.loading} elevation={0}>
          <CircularProgress size={60} thickness={5} />
        </Paper>
      )}
      {!loading && (
        <>
          {(isLoggedIn().user.roles.includes("Admin") ||
            isLoggedIn().user.roles.includes("Professor")) &&
            !commentLoading &&
            canComment && (
              <Grid container spacing={3} style={{ marginBottom: 20 }}>
                <Grid item xs={12}>
                  {status!=="Completed"&&
                  <TextField
                    id="comment"
                    placeholder="Write a comment"
                    label="Comment"
                    margin="dense"
                    variant="outlined"
                    multiline
                    fullWidth
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                      setError("");
                    }}
                    rowsMax="4"
                    error={error === "comment"}
                    helperText={
                      error === "comment"
                        ? "Comment must be between 5-1000 characters"
                        : `${comment.length}/1000`
                    }
                  />}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl style={{ minWidth: "15rem" }}>
                    <InputLabel id="demo-simple-select-label">
                      Decision
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={decision}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Approved"}>Accepted</MenuItem>
                      <MenuItem value={"Approved with Minor Changes"}>
                        Accepted with Minor Changes
                      </MenuItem>
                      <MenuItem value={"Represent"}>
                        Accepted with Major Changes
                      </MenuItem>
                      <MenuItem value={"Rejected"}>Rejected</MenuItem>
                    </Select>
                    <FormHelperText error={error === "decision"}>
                      {error === "decision" ? "Select your decision" : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={insertComment}
                    variant="outlined"
                    color="primary"
                  >
                    Add New Comment
                  </Button>
                </Grid>
              </Grid>
            )}
          {!commentLoading && commentsList.length === 0 && (
            <Alert severity="info">No Comments at the moment!</Alert>
          )}
          {!commentLoading && commentsList.length > 0 && (
            <List
              component="div"
              disablePadding
              className={classes.commentList}
            >
              <Box
                justifyContent="center"
                display="flex"
                fontWeight="fontWeightMedium"
                fontSize={16}
              >
                Previous Comments
              </Box>

              {commentsList
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((comment, index) => (
                  <React.Fragment key={index}>
                    <ListItem className={classes.list} alignItems="flex-start">
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Box display="flex">
                              <Box flexGrow={1}>
                                {" "}
                                <Typography
                                  component="span"
                                  variant="subtitle2"
                                >
                                  {comment.author.name}
                                </Typography>
                              </Box>
                              <Box>
                                {" "}
                                <Typography
                                  component="span"
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {
                                    <Moment format="MMM D, YYYY, h:mm A">
                                      {comment.createdAt}
                                    </Moment>
                                  }
                                </Typography>
                              </Box>
                            </Box>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                              gutterBottom
                            >
                              {comment.decision === "Represent"
                                ? "Accepted with Major Changes"
                                : comment.decision ===
                                  "Approved with Minor Changes"
                                ? "Accepted with Minor Changes"
                                : comment.decision === "Approved"
                                ? "Accepted"
                                : comment.decision === "Rejected"
                                ? "Rejected"
                                : ""}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={
                          <Typography variant="body2">
                            {comment.text}
                          </Typography>
                        }
                      />
                      {isLoggedIn().user._id === comment.author._id &&
                        version === currentVersion && (
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              size="small"
                              onClick={handleClickOpen}
                            >
                              <DeleteIcon
                                onClick={() => {
                                  setComId(comment._id);
                                }}
                              />
                            </IconButton>
                          </ListItemSecondaryAction>
                        )}
                    </ListItem>
                    <Divider component="li" />
                  </React.Fragment>
                ))}
            </List>
          )}
          {commentLoading && (
            <Paper className={classes.loading} elevation={0}>
              <CircularProgress size={60} thickness={5} />
            </Paper>
          )}
        </>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          alignText="center"
          id="alert-dialog-title"
          style={{ textAlign: "center" }}
        >
          <>
            <WarningIcon style={{ fontSize: "6rem", color: "#fb8c00" }} />
            <Typography variant="h6"> Delete Comment?</Typography>
          </>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting an comment will permanently remove it from the portal. You
            can not undo this action. Are you sure you want to delete this
            comment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            cancel
          </Button>
          <Button
            onClick={deleteButton}
            variant="contained"
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CommentSectionGRC;