import React from 'react'
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const Copyright = () => {
    return (<>
        <Typography variant="body2" color="textSecondary" align="center" >
            {'Developed by '}
            <Link color="inherit" target="_blank" href="https://www.linkedin.com/in/naveed-abdullah-0007/">
                Naveed Abdullah
            </Link>{' & '}
            <Link color="inherit" target="_blank" href="https://www.linkedin.com/in/rohantaufique/">
                Rohan Taufique
            </Link>{' '}
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" >
            {'Copyright © '}
            <Link color="inherit" href="">
                DCS&SE Department
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
        </>
    );
}

export default Copyright;