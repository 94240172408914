import React, { useEffect, useState } from 'react'
//---
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import CommitteeDetail from './CommitteeDetail'
import { getCommittees } from '../apiCalls/committeeCalls'
import { isLoggedIn } from "../helpers/authenticationHelp"


const styles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    appbar: {
        //width: '100%',
        //height: '100%',
        overflow: "hidden",
        // color: "green",
        backgroundColor: "inherit",
        width: "100%",
        justifyContent: "center"
    }
}))
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

//this will show all the committees specific to that badge
export default function CommitteeSubView({ degree, updated, setUpdated }) {
    const classes = styles()

    const [committees, setCommittes] = useState([]);
    const [loading, setLoading] = useState(true)
    const [fetchError, setFetchError] = useState("")
    const [value, setValue] = React.useState(0);



    const handleChange = (event, newValue) => {
        setValue(newValue);
    }
    useEffect(() => {
        //calling the api to get data
        const token = isLoggedIn().token;
        getCommittees(token, degree)
            .then(data => {
                if (data) {
                    if (data.error) {
                        setFetchError(data.error)
                        setLoading(false)
                    } else {
                        setCommittes(data.committees)
                        setLoading(false)
                    }
                } else {
                    setFetchError("Unable to load data")
                }
            })
    }, [updated])
    return (
        <div className={classes.root} >
            {loading ?
                <Box className={classes.root}>
                    <CircularProgress />
                </Box>
                :
                fetchError
                    ?
                    <div className={classes.root}>
                        <Alert severity="error">
                            {fetchError}
                        </Alert>
                    </div>
                    :
                    <AppBar position="static" className={classes.appbar} >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            scrollButtons="auto"
                            style={{ overflow: "auto", width: "100%" }}
                            centered
                        >
                            {committees.map((committee, key) => (
                                <Tab key={key} label={committee.name} {...a11yProps(key)} />
                            ))
                            }
                        </Tabs>
                        {committees.map((committee, key) => (

                            <TabPanel key={key} value={value} index={key} style={{ width: "100%" }}>
                                <CommitteeDetail committee={committee} updated={updated} setUpdated={setUpdated} />
                            </TabPanel>
                        ))
                        }
                    </AppBar>
            }
        </div>
    )
}
