import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  TableRow,
  CardHeader,
  Typography,
  TableCell,
  Grid,
  Button,
  Table,
  TableBody,
  Box,
  TableHead,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core";
import { Container } from "@material-ui/core/Container";
import FormDialog from "./FormDialog";
import AddCommitteeForm from "./AddCommitteeForm";
import ConfirmDialog from "./ConfirmDialog";
import { isLoggedIn } from "../helpers/authenticationHelp";
import { deleteCommittee } from "../apiCalls/committeeCalls";

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  card: {},
}));

export default function CommitteeDetail({ committee, setUpdated, updated }) {
  const classes = styles();

  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  //const [done, setDone]= useState(false)

  const onDelete = (id) => {
    setLoading("yes");
    setConfirmDialog({
      title: "Committee is being deleted please wait",
      subTitle: "Please wait!",
      isOpen: true,
    });
    const token = isLoggedIn().token;
    deleteCommittee(token, id).then((data) => {
      if (data) {
        if (data.error) {
          setConfirmDialog({
            title: data.error,
            subTitle: "Task Incomplete",
            isOpen: true,
          });
          setLoading("done");
        } else {
          setConfirmDialog({
            title: data.message,
            subTitle: "Task Completed!",
            isOpen: true,
          });
          setLoading("done");
          setUpdated(updated - 1);
        }
      } else {
        setConfirmDialog({
          title: "Failed to connect",
          subTitle: "Task Incomplete",
          isOpen: true,
        });
        setLoading("done");
      }
    });
  };

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Committee Detail
          </Typography>
          <Grid container>
            <Grid
              container
              item
              xs={12}
              sm={5}
              spacing={2}
              alignItems="flex-start"
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>{committee.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>
                      {committee.committeeType === "OC" && "Other Committee"}
                      {committee.committeeType === "DC" && "Defence Committee"}
                      {committee.committeeType === "PC" && "Proposal Committee"}
                      {committee.committeeType === "GRC" && "GRC Committee"}

                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Chairman</TableCell>
                    <TableCell>{committee.chairman.name}</TableCell>
                  </TableRow>
                  {committee.committeeType !== "GRC" && (
                    <TableRow>
                      <TableCell>Coordinator</TableCell>
                      <TableCell>{committee.coordinator.name}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Grid>
            <Grid container item xs={12} sm={2} spacing={2}></Grid>
            {committee.committeeType !== "GRC" ? (
            <Grid
              container
              item
              xs={12}
              sm={5}
              spacing={2}
              alignItems="flex-start"
            >
              {committee.committeeMembers &&
                committee.committeeMembers.length === 0 && (
                  <Typography>No members in committee</Typography>
                )}
              <Table>
                <TableBody>
                  {committee.committeeMembers &&
                    committee.committeeMembers.map((member, key) => (
                      <TableRow>
                        <TableCell>{key === 0 ? "Members" : ""}</TableCell>
                        <TableCell>{member.name}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
            ):(
                <Grid
                container
                item
                xs={12}
                sm={5}
                spacing={2}
                alignItems="flex-start"
              >
                {committee.grcCoordinator &&
                  committee.grcCoordinator.length === 0 && (
                    <Typography>No members in committee</Typography>
                  )}
                <Table>
                  <TableBody>
                    {committee.grcCoordinator &&
                      committee.grcCoordinator.map((member, key) => (
                        <TableRow>
                          <TableCell>{key === 0 ? "Coordinators" : ""}</TableCell>
                          <TableCell>{member.name}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Grid>  
            )}
          </Grid>
        </CardContent>
      </Card>
      <div
        style={{ margin: "1rem", display: "flex", justifyContent: "flex-end" }}
      >
        <FormDialog
          Form={
            <AddCommitteeForm
              Committee={committee}
              updated={updated}
              setUpdated={setUpdated}
            />
          }
          Title={"Edit Committee"}
          Heading={`${committee.name} (${committee.committeeType})`}
        />
        <Button
          variant="outlined"
          color="secondary"
          style={{marginLeft:"1rem" }}
          onClick={() => {
            setConfirmDialog({
              isOpen: true,
              title: "Are you sure to see this record?",
              subTitle: "You can't undo this operation",
              onConfirm: () => {
                onDelete(committee._id);
              },
            });
          }}
        >
          Delete Committee
        </Button>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
}
