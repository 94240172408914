import React, { useState, useEffect } from "react";
//---
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import { TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import PageLayout from "../components/PageLayout";
import MuiTableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import FormDialog from "../components/FormDialog";
import ProjectView from "../components/ProjectView";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip"

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import MuiAlert from "@material-ui/lab/Alert";
import TablePagination from "@material-ui/core/TablePagination/TablePagination";
//----
import { getSignupData } from "../apiCalls/settingCalls";
import { WAIT, ERROR, SUCCESS, REJECTED, PENDING, APPROVED } from "../variables/enums";
import SearchProfessor from "../components/SearchProfessor"
import { getAllProjects, updateProjectStatus } from "../apiCalls/projectCalls";
import Taskboard from "./TaskBoard/Taskboard.js";
import ProjectViewGRC from "./ProjectViewGRC"

const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
        overflow: "hidden",
    },
    bold: {
        fontWeight: "bold",
    },
});

const TableCell = withStyles({
    root: {
        //borderBottom: "none",
        padding: ".8rem",
    },
})(MuiTableCell);

const styles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "green",
    },
    root: {
        marginTop: theme.spacing(2),
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    "@keyframes delayedHide": {
        from: { opacity: 1 },
        to: { opacity: 0 },
    },

    autoHide: {
        animationName: "delayedHide",
        animationDuration: "300ms",
        animationDelay: (props) => props.delay || "2s",
        animationTimingFunction: "ease-out",
        animationFillMode: "forwards",
    },
    table: {
        minWidth: 650,
    },
    formControl: {
        width: "100%"
    },
}));
export default function RowUGPCAdmin({ project }) {
    const classes101 = styles()
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const [supervisor, setSupervisor] = useState({})
    const [requestStatus, setRequestStatus] = useState({ msg: "", status: "" })

    const changeProjectStatus = (projectId, status) => {
        const projectStatus = { status, supervisor };
        setRequestStatus({ status: WAIT, msg: "" })
        updateProjectStatus(projectId, projectStatus).then(data => {
            if (data) {
                if (data.error) {
                    setRequestStatus({ status: ERROR, msg: data.error })
                }
                project.projectStatus = data.status
                project.supervisor.name = supervisor.name
                setRequestStatus({ status: SUCCESS, msg: data.message })
            } else {
                setRequestStatus({ status: ERROR, msg: "Unable to connect!" })
            }
        })
    }

    return (
        <React.Fragment>
            <TableRow className={classes.root} style={{ backgroundColor: "#e2ffde" }} onClick={() => setOpen(!open)}>
                <TableCell component="th" scope="row">
                    <h3>{project.title}</h3>
                </TableCell>
                <TableCell ><h4>{project.groupName}</h4></TableCell>
                <TableCell ><h4>{project.department}</h4></TableCell>
                <TableCell ><h4>{new Date(project.uploadedAt).toDateString()}</h4></TableCell>
                <TableCell><h4><Chip label={project.projectStatus} style={{ color: "white", backgroundColor: `${project.projectStatus === "Pending" ? "#3d5afe" : project.projectStatus === "Approved" ? "#357a38" : project.projectStatus === "Ready for Defence" ? "#ff5722" : project.projectStatus === "Completed" ? "#004d40" : "#212121"}` }} /></h4></TableCell>

                <TableCell align="right">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Card style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                            <div style={{ margin: "1rem" }}>
                                <Box >
                                    <Typography className={classes.bold}>Chariman Controls</Typography>
                                </Box>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Project Status</TableCell>
                                            <TableCell>{project.projectStatus}</TableCell>
                                        </TableRow>
                                        {project.projectStatus === "Approved" &&
                                            <TableRow>
                                                <TableCell>Supervisor</TableCell>
                                                <TableCell>{project.supervisor.name}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                                {project.projectStatus === PENDING &&
                                    <SearchProfessor groupMember={supervisor}
                                        setGroupMember={setSupervisor}
                                        label={"Project Supervisor"}
                                    />
                                }
                                {requestStatus.status === WAIT &&
                                    <Box className={classes101.root} display="flex" justifyContent="center">
                                        <CircularProgress />
                                    </Box>
                                }
                                {project.projectStatus === PENDING &&
                                    <Box mt={1} mb={1}>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button variant="outlined" color="secondary"
                                                onClick={() => changeProjectStatus(project._id, REJECTED)}>
                                                Reject Project
                                            </Button>
                                            <Button variant="outlined" color="primary"
                                                style={{ marginLeft: "1rem" }}
                                                disabled={supervisor._id ? false : true}
                                                onClick={() => changeProjectStatus(project._id, APPROVED)}>
                                                Approve Project
                                            </Button>
                                        </div>
                                    </Box>
                                }
                                {(project.projectStatus === APPROVED || project.projectStatus === REJECTED) &&
                                    <Box mt={1} mb={1}>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button variant="outlined" color="secondary"
                                                onClick={() => changeProjectStatus(project._id, PENDING)}>
                                                Revert Decision
                                            </Button>
                                        </div>
                                    </Box>
                                }
                            </div>
                        </Card>
                        <Box mb={2}>
                            <ProjectViewGRC project={project} />
                        </Box>

                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}


