import React from 'react';
import styled from 'styled-components';
import Task from './Task.js';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';



const TaskList = styled.div`
  padding: 8px;
  background-color: ${props => (props.isDraggingOver ? '#fafafa  ' : props.color)};
  flex-grow: 1;
  overflow:auto;
  height: 22rem;
`;
const useStyles = makeStyles(theme => ({
    column: {
        margin: theme.spacing(1),
        width:"100%",
        minWidth:"300px",
        height: "30rem",
        display: "flex",
        flexDirection: "column",
        color: "#fafafa",



    },

}))
const Column = (props) => {
    const classes = useStyles();
    var style = { color: "", backgroundColor: "" };
    if (props.id == "pending") {
        style.color = "#1976d2";
        style.backgroundColor = "#fff"
    }
    else if (props.id == "completed") {
        style.color = "#512da8";
        style.backgroundColor = "#fff";
    } else {
        style.color = "#d32f2f";
        style.backgroundColor = "#fff";
    }

    return (
        <Paper
            className={classes.column}
            elevation={3}
            style={{ backgroundColor: style.color }}
        >
            <Box display="flex" justifyContent="center" mt={2} mb={2}>
                <Typography variant="button">{props.id}</Typography>
            </Box>

            <TaskList color={style.backgroundColor}>
                {props.tasks.map((task, index) => (
                    <Task key={task._id} task={task} index={index} admin={props.admin} projectId={props.projectId} refreshTaskboard={props.refreshTaskboard} setRefreshTaskboard={props.setRefreshTaskboard} />
                ))}
            </TaskList>
        </Paper>
    );

}
export default Column;