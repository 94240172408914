import {Redirect} from "react-router-dom"
import Cookie from "js-cookie"

export const isLoggedIn = () => {

    
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("jwt")) {
    if (localStorage.getItem("expire_time")) {
      const expireTime = parseInt(localStorage.getItem("expire_time"));
      const timeNow = parseInt(Date.now());
      if (expireTime < timeNow) {
        const data = JSON.parse(localStorage.getItem("jwt"));
        data.token = "";
        localStorage.setItem("jwt", JSON.stringify(data));
        localStorage.removeItem("expire_time");
        localStorage.removeItem("currentRole");
        return <Redirect to="/login" />;
      } else {
        return JSON.parse(localStorage.getItem("jwt"));
      }
    } else {
      <Redirect to="/login" />;
      return JSON.parse(localStorage.getItem("jwt"));
    }
  } else return false;
};

export const roleNow = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("currentRole")) {
    return localStorage.getItem("currentRole");
  } else return false;
};
export const changeRole = (role) => {
  if (role === "Professor" || role === "Program-Office" || role === "Admin") {
    localStorage.setItem("currentRole", role);
    return true;
  }
  return false;
};