const { isLoggedIn } = require("../helpers/authenticationHelp");
const { serverURL } = require("./helper");

export const fetchNotEnrolledStudents = async () => {
  isLoggedIn();
  return await fetch(`${serverURL}/notEnrolled/${isLoggedIn().user._id}`, {
    method: "GET",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
