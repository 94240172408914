import React, { useState, useEffect } from "react";
//---
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip"
import TableBody from "@material-ui/core/TableBody";
import { TableRow, TextareaAutosize, TextField } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EditIcon from "@material-ui/icons/Edit";
import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import MuiTableCell from "@material-ui/core/TableCell";
import ProjectView from "./ProjectView";
import Snackbar from "@material-ui/core/Snackbar";

//---
import {
  projectsByCommittee,
  updateProjectStatus,
  appointEvaluators,
  projectMarks,
  updateGRCProjectStatus,
} from "../apiCalls/projectCalls";
import { addComment } from "../apiCalls/projectCalls";
import { isLoggedIn } from "../helpers/authenticationHelp";
import SearchProfessor from "./SearchProfessor";
import SearchEvaluator from "./SearchEvaluator";
import ProjectViewGRC from "./ProjectViewGRC";
import {
  WAIT,
  ERROR,
  SUCCESS,
  REJECTED,
  PENDING,
  APPROVED,
} from "../variables/enums";
const GreenButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
const materialTheme = createMuiTheme({
  palette: {
    primary: green,
  },
  overrides: {
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: "white",
      },
      toolbarTxt: {
        color: "#c8e6c9",
      },
    },
    MuiTabs: {
      scroller: {
        color: "#eeeeee",
      },
    },
  },
});
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  bold: {
    fontWeight: "bold",
  },
});

const TableCell = withStyles({
  root: {
    //borderBottom: "none",
    minWidth: "15rem",
    padding: ".8rem",
  },
})(MuiTableCell);

const styles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "green",
  },
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  "@keyframes delayedHide": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },

  autoHide: {
    animationName: "delayedHide",
    animationDuration: "300ms",
    animationDelay: (props) => props.delay || "2s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
}));

export default function RowGRCCommittee({
  project,
  committee,
  refresh,
  setRefresh,
}) {
  const classes101 = styles();
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const [supervisor, setSupervisor] = useState({});
  const [requestStatus, setRequestStatus] = useState({ msg: "", status: "" });
  const [Internal, setInternal] = useState({});
  const [InternalDate, setInternalDate] = useState(Date.now());
  const [InternalVenue, setInternalVenue] = useState("");

  const [External, setExternal] = useState({});
  const [grcExternal, setGRCExternal] = useState({});
  const [finalComment, setFinalComment] = useState("");
  const [ExternalDate, setExternalDate] = useState(Date.now());
  const [grcExternalDate, setGRCExternalDate] = useState(Date.now());

  const [ExternalVenue, setExeternalVenue] = useState("");
  const [grcExternalVenue, setGRCExeternalVenue] = useState("");

  const [internalMarks, setInternalMarks] = useState(0);
  const [externalMarks, setExternalMarks] = useState(0);
  const [externalGRCMarks, setExternalGRCMarks] = useState(0);

  const [errorIn, setErrorIn]= useState("")
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const changeProjectStatus = (projectId, status) => {
    setErrorIn("")
    setError("")
    // we need comments as well
    if (finalComment.length < 50) {
      setError("Add a valid comment!");
      setErrorIn("finalComment")

    } else {
      const data = { comment: finalComment, decision: status, final:true };
      setLoading(true)
      addComment(data, projectId).then((data) => {
        if (data) {
          if (data.error) {
            setError(data.error);
            setOpenSnack(true);
            setLoading(false)
          } else {
            // comment added changing the status
            const projectStatus = { status };
            setRequestStatus({ status: WAIT, msg: "" });
            updateGRCProjectStatus(projectId, projectStatus).then((data) => {
              if (data) {
                if (data.error) {
                  setRequestStatus({ msg: data.error, status: ERROR });
                  setOpenSnack(true);
                  setLoading(false)
                } else {
                  // when it was successsful
                  project.projectStatus = status;
                  setRequestStatus({ msg: "Status Updated!", status: SUCCESS });
                  setLoading(false)
                }
              } else {
                setRequestStatus({
                  msg: "Failed to connect to server",
                  status: ERROR,
                });
                setLoading(false)
                setOpenSnack(true);
              }
            });
          }
        }else {
          setError("Connection error!");
          setOpenSnack(true);
          setLoading(false)
        }
      });
    }
  };
  const markAsComplete = (e) => {
    e.preventDefault();
    // we check if the marks are correctly distributed

    if (
      parseInt(internalMarks) < 1 ||
      parseInt(internalMarks) > 80 ||
      parseInt(externalMarks) < 1 ||
      parseInt(externalMarks) > 80 ||
      parseInt(externalGRCMarks) < 1 ||
      parseInt(externalGRCMarks) > 80 ||
      parseInt(internalMarks) +
        parseInt(externalMarks) +
        parseInt(externalGRCMarks) <
        3 ||
      parseInt(internalMarks) +
        parseInt(externalMarks) +
        parseInt(externalGRCMarks) >
        98
    ) {
      setError("Invalid Marks Distribution!");
      setOpenSnack(true);
    } else {
      // now we can update the marks
      setLoading(true);
      const projectMarking = { internalMarks, externalMarks, externalGRCMarks };
      projectMarks(project._id, projectMarking).then((data) => {
        if (data) {
          if (data.error) {
            setError(data.error);
            setOpenSnack(true);
            setLoading(false);
          } else {
            setError(data.message);
            project.marks.internal = internalMarks;
            project.marks.external = externalMarks;
            project.marks.externalGRC= externalGRCMarks;
            project.projectStatus="Completed"
            setLoading(false);
          }
        } else {
          setError("Connection Error!");
          setOpenSnack(true);
          setLoading(false);
        }
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const currentDate = Date.now();
    const exDate = new Date(ExternalDate);
    const inDate = new Date(InternalDate);
    const exGRCDate = new Date(grcExternalDate);

    //incase the dates are older than now
    if (
      currentDate > inDate.getTime() ||
      currentDate > exGRCDate.getTime() ||
      currentDate > exDate.getTime()
    ) {
      setError("Invalid Dates for Evaluation");
      setOpenSnack(true);
    } else if (
      ExternalVenue.length < 5 ||
      InternalVenue.length < 5 ||
      grcExternalVenue.length < 5
    ) {
      setError("Invalid Venue provided! (Minimum 5 characters)");
      setOpenSnack(true);
    } else {
      setLoading(true);
      const evaluationData = {
        internal: {
          examiner: Internal._id,
          date: InternalDate,
          venue: InternalVenue,
        },
        external: {
          examiner: External._id,
          date: ExternalDate,
          venue: ExternalVenue,
        },
        grcExternal: {
          examiner: grcExternal._id,
          date: grcExternalDate,
          venue: grcExternalVenue,
        },
      };
      appointEvaluators(project._id, evaluationData).then((data) => {
        if (data) {
          if (data.error) {
            setError(data.error);
            setLoading(false);
            setOpenSnack(true);
          } else {
            setRefresh(refresh + 1);
            setLoading(false);
          }
        } else {
          setError("Failed to connect to server!");
          setOpenSnack(true);
          setLoading(false);
        }
      });
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root} style={{ backgroundColor: "#e2ffde" }} onClick={() => setOpen(!open)}>
        <TableCell component="th" scope="row">
          <h3>{project.title}</h3>
        </TableCell>
        <TableCell>
          <h4>
            <Chip
              label={
                project.projectStatus === "Represent"
                  ? "Accepted with Major Changes"
                  : project.projectStatus === "Approved with Minor Changes"
                  ? "Accepted with Minor Changes"
                  : project.projectStatus === "Approved"
                  ? "Accepted"
                  : project.projectStatus === "Rejected"
                  ? "Rejected"
                  : project.projectStatus
              }
              style={{ color: "white", backgroundColor: `${project.projectStatus === "Pending" ? "#3d5afe" : project.projectStatus === "Approved" ? "#357a38" : project.projectStatus === "Ready for Defence" ? "#ff5722" : project.projectStatus === "Completed" ? "#004d40" :"#212121"}`}}
            />
          </h4>
        </TableCell>
        <TableCell>
          <h4>{project.department}</h4>
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {(committee.chairman === isLoggedIn().user._id ||
              committee.grcCoordinator.includes(isLoggedIn().user._id)) && (
              <Card style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <div style={{ margin: "1rem" }}>
                  <Box>
                    <Typography
                      className={classes.bold}
                      style={{ marginBottom: "2rem" }}
                    >
                      Coordinator Controls
                    </Typography>
                  </Box>
                  {/* now we will ge the project for inital evaluation*/}
                  {project.projectStatus === "Ready for Initial Approval" && (
                    <>
                      <TextField
                        id="outlined-basic"
                        label="Final Comments"
                        variant="outlined"
                        style={{ width: "100%" }}
                        multiline
                        rows={2}
                        required
                        value={finalComment}
                        onChange={(e) => {
                          setFinalComment(e.target.value);
                          setErrorIn("");
                        }}
                        error={errorIn === "finalComment"}
                        helperText={`${finalComment.length}/1000. (50 characters minimum)`}
                      />
                      {/* don;t show on loading */}
                      {loading ? (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "1rem",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "1rem",
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{ marginRight: "1rem" }}
                            onClick={() => {
                              changeProjectStatus(project._id, "Approved");
                            }}
                          >
                            Accepted
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            style={{ marginRight: "1rem" }}
                            onClick={() => {
                              changeProjectStatus(
                                project._id,
                                "Approved with Minor Changes"
                              );
                            }}
                          >
                            Accepted with Minor Changes
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            style={{ marginRight: "1rem" }}
                            onClick={() => {
                              changeProjectStatus(project._id, "Represent");
                            }}
                          >
                            Accepted with Major Changes
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              changeProjectStatus(project._id, "Rejected");
                            }}
                          >
                            Rejected
                          </Button>
                        </Box>
                      )}
                    </>
                  )}

                  {project.projectStatus === "Ready for Defence" &&
                    // either we will apoint them or they are already appointed
                    (project.internal.examiner &&
                    project.external.examiner &&
                    project.grcExternal.examiner ? (
                      <>
                        <Table>
                          <TableRow style={{ width: "100%" }}>
                            <TableCell>Project Supervisor</TableCell>
                            <TableCell>{project.supervisor.name}</TableCell>
                            <TableCell style={{ paddingLeft: "5rem" }}>
                              {project.marks.supervisor}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Project Internal</TableCell>
                            <TableCell>
                              {project.internal.examiner.name}
                            </TableCell>
                            {project.marks.internal ? (
                              <TableCell style={{ paddingLeft: "5rem" }}>
                                {project.marks.internal}
                              </TableCell>
                            ) : (
                              <TableCell style={{ paddingLeft: "4rem" }}>
                                <form
                                  className={classes.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    id="standard-basic"
                                    label="Marks"
                                    type="number"
                                    min="0"
                                    value={internalMarks}
                                    onChange={(e) =>
                                      setInternalMarks(e.target.value)
                                    }
                                    InputProps={{
                                      inputProps: { min: 1, max: 60 },
                                    }}
                                  />
                                </form>
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell>Project External</TableCell>
                            <TableCell>
                              {project.external.examiner.name}
                            </TableCell>
                            {project.marks.external ? (
                              <TableCell style={{ paddingLeft: "5rem" }}>
                                {project.marks.external}
                              </TableCell>
                            ) : (
                              <TableCell style={{ paddingLeft: "4rem" }}>
                                <form
                                  className={classes.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    id="standard-basic"
                                    label="Marks"
                                    type="number"
                                    min="0"
                                    value={externalMarks}
                                    onChange={(e) =>
                                      setExternalMarks(e.target.value)
                                    }
                                    InputProps={{
                                      inputProps: { min: 1, max: 60 },
                                    }}
                                  />
                                </form>
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell>Project External</TableCell>
                            <TableCell>
                              {project.grcExternal.examiner.name}
                            </TableCell>
                            {project.marks.externalGRC ? (
                              <TableCell style={{ paddingLeft: "5rem" }}>
                                {project.marks.externalGRC}
                              </TableCell>
                            ) : (
                              <TableCell style={{ paddingLeft: "4rem" }}>
                                <form
                                  className={classes.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    id="standard-basic"
                                    label="Marks"
                                    type="number"
                                    min="0"
                                    value={externalGRCMarks}
                                    onChange={(e) =>
                                      setExternalGRCMarks(e.target.value)
                                    }
                                    InputProps={{
                                      inputProps: { min: 1, max: 60 },
                                    }}
                                  />
                                </form>
                              </TableCell>
                            )}
                          </TableRow>
                        </Table>
                        <Box
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {loading ? (
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1rem",
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <>
                              {!(
                                project.marks.internal && project.marks.external
                              ) && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  href="#contained-buttons"
                                  style={{ color: "white", marginTop: "1rem" }}
                                  onClick={markAsComplete}
                                >
                                  Mark Project as Complete
                                </Button>
                              )}
                            </>
                          )}
                        </Box>
                      </>
                    ) : (
                      <>
                        <form
                          onSubmit={handleSubmit}
                          autoComplete="off"
                          style={{ margin: "2rem" }}
                        >
                          {/* an internal examiner will be assigned */}
                          <SearchProfessor
                            groupMember={Internal}
                            setGroupMember={setInternal}
                            label={"Internal Evaluator"}
                            style={{ margin: "1rem 1rem 1rem 0rem" }}
                          />
                          {/* date and venu is also  required */}
                          <Box style={{ display: "flex" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <ThemeProvider theme={materialTheme}>
                                <DateTimePicker
                                  style={{
                                    width: "14rem",
                                    marginRight: "auto",
                                  }}
                                  disablePast
                                  value={InternalDate}
                                  onChange={(e) => {
                                    setInternalDate(e);
                                  }}
                                  label="Internal Time"
                                  fullWidth
                                  format="MMMM d, yyyy, h:mm a"
                                  variant="inline"
                                  allowKeyboardControl
                                  animateYearScrolling
                                />
                              </ThemeProvider>
                            </MuiPickersUtilsProvider>
                            <TextField
                              value={InternalVenue}
                              onChange={(e) => setInternalVenue(e.target.value)}
                              multiline
                              rows="1"
                              variant="outlined"
                              size="small"
                              label="Internal Venue"
                              style={{ marginTop: ".5rem", minWidth: "14rem" }}
                            />
                          </Box>
                          {/* an external examiner will be assigned */}
                          <SearchEvaluator
                            groupMember={External}
                            setGroupMember={setExternal}
                            label={"External Evaluator"}
                            style={{ marginTop: "1rem" }}
                          />
                          {/* date and venu is also  required */}
                          <Box style={{ display: "flex" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <ThemeProvider theme={materialTheme}>
                                <DateTimePicker
                                  style={{
                                    width: "14rem",
                                    marginRight: "auto",
                                  }}
                                  disablePast
                                  value={ExternalDate}
                                  onChange={(e) => {
                                    setExternalDate(e);
                                  }}
                                  label="External Time"
                                  fullWidth
                                  format="MMMM d, yyyy, h:mm a"
                                  variant="inline"
                                  allowKeyboardControl
                                  animateYearScrolling
                                />
                              </ThemeProvider>
                            </MuiPickersUtilsProvider>
                            <TextField
                              value={ExternalVenue}
                              onChange={(e) =>
                                setExeternalVenue(e.target.value)
                              }
                              variant="outlined"
                              size="small"
                              label="External Venue"
                              style={{ marginTop: ".5rem", minWidth: "14rem" }}
                            />
                          </Box>
                          {/* a grc external examiner will be assigned */}
                          <SearchEvaluator
                            groupMember={grcExternal}
                            setGroupMember={setGRCExternal}
                            label={"External Evaluator"}
                            style={{ marginTop: "1rem" }}
                          />
                          {/* date and venu is also  required */}
                          <Box style={{ display: "flex" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <ThemeProvider theme={materialTheme}>
                                <DateTimePicker
                                  style={{
                                    width: "14rem",
                                    marginRight: "auto",
                                  }}
                                  disablePast
                                  value={grcExternalDate}
                                  onChange={(e) => {
                                    setGRCExternalDate(e);
                                  }}
                                  label="External Time"
                                  fullWidth
                                  format="MMMM d, yyyy, h:mm a"
                                  variant="inline"
                                  allowKeyboardControl
                                  animateYearScrolling
                                />
                              </ThemeProvider>
                            </MuiPickersUtilsProvider>
                            <TextField
                              value={grcExternalVenue}
                              onChange={(e) =>
                                setGRCExeternalVenue(e.target.value)
                              }
                              variant="outlined"
                              size="small"
                              label="External Venue"
                              style={{ marginTop: ".5rem", minWidth: "14rem" }}
                            />
                          </Box>
                          {loading ? (
                            <Box
                              style={{
                                disply: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <GreenButton
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="primary"
                              className={classes.submit}
                              style={{ marginTop: "1rem" }}
                              disabled={
                                Internal._id && External._id ? false : true
                              }
                            >
                              Assign Evaluators
                            </GreenButton>
                          )}
                        </form>
                      </>
                    ))}
                  {/* for all the other cases  */}
                  {(project.projectStatus === "Approved" ||
                    project.projectStatus === "Rejected" ||
                    project.projectStatus === "Represent" ||
                    project.projectStatus === "Approved with Minor Changes" ||
                    project.projectStatus === "Completed") && (
                    <Table>
                      <TableRow>
                        <TableCell>Project Status</TableCell>
                        <TableCell>
                          {project.projectStatus === "Represent"
                            ? "Accepted with Major Changes"
                            : project.projectStatus ===
                              "Approved with Minor Changes"
                            ? "Accepted with Minor Changes"
                            : project.projectStatus === "Approved"
                            ? "Accepted"
                            : project.projectStatus === "Rejected"
                            ? "Rejected"
                            : project.projectStatus}
                        </TableCell>
                      </TableRow>
                    </Table>
                  )}
                </div>
              </Card>
            )}
            <Box mb={2}>
              <ProjectViewGRC userId={project.groupMembers[0]._id} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
