export const validation = (
  firstName,
  lastName,
  email,
  password,
  regNo,
  degree,
  batch
) => {
  var nameRegex = /^[a-zA-Z ]{2,30}$/;
  var decimal = /^.{8,}$/;

  if (!nameRegex.test(firstName))
    return { error: "Valid First Name Required", errorIn: "firstName" };
  if (!nameRegex.test(lastName))
    return { error: "Valid Last Name Required", errorIn: "lastName" };
  if (!email.match(/.+\@iiu\.edu\.pk/))
    return { error: "University Email Required", errorIn: "email" };

  if (!password.match(decimal))
    return { error: "Password must be minimum eight characters", errorIn: "password" };
  if (regNo === "") return { error: "Required", errorIn: "regNo" };
  if (degree === "") return { error: "Required", errorIn: "degree" };
  if (batch === "") return { error: "Select a Batch", errorIn: "batch" };
  else return { error: "", errorIn: "" };
};
export const validationSignin = (email, password) => {
  var emailRegex = /^[a-zA-Z0-9_.+-]+@iiu.edu.pk$/;

  if (!emailRegex.test(email))
    return { error: "University Email Required", errorIn: "email" };

  else if (password < 1) return { error: "Password Required", errorIn: "password" };
  else return "";
};

export const authenticate = (jwt, next) => {
  var oneDay = 24 * 60 * 60 * 1000;
  const expireTime = Date.now() + oneDay;
  if (typeof window !== "undefined") {
    localStorage.setItem("jwt", JSON.stringify(jwt));
    localStorage.setItem("expire_time", expireTime);
  }
  if (jwt.user.roles.includes("Program-Office")) {
    localStorage.setItem("currentRole", "Program-Office");
  }

  if (jwt.user.roles.includes("Professor")) {
    localStorage.setItem("currentRole", "Professor");
  }

  if (jwt.user.roles.includes("Student")) {
    localStorage.setItem("currentRole", "Student");
  }
  if (jwt.user.roles.includes("Admin")) {
    localStorage.setItem("currentRole", "Admin");
  }
  next();
};
