import {
    Box,
    CircularProgress,
    Collapse,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import React, { Fragment, useState, useEffect } from "react";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
//---
import { fetchProfessors } from "../apiCalls/userCalls";
import { isLoggedIn } from "../helpers/authenticationHelp"

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        overflow: "auto",
        maxHeight: 200,
    },
    inline: {
        display: "flex",
        marginTop: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

    list: {
        "&:hover": {
            marginTop: "0px",
            marginBottom: "0px",
            backgroundColor: "#c5e1a5",
        },
    },
    selectedList: {
        marginTop: "0px",
        marginBottom: "0px",
        backgroundColor: "#eeeeee",
    },

    load: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(2),
    },
}));

const SearchProfessor = (props) => {

    const { groupMember, setGroupMember, label } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [professorsList, setProfessorsList] = useState({
        list: [],
        dataLoaded: false,
    });
    const [fetchProfessorError, setFetchProfessorError] = useState(false);
    const handleClick = () => {
        setSearch("");
        setOpen(!open);
    };

    const setClose = () => {
        setGroupMember([]);
        setOpen(false);
        setSearch("");
    };
    const handleListItemClick = (index, professor) => (event) => {
        handleClick();
        setSearch(professor.email);
        setGroupMember(professor)
    };
    useEffect(() => {
        const token = isLoggedIn().token
        fetchProfessors(token).then((data) => {
            if (data) {
                if (data.error) {
                    setProfessorsList({
                        list: [],
                        dataLoaded: false,
                    });
                } else {
                    setProfessorsList({
                        list: data.professors,
                        dataLoaded: true,
                    });
                    setFetchProfessorError(false);
                }
            } else {
                setFetchProfessorError(true);
            }
        });
    }, [])
    return (
        <Grid item xs={12} style={{ paddingTop: "0px", margin: "0px" }}>
            {fetchProfessorError && (
                <Alert severity="error" className={classes.root}>
                    System is not working properly. Please contact IT support :(
                </Alert>
            )}
            {!professorsList.dataLoaded ? (
                <Box className={classes.load}>
                    <CircularProgress />
                </Box>
            ) : (
                <List style={{ paddingTop: "0px" }}>
                    {/* display the search bar when nothing is selected */}
                    {!(groupMember.name) &&
                        <TextField
                            id="auto"
                            placeholder="search by name"
                            onClick={handleClick}
                            label={label}
                            value={search}
                            required
                            onChange={(event) => setSearch(event.target.value)}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!open ? (
                                            <ArrowDropDownIcon />
                                        ) : (
                                            <ArrowDropUpIcon />
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    }


                    {/* display the available records based on search input */}
                    {
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List
                                className={classes.root}
                                component="div"
                                disablePadding
                            >
                                {professorsList.list.filter(
                                    ({ name }) =>
                                        name
                                            .toLowerCase()
                                            .indexOf(search.toLowerCase()) > -1
                                ).length > 0 ? (
                                    professorsList.list
                                        .filter(
                                            ({ name }) =>
                                                name
                                                    .toLowerCase()
                                                    .indexOf(
                                                        search.toLowerCase()
                                                    ) > -1
                                        )
                                        .map((professor, index) => (
                                            <Fragment key={index}>
                                                <ListItem
                                                    className={classes.list}
                                                    button
                                                    alignItems="flex-start"
                                                    onClick={handleListItemClick(
                                                        index,
                                                        professor
                                                    )}
                                                >
                                                    <ListItemText
                                                        p
                                                        rimary={professor.name}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Box
                                                                    className={
                                                                        classes.inline
                                                                    }
                                                                >
                                                                    <Box
                                                                        flexGrow={
                                                                            1
                                                                        }
                                                                    >
                                                                        <Typography>
                                                                            {`Email: ${professor.email}`}
                                                                        </Typography>
                                                                        <Typography>
                                                                            {`Current Projects: ${professor.totalProjects}`}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider component="li" />
                                            </Fragment>
                                        ))
                                ) : (
                                    <ListItem>
                                        <ListItemText
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            No data found
                                        </ListItemText>
                                    </ListItem>
                                )}
                            </List>
                        </Collapse>
                    }
                    {/* display the selected item */}
                    {(groupMember.name) && !open && (
                        <List style={{ padding: "0px", margin: "0px" }}>
                            <ListSubheader
                                style={{ paddingTop: "0px", margin: "0px" }}
                                component="div"
                                id="nested-list-subheader"
                            >
                                {label}
                            </ListSubheader>
                            <ListItem className={classes.selectedList} style={{ padding: "0px", margin: "0px" }}>
                                <ListItemText
                                    style={{ padding: "1rem", margin: "0px" }}
                                    primary={groupMember.name}
                                    secondary={
                                        <Box>
                                            <Box>
                                                {`Email: ${groupMember.email}`}
                                            </Box>
                                        </Box>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        onClick={setClose}
                                        color="primary"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    )}
                </List>
            )
            }
        </Grid >
    );
};
export default SearchProfessor;