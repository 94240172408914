const { serverURL } = require("./helper");
const { isLoggedIn } = require("../helpers/authenticationHelp");

export const addUser = (user, token) => {
  isLoggedIn();
  return fetch(`${serverURL}/adduser`, {
    method: "POST",
 
    headers: {
      Accept: "application/json",

      "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getUsers = (userType, token) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/getusers?` + new URLSearchParams({ record: userType }),
    {
      method: "GET",
   

      headers: {
        Accept: "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,


        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const pendingUsers = (token) => {
  isLoggedIn();
  return fetch(`${serverURL}/pendingusers`, {
    method: "GET",
 
    headers: {
      Accept: "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,


      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const eligibleUser = (id, token) => {
  isLoggedIn();
  return fetch(`${serverURL}/eligigbleuser/${id}`, {
    method: "PUT",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deleteUser = (id, token) => {
  isLoggedIn();
  return fetch(`${serverURL}/deleteuser`, {
    method: "DELETE",
 

    headers: {
      Accept: "application/json",

      "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

      id: id,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const undoDeleteUser = (id, token) => {
  isLoggedIn();
  return fetch(`${serverURL}/undodeleteuser`, {
    method: "DELETE",
 
    headers: {
      Accept: "application/json",

      "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

      id: id,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const editAdmin = (id, token, user) => {
  isLoggedIn();
  return fetch(`${serverURL}/editadmin?` + new URLSearchParams({ id: id }), {
    method: "PUT",
 

    headers: {
      Accept: "application/json",

      "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const userSetting = (token) => {
  isLoggedIn();
  return fetch(`${serverURL}/settings`, {
    method: "GET",
 

    headers: {
      Accept: "application/json",

      "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addphoneNumber = (number, token) => {
  isLoggedIn();
  return fetch(`${serverURL}/phonenumber`, {
    method: "POST",
 

    headers: {
      Accept: "application/json",

      "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

    },
    body: JSON.stringify(number),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const fetchProfessors = async (token) => {
  isLoggedIn();
  return await fetch(`${serverURL}/professors`, {
    method: "GET",
 

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const fetchExternalEvaluator = async () => {
  isLoggedIn();
  return await fetch(`${serverURL}/evaluators`, {
    method: "GET",
 

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const editStudent = async (id, token, user) => {
  isLoggedIn();
  return fetch(`${serverURL}/editstudent?` + new URLSearchParams({ id: id }), {
    method: "PUT",
 

    headers: {
      Accept: "application/json",

      "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const fetchNotifications = async (token) => {
  isLoggedIn();
  return fetch(`${serverURL}/notifications`, {
    method: "GET",
 

    headers: {
      Accept: "application/json",

      "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
