import React, { useState, useEffect } from "react";
//---
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination/TablePagination";
//---
import { getSignupData } from "../apiCalls/settingCalls";
import { WAIT, ERROR, SUCCESS } from "../variables/enums";
import PageLayout from "../components/PageLayout";
import { CircularProgress, Container } from "@material-ui/core";
import { broadCast, getbroadCasts } from "../apiCalls/broadcastCalls";
import { isLoggedIn, roleNow } from "../helpers/authenticationHelp";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {},
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function BrodcastMessages({ Data }) {
  const classes = useStyles();
  const pages = [5, 10, 15, 20];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [totalRecord, setTotalRecords] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [signupData, SetSignupData] = useState({
    degrees: [],
    batches: [],
    isLoaded: WAIT,
    msg: "",
  });
  const [messageText, setMessageText] = useState("");
  const [degree, setDegree] = useState("All");
  const [batch, setBatch] = useState("All");
  const [category, setCategory] = useState("All");
  const [open, setOpen] = React.useState(false);
  const [refresh, setRefresh] = useState(0);
  const [messageLoaded, setMessageLoaded] = useState({ msg: "", loaded: "" });
  const [messages, setMessages] = useState({
    list: [],
    isLoaded: WAIT,
    msg: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const message = { messageText, degree, batch, category };
    setMessageLoaded({ loaded: WAIT });

    broadCast(message).then((data) => {
      if (data) {
        if (data.error) {
          setMessageLoaded({ loaded: ERROR, msg: data.error });
          setOpen(true);
        } else {
          setMessageLoaded({
            loaded: SUCCESS,
            msg: "Message Broadcasted Successfully",
          });
          setOpen(true);
          setRefresh(refresh + 1);
        }
      } else {
        setMessageLoaded({
          loaded: ERROR,
          msg: "Failed to connect to server",
        });
        setOpen(true);
      }
    });
  };

  useEffect(() => {
    getSignupData().then((data) => {
      if (data) {
        if (data.error) {
          SetSignupData({ isLoaded: ERROR, msg: data.error });
        } else {
          SetSignupData({
            degrees: data.degrees,
            batches: data.batches,
            isLoaded: SUCCESS,
          });
        }
      } else {
        SetSignupData({ isLoaded: ERROR, msg: "Unable to connect" });
      }
    });
    //now we will get the messages
    getbroadCasts(page, rowsPerPage).then((data) => {
      if (data) {
        if (data.error) {
          setMessages({ isLoaded: ERROR, msg: data.error });
        } else {
          setMessages({
            isLoaded: SUCCESS,
            list: data.broadcastMessage,
          });
          setTotalRecords(data.totalCount);
        }
      } else {
        setMessages({ isLoaded: ERROR, msg: "Failed to connect to server" });
      }
    });
  }, [refresh, page, rowsPerPage]);
  return (
    <PageLayout Title={Data.name} Icon={Data.icon}>
      {isLoggedIn().user.roles.includes("Admin") && roleNow() === "Admin" && (
        <Container>
          <Grid
            container
            spacing={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={12}>
              <TextField
                label="Notification"
                variant="outlined"
                fullWidth
                multiline
                required
                value={messageText}
                onChange={(e) => {
                  setMessageText(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4} sm={4}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="degree-input">Degree</InputLabel>
                    <Select
                      fullWidth
                      labelId="degree"
                      id="degreeId"
                      value={degree}
                      name="degree"
                      onChange={(e) => {
                        setDegree(e.target.value);
                      }}
                      label="degree"
                    >
                      <MenuItem value="All">
                        <em>All</em>
                      </MenuItem>
                      {signupData.degrees.map((degree, key) => (
                        <MenuItem key={key} value={degree}>
                          {degree}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="batch">Batch</InputLabel>
                    <Select
                      fullWidth
                      labelId="batch"
                      id="batchId"
                      value={batch}
                      onChange={(e) => {
                        setBatch(e.target.value);
                      }}
                      label="Batch"
                    >
                      <MenuItem value="All">
                        <em>All</em>
                      </MenuItem>
                      {signupData.batches.map((batch, key) => (
                        <MenuItem key={key} value={batch}>
                          {batch}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="batch">Category</InputLabel>
                    <Select
                      fullWidth
                      id="categoryId"
                      value={category}
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                      label="Category"
                    >
                      <MenuItem value={"All"}>Both</MenuItem>
                      <MenuItem value={"Student"}>Student</MenuItem>
                      <MenuItem value={"Faculty"}>Faculy</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {messageLoaded.loaded === WAIT ? (
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  fullWidth
                  style={{ padding: ".9rem" }}
                  onClick={handleSubmit}
                >
                  Publish
                </Button>
              )}
            </Grid>
          </Grid>
        </Container>
      )}
      <Container
        style={{ maxHeight: "60vh", overflow: "auto", marginTop: "1rem" }}
      >
        <Grid container>
          <Grid item xs={12}>
            {messages.isLoaded === WAIT ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <CircularProgress />
              </Box>
            ) : messages.isLoaded === ERROR ? (
              <Box style={{ margin: "1rem 0rem" }}>
                <Alert severity="error">{messages.msg}</Alert>
              </Box>
            ) : (
              messages.isLoaded === SUCCESS && (
                <Box style={{height:"60vh"}}>
                  {
                  messages.list.map((message, index) => (
                  <Card className={classes.root} style={{ marginTop: "1rem" }}>
                    <CardContent>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        style={{ whiteSpace: "pre-line" }}
                        component="p"
                      >
                        {message.messageText}
                      </Typography>
                    </CardContent>
                    <CardActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "0 1rem",
                      }}
                    >
                      {roleNow() !== "Admin" && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {" "}
                          By:{` `}{" "}
                          <Typography
                            variant="body2"
                            color="primary"
                            component="span"
                          >
                            {" "}
                            University Administration{" "}
                          </Typography>{" "}
                        </Typography>
                      )}
                      {roleNow() === "Admin" && (
                        <>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            For:
                            <Typography
                              variant="body2"
                              color="error"
                              component="span"
                            >
                              {` ${
                                message.category === "All"
                                  ? "Student and Faculty"
                                  : message.category
                              }`}
                            </Typography>
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            Batch:
                            <Typography
                              variant="body2"
                              color="error"
                              component="span"
                            >
                              {` ${message.batch}`}
                            </Typography>
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            Degree:
                            <Typography
                              variant="body2"
                              color="error"
                              component="span"
                            >
                              {` ${message.degree}`}
                            </Typography>
                          </Typography>
                        </>
                      )}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Created at: {new Date(message.createdAt).toDateString()}
                      </Typography>
                    </CardActions>
                  </Card>
                  ))}
                </Box>
              )
            )}
          </Grid>
        </Grid>
      </Container>
      <Container>
        {messages.isLoaded === SUCCESS && (
          <TablePagination
            component="div"
            page={page}
            rowsPerPageOptions={pages}
            rowsPerPage={rowsPerPage}
            count={totalRecord}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Container>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageLoaded.loaded}>
          {messageLoaded.msg}
        </Alert>
      </Snackbar>
    </PageLayout>
  );
}
