import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MuiTableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { projectsByProfessor } from "../apiCalls/projectCalls";
import { WAIT, ERROR, SUCCESS } from "../variables/enums";
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import { CircularProgress } from "@material-ui/core";
const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: ".3rem",
  },
})(MuiTableCell);

const useStyles = makeStyles({
  increaseSize: {
    minWidth: "70rem",
    maxWidth: "200rem",
  },
  root: {
    minWidth: 275,
    padding: "0px",
    margin: "0px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    width: "100%",
  },
});
export default function ProfessorDetail({ user }) {
  const classes = useStyles();
  const { created, email, name, _id } = user;
    const [projects, setProjects] = useState({
      list: [],
      loaded: WAIT,
      message: "",
    });
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    //WE WILL GET THE PROJECTS under supervision of the professor FROM THE BACKEND
    projectsByProfessor(user._id).then((data) => {
      if (data) {
        if (data.projects) {
          setProjects({ list: data.projects, loaded: SUCCESS });
        }
        if (data.error) setProjects({ loaded: ERROR, message: data.error });
      } else {
        setProjects({
          loaded: ERROR,
          message: "Unable to connect to server",
        });
      }
    });
  }, []);
  return (
    <>
      <Box className={classes.root} style={{minWidth:"25rem"}}>
        <CardContent style={{ paddingTop: "0px", marginTop: "0px" }}>
          <TableRow>
            <TableCell style={{minWidth:"8rem"}}>Name</TableCell>
            <TableCell>{name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>{email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Created</TableCell>
            <TableCell>{new Date(created).toDateString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Projects Count</TableCell>
            <TableCell>
              {projects.loaded === SUCCESS
                ? projects.list.length
                : projects.loaded === ERROR
                ? "error"
                : "Loading..."}
            </TableCell>
          </TableRow>
          {open &&
            projects.list.map((project, index) => {
              return (
                <Card style={{ marginBottom: "1rem" }}  key={index}>
                  <TableRow >
                  <TableCell ><h4 style={{margin:"0px"}}>{`Project no ${index+1}`}</h4></TableCell>
                  </TableRow>
                  <TableRow className={classes.root}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{minWidth:"8rem"}}
                    >
                      Project Title
                    </TableCell>
                    <TableCell>
                      {project.documentation.visionDocument.title}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.root}>
                  <TableCell
                      component="th"
                      scope="row"
                      
                    >
                      Group Name
                    </TableCell>
                    <TableCell >
                      {project.groupName}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.root}>
                  <TableCell
                      component="th"
                      scope="row"
                      
                    >
                      Department
                    </TableCell>
                    <TableCell >
                      {project.department}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.root}>
                  <TableCell
                      component="th"
                      scope="row"
                      
                    >
                      Group Memeber(s)
                    </TableCell>
                    <TableCell >
                      {project.groupMembers.length === 2
                        ? `${project.groupMembers[0].name} and  ${project.groupMembers[1].name}`
                        : `${project.groupMembers[0].name}`}
                    </TableCell>
                  </TableRow>
                </Card>
              );
            })
          }
          {projects.loaded === SUCCESS &&
            projects.list.length>0 && 
           (
            <Button
              className={classes.button}
              onClick={() => {
                setOpen(!open);
              }}
              variant="contained"
            >
              {open? "Hide Projects List":"Show Project List"}
            </Button>
          )}
        </CardContent>
      </Box>
    </>
  );
}
