import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { DropzoneArea } from "material-ui-dropzone";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Grid } from "@material-ui/core";

import { CircularProgress } from "@material-ui/core";
import {
  uploadResource
} from "../../apiCalls/resourceCalls";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({

  dropzone: {
    marginTop: theme.spacing(2),

    width: "25rem",
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
    backgroundColor: "#eeeeee",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    textAlign: "center",
  },
  successIcon: {
    color: "#7cb342",
    fontSize: "8rem",
  },
  unsuccessIcon: {
    color: "#c62828",
    fontSize: "8rem",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(8),
  },



}));



const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiTypography: {
      // Name of the rule
      colorTextSecondary: {
        // Some CSS
        color: "white",
      },
      body1: {
        fontWeight: "normal",
      },
    },
    MuiDropzoneArea: {
      root: {
        backgroundColor: "#f9fbe7",
      },
    },
  },
});


const UploadResources = ({ openForm,setOpenForm, setRefresh, refresh }) => {
  const classes = useStyles();
  const [fileName,setFileName]=useState("");
  const [document, setDocument] = useState([]);
  const [res, setRes] = useState({
    error: false,
    loading: false,
    success: false,
    request: false,
  });
    const [level, setLevel] = useState("");
  const [error,setError]=useState("");


  const [next, setNext] = useState(false);
  const formData = new FormData();

    const handleLevelChange = (event) => {
      setLevel(event.target.value);
      setError("");
    };
  const handleCloseDoc = () => {
     setDocument([]);
     setRes({ error: false, loading: false, success: false, request: false });
    setOpenForm(false);

  };

  const uploadDocumentClick = async (e) => {
    e.preventDefault();
    if (fileName.trim().length < 5 || fileName.trim().length > 50)
      setError("fileName");
    else if (level !== "UGRD" && level !== "GRAD" && level != "PG"&&level!="General") 
      setError("level");
    else if (document.length === 0 || document.name === "") 
        setError("file");
     else {
      setRes({ error: false, loading: true, success: false, request: true });

      formData.set("file", document[0]);
      uploadResource(level, formData, fileName).then((data) => {
        if (data && !data.error) {
          setRes({
            error: false,
            loading: false,
            success: true,
            request: true,
          });
          setRefresh(!refresh);
        } else {
          setRes({
            error: true,
            loading: false,
            success: false,
            request: true,
          });
        }
      });
    }
  };
  const handleNext = async (e) => {
    e.preventDefault();
    if (!next) setNext(true);
  };

  useEffect(() => {}, [setNext]);
  return (
    <Dialog open={openForm} onClose={handleCloseDoc}>
      <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
        Upload New File
      </DialogTitle>
      <DialogContent className={classes.dropzone}>
        <Grid container spacing={2}>
          {error === "file" && (
            <Grid item xs={12}>
              <Alert severity="error">Error - Please select your file</Alert>
            </Grid>
          )}

          {res.loading && (
            <Grid item xs={12}>
              <Paper className={classes.loading} elevation={0}>
                <CircularProgress size={130} thickness={5} />
              </Paper>
            </Grid>
          )}

          {!res.loading && !res.request && (
            <>
              <Grid item xs={12}>
                <TextField
                  name="fileName"
                  variant="outlined"
                  required
                  fullWidth
                  size="small"
                  id="fileName"
                  label="File Name"
                  value={fileName}
                  onChange={(e) => {
                    setFileName(e.target.value);
                    setError("");
                  }}
                  error={error === "fileName"}
                  helperText={
                    error === "fileName"
                      ? "File name must be between 5-50 characters"
                      : `${fileName.length}/50`
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{ minWidth: "-webkit-fill-available" }}>
                  <InputLabel id="demo-simple-select-label">Level</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={level}
                    onChange={handleLevelChange}
                  >
                    <MenuItem value={"UGRD"}>BS</MenuItem>
                    <MenuItem value={"GRAD"}>MS</MenuItem>
                    <MenuItem value={"PG"}>PhD</MenuItem>
                    <MenuItem value={"General"}>General</MenuItem>
                  </Select>
                  <FormHelperText error={error === "level"}>
                    {error === "level" ? "Select the level of Degree" : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <ThemeProvider theme={theme}>
                  <DropzoneArea
                    initialFiles={document}
                    onChange={(file) => {
                      setDocument(file);
                      setError("");
                    }}
                    onDelete={() => {
                      setDocument([]);
                      setError("");
                    }}
                    acceptedFiles={[
                      ".pdf,.ppt,.pptx,.doc,.docx,.zip,.rar,.txt,.xlsx,",
                    ]}
                    filesLimit={1}
                    maxFileSize={20000000}
                    dropzoneText={"Drag your file here or click to upload"}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                    showPreviewsInDropzone={false}
                    useChipsForPreview
                    previewGridProps={{
                      container: {
                        spacing: 1,
                        direction: "row",
                      },
                    }}
                    previewChipProps={{
                      classes: { root: classes.previewChip },
                    }}
                    previewText="Selected files"
                  />
                </ThemeProvider>
              </Grid>
            </>
          )}
          {res.success && (
            <Grid item xs={12}>
              <Paper
                className={classes.paper}
                style={{ backgroundColor: "#dcedc8" }}
              >
                <CheckCircleIcon className={classes.successIcon} />
                <Typography variant="h5">
                  File Uploaded Successfully!
                </Typography>
                <Typography>
                  Congratulations! You have successfully uploaded a new file
                </Typography>
              </Paper>
            </Grid>
          )}

          {res.error && !res.loading && (
            <Grid item xs={12}>
              <Paper
                className={classes.paper}
                style={{ backgroundColor: "#ffebee" }}
              >
                <SentimentVeryDissatisfiedIcon
                  className={classes.unsuccessIcon}
                />
                <Typography variant="h5">System Error!</Typography>
                <Typography>
                  File Upload failed! System is unable to upload this file on
                  database. Please contact IT support to report this problem.
                </Typography>
              </Paper>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDoc} color="primary">
          Cancel
        </Button>
        {!res.request && !res.loading && (
          <Button
            color="primary"
            type="submit"
            variant="contained"
            style={{ color: "white" }}
            onClick={uploadDocumentClick}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default UploadResources;
