import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CodeIcon from "@material-ui/icons/Code";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import { Toolbar, AppBar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { DropzoneArea } from "material-ui-dropzone";
import Chip from "@material-ui/core/Chip";
import HelpIcon from "@material-ui/icons/Help";
import { viewProject, deleteProject } from "../apiCalls/projectCalls";
import { CircularProgress } from "@material-ui/core";
import { PDFViewer } from "@react-pdf/renderer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { isLoggedIn, roleNow } from "../helpers/authenticationHelp";
import CommentsLetter from "./CommentsLetter";
import MenuItem from "@material-ui/core/MenuItem";
import { uploadDocument } from "../apiCalls/projectCalls";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import CommentSectionGRC from "./CommentSectionGRC";
import { approvalLetterData } from "../apiCalls/projectCalls";
import Moment from "react-moment";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MuiTableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
const { serverURL } = require("../apiCalls/helper");
const TableCell = withStyles({ root: { minWidth: "15rem", padding: ".8rem" } })(
    MuiTableCell
);
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
    root: { marginTop: theme.spacing(2), width: "100%", minHeight: "25rem" },
    dropzone: { marginTop: theme.spacing(2), width: "25rem" },
    previewChip: { minWidth: 160, maxWidth: 210, backgroundColor: "#eeeeee" },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(3),
        textAlign: "center",
    },
    successIcon: { color: "#7cb342", fontSize: "8rem" },
    fileIcon: { fontSize: "5rem", color: "#37474f" },
    unsuccessIcon: { color: "#c62828", fontSize: "8rem" },
    loading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(3),
        padding: theme.spacing(8),
        minHeight: "25rem",
    },
    loading2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(8),
    },
    section: { padding: theme.spacing(2), width: "200px" },
    bold: { fontWeight: "bold" },
    chip: {
        color: "white",
        fontSize: "16px",
        letterSpacing: "0.8px",
        backgroundColor: "#3576a5",
    },
    modulesChip: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(0.5),
        backgroundColor: "#ff8d00",
        color: "white",
        borderStyle: "solid",
        borderWidth: "1.5px",
        fontSize: "14px",
        letterSpacing: "1px",
    },
    card: { marginTop: theme.spacing(1) },
    cardHeader: {
        paddingBottom: theme.spacing(0.5),
        backgroundColor: "#757575",
        color: "white",
    },
    cardContent: {
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(0),
        backgroundColor: "#eeeeee",
    },
    pdfView: { marginTop: theme.spacing(8), width: "100%", height: "100%" },
}));
const theme = createMuiTheme({
    overrides: {
        MuiTypography: {
            colorTextSecondary: { color: "white" },
            body1: { fontWeight: "normal" },
        },
        MuiDropzoneArea: { root: { backgroundColor: "#f9fbe7" } },
    },
});


const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));


const ProjectViewGRC = ({ setProjectExist, project, userId }) => {
    const classes = useStyles();
    const [projectData, setProjectData] = useState("");
    const [loading, setLoading] = useState(!0);
    const [open, setOpen] = useState(!1);
    const [commentList, setCommentList] = useState({ loaded: !1, data: [] });
    const [openAlert, setOpenAlert] = useState(!1);
    const [error, setError] = useState(!1);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [currentVersion, setCurrentVersion] = useState(1);
    const [document, setDocument] = useState({
        open: !1,
        name: "",
        type: "",
        file: [],
    });
    const [letterViewer, setLetterViewer] = useState(!1);
    const [openI, setOpenI] = React.useState(!1);
    const [file, setFile] = useState([]);
    const [errorIn, setErrorIn] = useState(!1);
    const [refresh, setRefresh] = useState(!1);
    const version = useRef([]);
    const [res, setRes] = useState({
        error: !1,
        loading: !1,
        success: !1,
        request: !1,
    });
    const formData = new FormData();
    const [response, setResponse] = useState({
        loading: !1,
        error: !1,
        message: "",
        data: {},
    });
    const handleToggle = () => {
        setOpenI((prevOpen) => !prevOpen);
    };
    const handleChange = (event) => {
        setCurrentVersion(event.target.value);
    };

    const handleClickTest = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseTest = () => {
        setAnchorEl(null);
    };


    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpenI(!1);
        }
    }
    const handleClickOpenDoc = (name, type) => {
        setDocument({ open: !0, name: name, type: type, file: [] });
    };
    const uploadDocumentClick = async (e) => {
        e.preventDefault();
        if (
            document.file.length === 0 ||
            document.type === "" ||
            document.name === ""
        ) {
            setErrorIn(!0);
        } else {
            setRes({ error: !1, loading: !0, success: !1, request: !0 });
            var location;
            if (document.name === "Propsal Presentation") location = "presentation";
            else if (document.name === "Final Documentation")
                location = "documentation";
            else if (document.name === "Code File") location = "code";
            else if (document.name === "Vision Document")
                location = "visionDocuments";
            else if (document.name === "Plagiarism Report") location = "plagiarism";
            else location = "other";
            formData.set("file", document.file[0]);
            uploadDocument(location, formData, projectData._id).then((data) => {
                if (data && !data.error) {
                    setRes({ error: !1, loading: !1, success: !0, request: !0 });
                    setRefresh(!refresh);
                } else {
                    setRes({ error: !0, loading: !1, success: !1, request: !0 });
                }
            });
        }
    };
    const uploadOrNot = () => {
        if (projectData.groupMembers.length === 1) {
            if (
                projectData.groupMembers[0]._id === isLoggedIn().user._id ||
                projectData.supervisor._id === isLoggedIn().user._id
            )
                return !0;
        }
        return !1;
    };
    const handleCloseDoc = () => {
        setDocument({ open: !1, name: "", type: "", file: [] });
        setRes({ error: !1, loading: !1, success: !1, request: !1 });
    };
    const closeLetterViewer = () => {
        setLetterViewer(!1);
    };
    const handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenAlert(!1);
    };
    const handleClickOpen = () => {
        setOpen(!0);
    };
    const openLetterViewer = () => {
        setLetterViewer(!0);
        setResponse({ loading: !0, error: !1, message: "", data: {} });
        approvalLetterData(projectData.department)
            .then((result) => {
                if (
                    result &&
                    !result.error &&
                    result.department &&
                    result.department.departmentName &&
                    result.department.departmentFullName
                ) {
                    setResponse({ loading: !1, error: !1, message: "", data: result });
                } else
                    setResponse({
                        loading: !1,
                        error: !0,
                        message: "Unable to get Coordinator's Name",
                        data: {},
                    });
            })
            .catch((error) => {
                setResponse({
                    loading: !1,
                    error: !0,
                    message: "Unable to get Coordinator's Name",
                    data: {},
                });
            });
    };
    const handleClose = () => {
        setOpen(!1);
    };
    const deleteProjectButton = (e) => {
        deleteProject(projectData._id).then((data) => {
            if (data) {
                if (!data.error) {
                    setLoading(!0);
                    setProjectExist("not-exist");
                } else {
                    setError(!0);
                }
            } else {
                setError(!0);
            }
        });
        setOpen(!1);
        setOpenAlert(!0);
    };
    useEffect(() => {
        if (project) {
            setProjectData(project);
            version.current = [];
            for (let k = 1; k <= project.version; k++) {
                version.current.push(k);
            }
            setCurrentVersion(project.version);
            setLoading(!1);
        } else {
            if (userId) {
                viewProject(userId).then((data) => {
                    if (data) {
                        if (!data.error) {
                            setProjectData(data);
                            version.current = [];
                            for (let k = 1; k <= data.version; k++) {
                                version.current.push(k);
                            }
                            setCurrentVersion(data.version);
                            setLoading(!1);
                        }
                    }
                });
            } else {
                viewProject().then((data) => {
                    if (data) {
                        if (!data.error) {
                            setProjectData(data);
                            version.current = [];
                            for (let k = 1; k <= data.version; k++) {
                                version.current.push(k);
                            }
                            setCurrentVersion(data.version);
                            setLoading(!1);
                        }
                    }
                });
            }
        }
    }, [refresh, setRefresh, setCurrentVersion]);
    return (
        <Paper className={classes.root} elevation={3}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                {error ? (
                    <Alert onClose={handleCloseAlert} severity="error">
                        Project Deletion Failed!
                    </Alert>
                ) : (
                    <Alert onClose={handleCloseAlert} severity="success">
                        Project Deleted Successfully!
                    </Alert>
                )}
            </Snackbar>
            {loading === !0 && (
                <Paper className={classes.loading} elevation={0}>
                    <CircularProgress size={130} thickness={5} />
                </Paper>
            )}
            {loading === !1 && (
                <>
                    <Grid container>
                        <Grid item xs={12} sm={6} className={classes.section}>
                            <Typography variant="h6">
                                <Box textAlign="center" mt={2} mb={2}>
                                    STUDENT DETAILS
                                </Box>
                            </Typography>
                            <Box mb={2}>
                                <Typography className={classes.bold} gutterBottom>
                                    Name
                                </Typography>
                                {projectData.groupMembers[0].name}
                            </Box>
                            <Box mb={2}>
                                <Typography className={classes.bold} gutterBottom>
                                    Email
                                </Typography>
                                {projectData.groupMembers[0].email}
                            </Box>
                            {projectData.groupMembers[0].phone_number && (
                                <Box mb={2}>
                                    <Typography className={classes.bold} gutterBottom>
                                        Contact Number
                                    </Typography>
                                    {projectData.groupMembers[0].phone_number}
                                </Box>
                            )}
                            <Box mt={3} mb={3}>
                                <Divider />
                            </Box>
                            <Box mb={2}>
                                <Typography variant="h6">
                                    <Box textAlign="center" mb={2}>
                                        PROJECT DETAILS
                                    </Box>
                                </Typography>
                                <Box mb={2}>
                                    <Typography className={classes.bold} gutterBottom>
                                        Supervisor
                                    </Typography>
                                    {`Mr. ${projectData.supervisor.name} (${projectData.supervisor.email})`}
                                </Box>
                                <Box mb={2}>
                                    <Typography className={classes.bold} gutterBottom>
                                        Status
                                    </Typography>
                                    <Chip
                                        label={
                                            projectData.projectStatus === "Represent"
                                                ? "Accepted with Major Changes"
                                                : projectData.projectStatus ===
                                                    "Approved with Minor Changes"
                                                    ? "Accepted with Minor Changes"
                                                    : projectData.projectStatus === "Approved"
                                                        ? "Accepted"
                                                        : projectData.projectStatus === "Rejected"
                                                            ? "Rejected"
                                                            : projectData.projectStatus
                                        }
                                        className={classes.chip}
                                        style={
                                            projectData.projectStatus === "Rejected"
                                                ? { backgroundColor: "Red" }
                                                : projectData.projectStatus === "Pending"
                                                    ? { backgroundColor: "Blue" }
                                                    : { backgroundColor: "Green" }
                                        }
                                    />
                                </Box>
                                <Box mb={2}>
                                    <Typography className={classes.bold}>Abstract</Typography>
                                    <Typography style={{ wordWrap: "break-word" }}>
                                        {projectData.abstract}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box mt={3} mb={3}>
                                <Divider />
                            </Box>
                            <Typography variant="h6">
                                <Box textAlign="center" mb={2}>
                                    DOCUMENTS
                                </Box>
                            </Typography>
                            {uploadOrNot() && (
                                <Box display="flex" flexDirection="row-reverse" p={2}>
                                    {projectData.projectStatus !== "Completed" &&
                                    <Button
                                        aria-controls="customized-menu"
                                        aria-haspopup="true"
                                        variant="contained"
                                        onClick={handleClickTest}
                                        variant="contained"
                                        style={{ color: "white" }}
                                        color="primary"
                                    >
                                        Upload New Document
                             
                                         </Button>}
                                                   <StyledMenu
                      id="customized-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseTest}
                    >
                                                        <MenuList
                                                            autoFocusItem={openI}
                                                            id="menu-list-grow"
                                                            onKeyDown={handleListKeyDown}
                                                        >
                                                            {(projectData.projectStatus === "Pending" ||
                                                                projectData.projectStatus === "Represent") && (
                                                                    <>
                                                                        <MenuItem
                                                                            onClick={() =>
                                                                                handleClickOpenDoc(
                                                                                    "Vision Document",
                                                                                    ".doc,.docx,.pdf"
                                                                                )
                                                                            }
                                                                        >
                                                                            Vision Document
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            onClick={() =>
                                                                                handleClickOpenDoc(
                                                                                    "Propsal Presentation",
                                                                                    ".pptx,.ppt,.pdf"
                                                                                )
                                                                            }
                                                                        >
                                                                            Propsal Presentation
                                                                        </MenuItem>
                                                                    </>
                                                                )}
                                                            {(projectData.projectStatus === "Approved" ||
                                                                projectData.projectStatus ===
                                                                "Approved with Minor Changes" ||
                                                                projectData.projectStatus ===
                                                                "Ready for Defence") && (
                                                                    <>
                                                                        <MenuItem
                                                                            onClick={() =>
                                                                                handleClickOpenDoc(
                                                                                    "Final Documentation",
                                                                                    ".pdf"
                                                                                )
                                                                            }
                                                                        >
                                                                            Final Documentation
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            onClick={() =>
                                                                                handleClickOpenDoc("Code File", ".zip")
                                                                            }
                                                                        >
                                                                            Code File
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            onClick={() =>
                                                                                handleClickOpenDoc(
                                                                                    "Plagiarism Report",
                                                                                    ".pdf"
                                                                                )
                                                                            }
                                                                        >
                                                                            Plagiarism Report
                                                                        </MenuItem>
                                                                    </>
                                                                )}
                                                        </MenuList>
                                         </StyledMenu>
                                </Box>
                            )}
                            <Box mb={2}>
                                <Grid container style={{ textAlign: "center" }}>
                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                        <Typography className={classes.bold}>Vision</Typography>
                                        <Link
                                            href={`${serverURL}/visionDocuments/${projectData.document.filename}`}
                                            target="_blank"
                                        >
                                            <IconButton edge="start" size="medium">
                                                <PictureAsPdfIcon className={classes.fileIcon} />
                                            </IconButton>
                                            <Typography
                                                style={{ display: "block", lineBreak: "anywhere" }}
                                                variant="caption"
                                            >
                                                {projectData.document.originalname}
                                            </Typography>
                                        </Link>
                                    </Grid>
                                    {typeof projectData.presentation.filename !== "undefined" && (
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                            <Typography className={classes.bold}>
                                                Presentation
                                            </Typography>
                                            <Link
                                                href={`${serverURL}/presentation/${projectData.presentation.filename}`}
                                                target="_blank"
                                            >
                                                <IconButton edge="start" size="medium">
                                                    <SlideshowIcon className={classes.fileIcon} />
                                                </IconButton>
                                                <Typography
                                                    style={{ display: "block", lineBreak: "anywhere" }}
                                                    variant="caption"
                                                >
                                                    {projectData.presentation.originalname}
                                                </Typography>
                                            </Link>
                                        </Grid>
                                    )}
                                    {typeof projectData.documentation.filename !==
                                        "undefined" && (
                                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                <Typography className={classes.bold}>
                                                    Documentation
                                                </Typography>
                                                <Link
                                                    href={`${serverURL}/documentation/${projectData.documentation.filename}`}
                                                    target="_blank"
                                                >
                                                    <IconButton edge="start" size="medium">
                                                        <PictureAsPdfIcon className={classes.fileIcon} />
                                                    </IconButton>
                                                    <Typography
                                                        style={{ display: "block", lineBreak: "anywhere" }}
                                                        variant="caption"
                                                    >
                                                        {projectData.documentation.originalname}
                                                    </Typography>
                                                </Link>
                                            </Grid>
                                        )}
                                    {typeof projectData.code.filename !== "undefined" && (
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                            <Typography className={classes.bold}>Code</Typography>
                                            <Link
                                                href={`${serverURL}/code/${projectData.code.filename}`}
                                                target="_blank"
                                            >
                                                <IconButton edge="start" size="medium">
                                                    <CodeIcon className={classes.fileIcon} />
                                                </IconButton>
                                                <Typography
                                                    style={{ display: "block", lineBreak: "anywhere" }}
                                                    variant="caption"
                                                >
                                                    {projectData.code.originalname}
                                                </Typography>
                                            </Link>
                                        </Grid>
                                    )}
                                    {typeof projectData.plagiarismReport.filename !==
                                        "undefined" && (
                                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                <Typography className={classes.bold}>
                                                    Plagiarism Report
                                                </Typography>
                                                <Link
                                                    href={`${serverURL}/plagiarism/${projectData.plagiarismReport.filename}`}
                                                    target="_blank"
                                                >
                                                    <IconButton edge="start" size="medium">
                                                        <PictureAsPdfIcon className={classes.fileIcon} />
                                                    </IconButton>
                                                    <Typography
                                                        style={{ display: "block", lineBreak: "anywhere" }}
                                                        variant="caption"
                                                    >
                                                        {projectData.plagiarismReport.originalname}
                                                    </Typography>
                                                </Link>
                                            </Grid>
                                        )}
                                </Grid>
                            </Box>
                            { }
                            {projectData.internal &&
                                projectData.internal.examiner &&
                                projectData.external &&
                                projectData.external.examiner &&
                                projectData.grcExternal.examiner && (
                                    <>
                                        <Box mt={3} mb={3}>
                                            <Divider />
                                        </Box>
                                        <Typography variant="h6">
                                            <Box textAlign="center" mb={2}>
                                                EVLUATION SCHEDULE
                                            </Box>
                                        </Typography>
                                        <Box mb={2}>
                                            <Typography className={classes.bold} gutterBottom>
                                                Internal Examiner
                                            </Typography>
                                            {`Mr. ${projectData.internal.examiner.name}`}
                                            <br />
                                            {`Venue: ${projectData.internal.venue}`}
                                            <br />
                                            {"Date: "}
                                            <Moment format="MMM D, YYYY, h:mm A">
                                                {projectData.internal.date}
                                            </Moment>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography className={classes.bold} gutterBottom>
                                                External Examiner
                                            </Typography>
                                            {`Mr. ${projectData.external.examiner.name}`}
                                            <br />
                                            {`Venue: ${projectData.external.venue}`}
                                            <br />
                                            {"Date: "}
                                            <Moment format="MMM D, YYYY, h:mm A">
                                                {projectData.external.date}
                                            </Moment>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography className={classes.bold} gutterBottom>
                                                External Examiner 02
                                            </Typography>
                                            {`Mr. ${projectData.grcExternal.examiner.name}`}
                                            <br />
                                            {`Venue: ${projectData.grcExternal.venue}`}
                                            <br />
                                            {"Date: "}
                                            <Moment format="MMM D, YYYY, h:mm A">
                                                {projectData.grcExternal.date}
                                            </Moment>
                                        </Box>
                                    </>
                                )}
                            {projectData.projectStatus === "Completed" &&
                                isLoggedIn().user.roles.includes("Admin") &&
                                projectData.marks &&
                                projectData.marks.external &&
                                projectData.marks.internal &&
                                projectData.marks.externalGRC && (
                                    <>
                                        <Box mt={3} mb={3}>
                                            <Divider />
                                        </Box>
                                        <Typography variant="h6">
                                            <Box textAlign="center" mb={2}>
                                                MARKS
                                            </Box>
                                        </Typography>
                                        <Table>
                                            <TableRow>
                                                <TableCell>Project Internal</TableCell>
                                                <TableCell>{project.internal.examiner.name}</TableCell>
                                                <TableCell style={{ paddingLeft: "5rem" }}>
                                                    {project.marks.internal}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Project External</TableCell>
                                                <TableCell>{project.external.examiner.name}</TableCell>
                                                <TableCell style={{ paddingLeft: "5rem" }}>
                                                    {project.marks.external}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Project External</TableCell>
                                                <TableCell>
                                                    {project.grcExternal.examiner.name}
                                                </TableCell>
                                                <TableCell style={{ paddingLeft: "5rem" }}>
                                                    {project.marks.externalGRC}
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    </>
                                )}
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.section}>
                            <Box>
                                <Box mt={3} display="flex" flexDirection="row-reverse">
                                    <FormControl
                                        style={{ minWidth: "5rem", textAlign: "center" }}
                                    >
                                        <InputLabel id="demo-simple-select-label">
                                            Version
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={currentVersion}
                                            label="Version"
                                            onChange={handleChange}
                                        >
                                            {version.current.map((v) => {
                                                return <MenuItem value={v}>{v}</MenuItem>;
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box mt={3}>
                                    <Typography variant="h6">
                                        <Box textAlign="center" mb={2}>
                                            COMMENT SECTION
                                        </Box>
                                    </Typography>
                                    <CommentSectionGRC
                                        projectId={projectData._id}
                                        setCommentList={setCommentList}
                                        currentVersion={currentVersion}
                                        version={projectData.version}
                                        status={projectData.projectStatus}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    {(roleNow() === "Student") &
                        (projectData.projectStatus === "Pending" ||
                            projectData.projectStatus === "Rejected") ? (
                        <Box display="flex" flexDirection="row-reverse" p={2} pr={2}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                endIcon={<DeleteIcon />}
                                onClick={handleClickOpen}
                            >
                                Delete Project
                            </Button>
                        </Box>
                    ) : (
                        ""
                    )}
                    {projectData.projectStatus &&
                        projectData.projectStatus !== "Pending" &&
                         projectData.projectStatus !==  "Ready for Initial Approval"&&
                        projectData.supervisor &&
                        (
                            (projectData.supervisor._id === isLoggedIn().user._id &&
                            roleNow() === "Professor") 
                            ||
                            roleNow() === "Student"||roleNow()==="Admin") && (
                            <Box display="flex" flexDirection="row-reverse" p={2} pr={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={openLetterViewer}
                                    style={{ color: "white" }}
                                >
                                    Evaluation Form
                                </Button>
                            </Box>
                        )}
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle
                            alignText="center"
                            id="alert-dialog-title"
                            style={{ textAlign: "center" }}
                        >
                            <>
                                <HelpIcon style={{ fontSize: "6rem", color: "#8e24aa" }} />
                                <Typography variant="h6">Delete Project?</Typography>
                            </>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Deleting this project will permanently remove it from the
                                portal.You can not undo this action.Are you sure you want to
                                delete this comment?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                cancel
                            </Button>
                            <Button
                                onClick={deleteProjectButton}
                                variant="contained"
                                color="primary"
                                autoFocus
                            >
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={letterViewer} onClose={closeLetterViewer} fullScreen>
                        <AppBar style={{ backgroundColor: "#09510c" }}>
                            <Toolbar>
                                <Box
                                    display="flex"
                                    flexGrow={1}
                                    letterSpacing={2}
                                    color="white"
                                    fontSize="h6.fontSize"
                                    fontWeight="fontWeightBold"
                                >
                                    Evaluation Form
                                </Box>
                                <Box>
                                    {" "}
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        style={{ color: "white" }}
                                        onClick={closeLetterViewer}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                        </AppBar>
                        {response.loading && (
                            <Paper className={classes.loading} elevation={0}>
                                <CircularProgress size={130} thickness={5} />
                            </Paper>
                        )}
                        {!response.loading && response.error && (
                            <Alert severity="error" style={{ margin: "100px" }}>
                                {response.message}
                            </Alert>
                        )}
                        {projectData.projectStatus !== "Pending" &&
                            !response.error &&
                            commentList.loaded &&
                            response.data.department && (
                                <PDFViewer className={classes.pdfView}>
                                    <CommentsLetter
                                        title={projectData.title}
                                        students={projectData.groupMembers}
                                        department={projectData.department}
                                        comments={commentList.data}
                                        setting={response.data.department}
                                        fileName={"Evaluation Form"}
                                    />
                                </PDFViewer>
                            )}
                    </Dialog>
                    <Dialog open={document.open} onClose={handleCloseDoc}>
                        <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
                            Upload New Document
                        </DialogTitle>
                        <DialogContent className={classes.dropzone}>
                            <Grid container spacing={1}>
                                {errorIn && (
                                    <Grid item xs={12}>
                                        <Alert severity="error">
                                            Error-Please select your file
                                        </Alert>
                                    </Grid>
                                )}
                                {res.loading && (
                                    <Grid item xs={12}>
                                        <Paper className={classes.loading2} elevation={0}>
                                            <CircularProgress size={130} thickness={5} />
                                        </Paper>
                                    </Grid>
                                )}
                                {!res.loading && !res.request && (
                                    <Grid item xs={12}>
                                        <ThemeProvider theme={theme}>
                                            <DropzoneArea
                                                initialFiles={document.file}
                                                onChange={(file) => {
                                                    setDocument({
                                                        open: document.open,
                                                        name: document.name,
                                                        type: document.type,
                                                        file: file,
                                                    });
                                                    setErrorIn(!1);
                                                }}
                                                onDelete={() => {
                                                    setDocument({
                                                        open: document.open,
                                                        name: document.name,
                                                        type: document.type,
                                                        file: [],
                                                    });
                                                    setErrorIn(!1);
                                                }}
                                                acceptedFiles={[document.type]}
                                                filesLimit={1}
                                                maxFileSize={20000000}
                                                dropzoneText={
                                                    <p>
                                                        Upload{document.name}
                                                        <br />({document.type})
                                                    </p>
                                                }
                                                showPreviews={!0}
                                                showFileNamesInPreview={!0}
                                                showPreviewsInDropzone={!1}
                                                useChipsForPreview
                                                previewGridProps={{
                                                    container: { spacing: 1, direction: "row" },
                                                }}
                                                previewChipProps={{
                                                    classes: { root: classes.previewChip },
                                                }}
                                                previewText="Selected files"
                                            />
                                        </ThemeProvider>
                                    </Grid>
                                )}
                                {res.success && (
                                    <Grid item xs={12}>
                                        <Paper
                                            className={classes.paper}
                                            style={{ backgroundColor: "#dcedc8" }}
                                        >
                                            <CheckCircleIcon className={classes.successIcon} />
                                            <Typography variant="h5">
                                                File Uploaded Successfully!
                                            </Typography>
                                            <Typography>
                                                Congratulations!You have successfully uploaded a new
                                                file
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {res.error && !res.loading && (
                                    <Grid item xs={12}>
                                        <Paper
                                            className={classes.paper}
                                            style={{ backgroundColor: "#ffebee" }}
                                        >
                                            <SentimentVeryDissatisfiedIcon
                                                className={classes.unsuccessIcon}
                                            />
                                            <Typography variant="h5">System Error!</Typography>
                                            <Typography>
                                                File Upload failed!System is unable to upload this file
                                                on database.Please contact IT support to report this
                                                problem.
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                )}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDoc} color="primary">
                                Cancel
                            </Button>
                            {!res.request && !res.loading && (
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    style={{ color: "white" }}
                                    onClick={uploadDocumentClick}
                                >
                                    Save
                                </Button>
                            )}
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </Paper>
    );
};
export default ProjectViewGRC;
