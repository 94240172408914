import React, { useState, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { fetchResources } from "../../apiCalls/resourceCalls";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ResourceList from "./ResourceList";
import UploadResources from "./UploadResources"
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "30rem",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "30rem",
    padding: theme.spacing(8),
  },
}));

const ResourceView = ({ admin }) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [refreshResources, setRefreshResources] = useState(false);
  const classes = useStyles();
    const [open, setOpen] = useState(false);


  const bs = useRef([]);
  const ms = useRef([]);
  const phd = useRef([]);
  const general = useRef([]);
    const handleClickOpen = () => {
      setOpen(true);
    };

  useEffect(() => {
    setLoading(true);
    fetchResources().then((data) => {
      if (data) {
        if (data.error) {
          setLoading(false);
          setSuccess(false);
        } else {
          if (data.resources) {
            bs.current = [];
            ms.current = [];
            phd.current = [];
            general.current=[];
            data.resources.map((resource) => {
              if (resource != null) {
                if (resource.level === "UGRD") bs.current.push(resource);
                else if (resource.level === "GRAD") ms.current.push(resource);
                else if (resource.level === "PG") phd.current.push(resource);
                else if (resource.level === "General")
                  general.current.push(resource);
              }
           
              
            });

            setLoading(false);
            setSuccess(true);
          } else {
            setLoading(false);
            setSuccess(false);
          }
        }
      } else {
        setLoading(false);
        setSuccess(false);
      }
    });
  }, [refreshResources]);
  return (
    <div>
      {admin&&<Box display="flex" justifyContent="flex-end" m={2}>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Upload New File
        </Button>
      </Box>}
      {open ? (
        <UploadResources
          openForm={open}
          setOpenForm={setOpen}
          setRefresh={setRefreshResources}
          refresh={refreshResources}
        />
      ) : (
        ""
      )}
      {loading && (
        <div className={classes.loading}>
          <CircularProgress size={130} thickness={5} />
        </div>
      )}

      {!loading && !success && (
        <Alert severity="error" variant="filled">
          System is unable to fetch Resources, please try again later!
        </Alert>
      )}

      {!loading && success && (
        <Paper className={classes.root} elevation={3}>
          <Box pt={2} textAlign="center" fontWeight={500} fontSize={20}>
            For BS Students
          </Box>
          {bs.current.length > 0 ? (
            <ResourceList
              admin={admin}
              list={bs.current}
              refreshResources={refreshResources}
              setRefreshResources={setRefreshResources}
            />
          ) : (
            <Box pt={2} textAlign="center">
              No resources found.
            </Box>
          )}

          <Box pt={2} textAlign="center" fontWeight={500} fontSize={20}>
            For MS Students
          </Box>
          {ms.current.length > 0 ? (
            <ResourceList
              admin={admin}
              list={ms.current}
              refreshResources={refreshResources}
              setRefreshResources={setRefreshResources}
            />
          ) : (
            <Box pt={2} textAlign="center">
              No resources found.
            </Box>
          )}
          <Box pt={2} textAlign="center" fontWeight={500} fontSize={20}>
            For PhD Students
          </Box>
          {phd.current.length > 0 ? (
            <ResourceList
              admin={admin}
              list={phd.current}
              refreshResources={refreshResources}
              setRefreshResources={setRefreshResources}
            />
          ) : (
            <Box pt={2} textAlign="center">
              No resources found.
            </Box>
          )}
                    <Box pt={2} textAlign="center" fontWeight={500} fontSize={20}>
            General Resources
          </Box>
          {general.current.length > 0 ? (
            <ResourceList
              admin={admin}
              list={general.current}
              refreshResources={refreshResources}
              setRefreshResources={setRefreshResources}
            />
          ) : (
            <Box pt={2} pb={3} textAlign="center">
              No resources found.
            </Box>
          )}
        </Paper>
      )}
    </div>
  );
};
export default ResourceView;
