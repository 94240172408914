const { isLoggedIn } = require("../helpers/authenticationHelp");
const { serverURL } = require("./helper");

export const createProjectAPI = async (data) => {
  isLoggedIn();
  return await fetch(`${serverURL}/create-project/${isLoggedIn().user._id}`, {
    method: "POST",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const uploadDocument = (location, data, projectId) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/uploadFile/${location}?` +
      new URLSearchParams({ projectId: projectId }),
    {
      method: "PUT",
   
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
      body: data,
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const approvalLetterData = (degreeType) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/approvalletter?` +
      new URLSearchParams({ degreeType: degreeType }),
    {
      method: "GET",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const viewProject = (id) => {
  if (id) {
    isLoggedIn();
    return fetch(`${serverURL}/projectView/${id}`, {
      method: "GET",
   
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    isLoggedIn();
    return fetch(`${serverURL}/projectView/${isLoggedIn().user._id}`, {
      method: "GET",
   
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const appointEvaluators = (projectId, evaluationData) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/appointevaluator?` +
      new URLSearchParams({ projectId: projectId }),
    {
      method: "POST",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
      body: JSON.stringify(evaluationData),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addComment = (data, projectId) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/addComment/${isLoggedIn().user._id}?` +
      new URLSearchParams({ projectId: projectId }),
    {
      method: "PUT",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
      body: JSON.stringify({ data }),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchComments = (projectId, version) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/fetchComments/${isLoggedIn().user._id}?` +
      new URLSearchParams({ projectId, version }),
    {
      method: "GET",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteComment = (id, projectId) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/deleteComment/${isLoggedIn().user._id}/${id}?` +
      new URLSearchParams({ projectId }),
    {
      method: "DELETE",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteProject = (id) => {
  isLoggedIn();
  return fetch(`${serverURL}/deleteProject/${isLoggedIn().user._id}/${id}`, {
    method: "DELETE",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const isProject = (id) => {
  isLoggedIn();
  return fetch(`${serverURL}/isProject/${isLoggedIn().user._id}`, {
    method: "GET",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const findProject = (id) => {
  isLoggedIn();
  return fetch(`${serverURL}/isProject/${id}`, {
    method: "GET",
 

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const projectsByCommittee = (token, id) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/projectsbycommittee?` +
      new URLSearchParams({ committeeId: id }),
    {
      method: "GET",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateProjectStatus = (projectId, status) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/projectstatus?` +
      new URLSearchParams({ projectId: projectId }),
    {
      method: "PUT",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
      body: JSON.stringify(status),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const projectMarks = (projectId, projectMarking) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/projectmarks?` +
      new URLSearchParams({ projectId: projectId }),
    {
      method: "PUT",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
      body: JSON.stringify(projectMarking),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const forwardForDefence = (projectId, toUpdate) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/forwardfordefence?` +
      new URLSearchParams({ projectId: projectId }),
    {
      method: "PUT",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
      body: JSON.stringify(toUpdate),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateGRCProjectStatus = (projectId, projectStatus) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/grcprojectstatus?` +
      new URLSearchParams({ projectId: projectId }),
    {
      method: "PUT",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
      body: JSON.stringify(projectStatus),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const projectsBySupervisor = (degreeLevel) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/projectsbysupervisor?` +
      new URLSearchParams({ degreeLevel: degreeLevel }),
    {
      method: "GET",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const createProjectGRCAPI = async (data) => {
  isLoggedIn();
  return await fetch(
    `${serverURL}/create-project-grc/${isLoggedIn().user._id}`,
    {
      method: "POST",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const projectsByProfessor = (id) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/projectsbyprofessor?` +
      new URLSearchParams({ professorId: id }),
    {
      method: "GET",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllProjects = (degree, batch, stage, page, rowsPerPage) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/projects?` +
      new URLSearchParams({ degree, batch, stage, page, rowsPerPage }),
    {
      method: "GET",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const assignP1MarksCall = (updateDetails) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/p1marks`,
    {
      method: "PUT",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
      body: JSON.stringify(updateDetails),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};