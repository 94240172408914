import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Moment from "react-moment";
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import DeleteTask from "./DeleteTask"
import UpdateTask from "./UpdateTask"
import { updateTaskStatus } from "../../apiCalls/taskCalls"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  task: {
    border: "1px",
    marginBottom: "8px",
    backgroundColor: "white",
  },
  title: {
    fontSize: "1.4rem",
  },
  button: {
    color: "white",
    fontWeight: "400",
    letterSpacing: "0.04957em",
  },

  menuOption: {

    "&:hover": {
      backgroundColor: "#66bb6a",
      transition: "background-color 0.3s ease"
    },
  },
  menu: {

    backgroundColor: "#38363659",
    transition: "background-color 0.3s ease"
  },


}))

const Task = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const expire = new Date(props.task.expireDate)
  const currentDate = Date.now();
  const isExpire = expire.getTime() > currentDate

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleOpenUpdate = () => {
    handleClose()
    setOpenUpdate(true)
  };

  const handleOpenDelete = () => {
    handleClose()
    setOpenDelete(true)
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const changeStatus = (newStatus) => {
    updateTaskStatus(newStatus, props.projectId, props.task._id).then((data) => {
      if (data) {
        if (data.error) {
          setSuccess(false);
          setOpen(true);
        } else if (data.message) {
          setSuccess(true);
          props.setRefreshTaskboard(!props.refreshTaskboard)
          setOpen(true)
        }
        else {
          setSuccess(false);
          setOpen(true)
        }
      } else {
        setSuccess(false);
        setOpen(true);
      }
    });

  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {

    return () => {

    }
  }, [])

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={success ? "success" : "error"}
        >
          {success
            ? "Task Status Updated!"
            : "Task status can not be changed!"}
        </Alert>
      </Snackbar>

      <Card p={3} className={classes.task} elevation={3}>
        <CardHeader
          title={props.task.title}
          action={
            props.admin && (
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.menuIcon}
              >
                <MoreVertIcon />
              </IconButton>
            )
          }
          subheader={`Assigned by: ${props.task.author.name}`}
          classes={{ title: classes.title }}
        />

        <CardContent>
          <Typography>{props.task.description}</Typography>

          <Box
            mt={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {props.task.status === "pending" && (
              <Button
                variant="contained"
                fullWidth
                color="primary"
                className={classes.button}
                onClick={() => changeStatus("completed")}
              >
                Mark as Completed
              </Button>
            )}
            {props.task.status === "completed" && (
              <>
                {props.admin ? (
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => changeStatus("pending")}
                  >
                    Mark as Incomplete
                  </Button>
                ) : (
                  <>
                    {isExpire && (
                      <Button
                        variant="contained"
                        fullWidth
                        color="secondary"
                        onClick={() => changeStatus("pending")}
                        className={classes.button}
                      >
                        mark as pending
                      </Button>
                    )}
                  </>
                )}
              </>
            )}

            {props.task.status === "delayed" && props.admin && (
              <Button
                variant="contained"
                onClick={() => changeStatus("completed")}
                fullWidth
              >
                Mark as Completed
              </Button>
            )}
          </Box>
        </CardContent>
        <Divider />

        <CardActions style={{ display: "inherit" }}>
          <Box display="flex" justifyContent="center">
            <Box flexGrow={1} ml={1}>
              <Typography variant="subtitle2">Created At:</Typography>
              <Typography variant="body2">
                <Moment format="DD/MM/YY H:mm">{props.task.createdAt}</Moment>
              </Typography>
            </Box>

            <Box
              mr={1}
              style={
                props.task.status === "delayed" ? { color: "#ed0101" } : {}
              }
            >
              <Typography variant="subtitle2">Due Date:</Typography>
              <Typography variant="body2">
                <Moment format="DD/MM/YY H:mm">
                  {props.task.expireDate}
                </Moment>
              </Typography>
            </Box>
          </Box>
        </CardActions>
      </Card>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
      >
        <MenuItem className={classes.menuOption} onClick={handleOpenUpdate}>
          Edit
        </MenuItem>
        <MenuItem className={classes.menuOption} onClick={handleOpenDelete}>
          Delete
        </MenuItem>
      </Menu>
      {openUpdate && (
        <UpdateTask
          openUpdate={openUpdate}
          setOpenUpdate={setOpenUpdate}
          task={props.task}
          projectId={props.projectId}
          refreshTaskboard={props.refreshTaskboard}
          setRefreshTaskboard={props.setRefreshTaskboard}
        />
      )}
      {openDelete && (
        <DeleteTask
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          task={props.task}
          projectId={props.projectId}
          refreshTaskboard={props.refreshTaskboard}
          setRefreshTaskboard={props.setRefreshTaskboard}
        />
      )}
    </>
  );

}
export default Task;