import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Grid, Card, TableRow, TableCell } from "@material-ui/core";
import { getSignupData } from "../apiCalls/settingCalls";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";

import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import Alert from "@material-ui/lab/Alert";

import { committeeByMember } from "../apiCalls/committeeCalls";
import { isLoggedIn } from "../helpers/authenticationHelp";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MuiTableCell from "@material-ui/core/TableCell";

import Typography from "@material-ui/core/Typography";
import ProjectsInCommittee from "./ProjectsInCommittee";
import MeetingsScheduler from "./MeetingsScheduler";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
const TableCellWithoutBorder = withStyles({
  root: {
    paddingLeft: "1rem",
    margin: "0px",
    padding: "0px",
    borderBottom: "none",
  },
})(MuiTableCell);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  appbar: {
    //width: '100%',
    //height: '100%',
    overflow: "hidden",
    // color: "green",
    backgroundColor: "inherit",
    width: "100%",
    justifyContent: "center",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  noBottomLine: {
    borderBottom: "none",
  },
}));

export default function MemberProjects() {
  const classes = useStyles();
  const [committees, setCommittees] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const token = isLoggedIn().token;
    committeeByMember(token).then((data) => {
      if (data) {
 
        if (data.error) {
          setError(data.error);
        } else if (data.committees) {
          setCommittees(data.committees);
        }
        setLoading(false);
      } else {
        setError("Unable to connect to server");
        setLoading(false);
      }
    });
  }, []);
  const findRole = (committee) => {
    const userId = isLoggedIn().user._id;
    if (userId === committee.chairman) return "Chairman";
    else if (userId === committee.coordinator) return "Coordinator";
    else if (committee.committeeMembers.includes(userId))
      return "Committee Member";
    else if (committee.committeeType==="GRC" && committee.grcCoordinator.includes(userId))
      return "GRC Coordinator";
    else return "Committee Member";
  };

  return (
    <div>
      {loading ? (
        <Box className={classes.root} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : error ? (
        <div className={classes.root}>
          <Alert severity="error">{error}</Alert>
        </div>
      ) : (
        <div className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            scrollButtons="auto"
            style={{ overflow: "auto", width: "100%" }}
            centered
          >
            {committees.map((committee, key) => (
              <Tab key={key} label={committee.name} {...a11yProps(key)} />
            ))}
          </Tabs>
          {committees.map((committee, key) => (
            <TabPanel
              key={key}
              value={value}
              index={key}
              style={{ width: "100%" }}
            >
              <Box m={2}>
                <h3>Committee Details</h3>
              </Box>
              <CardContent>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell>Role in Committee</TableCell>
                        <TableCell>
                          <TableCellWithoutBorder>
                            {findRole(committee)}
                          </TableCellWithoutBorder>
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.noBottomLine}>
                        <TableCell>Committee Type</TableCell>
                        <TableCell>
                          <TableCellWithoutBorder>
                            {committee.committeeType === "PC" &&
                              "Proposal Committee"}
                            {committee.committeeType === "DC" &&
                              "Defence Committee"}
                            {committee.committeeType === "OC" &&
                              "Other Committee"}
                            {committee.committeeType === "GRC" &&
                              "Graduate Research Committee"}
                          </TableCellWithoutBorder>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>For Degree Programs</TableCell>
                        <TableCell>
                          {committee.degree.map((value, key) => (
                            <TableCellWithoutBorder
                              key={key}
                              component="th"
                              scope="row"
                            >
                              {value}
                            </TableCellWithoutBorder>
                          ))}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
              {committee.committeeType === "GRC" &&
              committee.grcCoordinator.includes(isLoggedIn().user._id) ? (
                <Box m={2}>
                  <h3>Committee Meetings Scheduler</h3>
                  <MeetingsScheduler committee={committee} />
                </Box>
              ) : (
                committee.coordinator === isLoggedIn().user._id && (
                  <Box m={2}>
                    <h3>Committee Meetings Scheduler</h3>
                    <MeetingsScheduler committee={committee} />
                  </Box>
                )
              )}
              <Box m={2}>
                <h3>Committee Projects</h3>
              </Box>
              <ProjectsInCommittee committee={committee} />
            </TabPanel>
          ))}
        </div>
      )}
    </div>
  );
}
