import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Moment from "react-moment";
import Link from "@material-ui/core/Link";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { isLoggedIn } from "../../helpers/authenticationHelp";
import UpdateMeeting from "./UpdateMeeting";
import DeleteMeeting from "./DeleteMeeting";
const useStyles = makeStyles((theme) => ({
    list: {

        borderWidth: "thick",
        boxShadow:
            "0px 0px 0px 1px rgb(0 0 0 / 20%), 1px 0px 9px 2px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);",
        borderRadius: "4px",
        marginBottom: theme.spacing(1),
    },

}));

const Meeting = ({ meeting, index, admin, refreshMeeting, setRefreshMeeting }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    let expire = new Date(meeting.date);
    let currentDate = Date.now();
    let isAttended = false;
    if (expire.getTime() < currentDate) {
        isAttended = true;
    }
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpenUpdate = () => {
        handleClose()
        setOpenUpdate(true)
    };
    const handleOpenDelete = () => {
        handleClose()
        setOpenDelete(true)
    };
    return (
        <>

            <ListItem
                key={index}
                alignItems="flex-start"
                className={classes.list}
                style={
                    isAttended
                        ? {
                            color: "grey",
                            opacity: "65%",
                            boxShadow:
                                "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 1px rgb(0 0 0 / 14%), 0px 0px 0px 1px rgb(0 0 0 / 12%)",
                        }
                        : { color: "green" }
                }
            >
                <ListItemText
                    primary={meeting.description}
                    secondary={
                        <React.Fragment>
                            {`hosted by ${meeting.author.name} | `}
                            {meeting.purpose === "PC"
                                ? "Proposal Defence"
                                : meeting.purpose === "DC"
                                    ? "Project Defence"
                                    : "General Meeting"}
                            <Typography
                                variant="body2"
                                style={{
                                    color: "#424242",
                                    fontSize: "12px",
                                    marginTop: "0.5rem",
                                }}
                            >
                                {"Start Time:  "}
                                {
                                    <Moment format="MMMM DD, YYYY hh:mm a">
                                        {meeting.date}
                                    </Moment>
                                }
                                <br />
                                {meeting.link === "" ? (
                                    `Venue: ${meeting.venue}`
                                ) : (
                                    <>
                                        {"Meeting Link: "}
                                        <Link href={meeting.link} target="_blank">
                                            click here
                                        </Link>
                                    </>
                                )}
                            </Typography>
                        </React.Fragment>
                    }
                />
                {(admin || isLoggedIn().user._id.toString() === meeting.author._id.toString()) && <ListItemSecondaryAction>
                    <IconButton aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </ListItemSecondaryAction>}

            </ListItem>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menu}
            >
                <MenuItem className={classes.menuOption} onClick={handleOpenUpdate}>
                    Edit
                </MenuItem>
                <MenuItem className={classes.menuOption} onClick={handleOpenDelete}>
                    Delete
                </MenuItem>
            </Menu>
            {openUpdate && (
                <UpdateMeeting
                    openUpdate={openUpdate}
                    setOpenUpdate={setOpenUpdate}
                    meeting={meeting}
                    refreshMeeting={refreshMeeting}
                    setRefreshMeeting={setRefreshMeeting}
                />
            )}
            {openDelete && (
                <DeleteMeeting
                    openDelete={openDelete}
                    setOpenDelete={setOpenDelete}
                    meeting={meeting}
                    refreshMeeting={refreshMeeting}
                    setRefreshMeeting={setRefreshMeeting}
                />
            )}
        </>
    );
};
export default Meeting;
