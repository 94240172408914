const { serverURL } = require("./helper");
const { isLoggedIn } = require("../helpers/authenticationHelp");

export const addCommittee = (token, committee) => {
  isLoggedIn();
  return fetch(`${serverURL}/committee`, {
    method: "POST",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
    body: JSON.stringify(committee),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getCommittees = (token, degree) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/committees?` + new URLSearchParams({ degree: degree }),
    {
      method: "GET",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const editCommittee = (token, data) => {
  isLoggedIn();
  return fetch(`${serverURL}/committee`, {
    method: "PUT",
 
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deleteCommittee = async (token, id) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/committee?` + new URLSearchParams({ committeeId: id }),
    {
      method: "DELETE",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const committeeByMember = (token) => {
  isLoggedIn();
  return fetch(`${serverURL}/committeesbymember`, {
    method: "GET",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
