import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Moment from "react-moment"
import {
    addComment,
    deleteComment,
} from "../apiCalls/projectCalls";
import { CircularProgress } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import WarningIcon from "@material-ui/icons/Warning";
import InputAdornment from "@material-ui/core/InputAdornment";
import SendIcon from "@material-ui/icons/Send";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
    List,
    Button,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { fetchComments } from "../apiCalls/projectCalls";
import { isLoggedIn } from "../helpers/authenticationHelp";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
    commentList: {
        position: "relative",
        overflow: "auto",
        maxHeight: theme.spacing(40),
        padding: theme.spacing(2),
    },
    loading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));


const CommentSection = ({ projectId,status }) => {
    const classes = useStyles();
    const [commentsList, setCommentsList] = useState("");
    const [loading, setLoading] = useState(true);
    const [commentLoading, setCommentLoading] = useState(true);
    const [comment, setComment] = useState("");
    const [error, setError] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [open, setOpen] = useState(false);
    const [comId, setComId] = useState("");
    const [openAlert, setOpenAlert] = useState(false);

    const handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenAlert(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setComId("");
    };
    const deleteButton = (e) => {
        e.preventDefault();
        setOpenAlert(false);
        setCommentLoading(true);
        deleteComment(comId, projectId).then((data) => {
            if (data) {
                if (!data.error) {
                    setComId("");
                    setRefresh(!refresh);
                    setOpen(false);
                    setOpenAlert(true);
                } else {
                    setError("comment-delete-error");
                    setOpen(false);
                    setOpenAlert(true);
                    setCommentLoading(true);
                }
            } else {
                setError("comment-delete-error");
                setOpen(false);
                setOpenAlert(true);
            }
        });
    };

    const insertComment = (e) => {
        e.preventDefault();
        setCommentLoading(true);
        if (comment.trim().length < 5 || comment.trim().length > 1000) {
            setError("comment");
            setCommentLoading(false);
        } else {
            const data={comment:comment}
            addComment(data, projectId).then((data) => {
                if (data) {
                    if (!data.error) {
                        setComment("");
                        setRefresh(!refresh);
                    } else {
                        setCommentLoading(true);
                    }
                }
            });
        }
    };
    useEffect(() => {
        fetchComments(projectId,1).then((data) => {
            if (data) {
                if (!data.error) {
                    setCommentsList(data);
                    setCommentLoading(false);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        });
    }, [refresh]);
    return (
        <>
            <Snackbar
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

            >
                {error === "comment-delete-error" ? (
                    <Alert onClose={handleCloseAlert} severity="error">
                        Comment Deletion Failed!
                    </Alert>
                ) : (
                    <Alert onClose={handleCloseAlert} severity="success">
                        Comment Deleted Successfully!
                    </Alert>
                )}
            </Snackbar>
            {loading && (
                <Paper className={classes.loading} elevation={0}>
                    <CircularProgress size={60} thickness={5} />
                </Paper>
            )}
            {!loading && (
                <>
                {status!=="Completed"&& <TextField
                        id="comment"
                        placeholder="Write a comment"
                        label="Add Comment"
                        margin="dense"
                        variant="outlined"
                        multiline
                        fullWidth
                        value={comment}
                        onChange={(e) => {
                            setComment(e.target.value);
                            setError("");
                        }}
                        rowsMax="4"
                        error={error === "comment"}
                        helperText={
                            error === "comment"
                                ? "Comment must be between 5-1000 characters"
                                : `${comment.length}/1000`
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title="Add" placement="top">
                                        <IconButton size="small" onClick={insertComment}>
                                            <SendIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                    />}
                   

                    {!commentLoading && commentsList.length > 0 && (
                        <List
                            component="div"
                            disablePadding
                            className={classes.commentList}
                        >
                            <Typography variant="h6">
                                <Box justifyContent="center" display="flex">
                                    Previous Comments
                                </Box>
                            </Typography>
                            {commentsList
                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                .map((comment, index) => (
                                    <React.Fragment key={index}>
                                        <ListItem className={classes.list} alignItems="flex-start">
                                            <ListItemText
                                                primary={
                                                    <React.Fragment>
                                                        <Box display="flex">
                                                            <Box flexGrow={1}>
                                                                {" "}
                                                                <Typography
                                                                    component="span"
                                                                    variant="subtitle2"
                                                                >
                                                                    {comment.author.name}
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                {" "}
                                                                <Typography
                                                                    component="span"
                                                                    variant="caption"
                                                                    color="textSecondary"
                                                                >
                                                                    {
                                                                        <Moment format="MMM D, YYYY, h:mm A">
                                                                            {comment.createdAt}
                                                                        </Moment>
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </React.Fragment>
                                                }
                                                secondary={<Typography>{comment.text}</Typography>}
                                            />
                                            {isLoggedIn().user._id === comment.author._id && (
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        edge="end"
                                                        size="small"
                                                        onClick={handleClickOpen}
                                                    >
                                                        <DeleteIcon
                                                            onClick={() => {
                                                                setComId(comment._id);
                                                            }}
                                                        />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            )}
                                        </ListItem>
                                    </React.Fragment>
                                ))}
                        </List>
                    )}
                    {commentLoading && (
                        <Paper className={classes.loading} elevation={0}>
                            <CircularProgress size={60} thickness={5} />
                        </Paper>
                    )}
                </>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    alignText="center"
                    id="alert-dialog-title"
                    style={{ textAlign: "center" }}
                >
                    <>
                        <WarningIcon style={{ fontSize: "6rem", color: "#fb8c00" }} />
                        <Typography variant="h6"> Delete Comment?</Typography>
                    </>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting an comment will permanently remove it from the portal. You
                        can not undo this action. Are you sure you want to delete this
                        comment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        cancel
                    </Button>
                    <Button
                        onClick={deleteButton}
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default CommentSection;