import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Moment from "react-moment";
import Link from "@material-ui/core/Link";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Meeting from './Meeting'
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
    },
}));




const MeetingList = ({ admin, list,refreshMeeting,setRefreshMeeting }) => {
    const classes = useStyles();
    return (
        <List className={classes.root}>
            {list &&
                list.length > 0 &&
                list.map((meeting, index) => (
                    <Meeting meeting={meeting} index={index} admin={admin} refreshMeeting={refreshMeeting} setRefreshMeeting={setRefreshMeeting} />
                ))}
        </List>
    );
};
export default MeetingList;
