import Dashboard from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BallotIcon from "@material-ui/icons/Ballot";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Language from "@material-ui/icons/Language";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import SettingsIcon from "@material-ui/icons/Settings";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import Schedule from "@material-ui/icons/Schedule";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
// core components
import TaskBoard from "../pages/TaskBoard";
import Documentation from "../pages/Documentation";
import Something from "../pages/Something";
import Users from "../pages/Users";
import Meetings from "../pages/Meetings";
import UserQue from "../pages/UserQue";
import Supervisee from "../pages/Supervisee";
import SystemSetting from "../pages/SystemSetting";
import Committee from "../pages/Committee";
import CommitteeProjects from "../pages/CommitteeProjects";
import BrodcastMessages from "../pages/BrodcastMessages";
import Projects from "../pages/Projects";
import Resources from "../pages/Resources"

const dashboardRoutes = [
  {
    path: "/project",
    name: "Project",
    icon: AssignmentIcon,
    component: Documentation,
    layout: "/student",
  },
  {
    path: "/users",
    name: "Users",
    icon: PersonAddIcon,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/user-queue",
    name: "Pending Users",
    icon: AddToQueueIcon,
    component: UserQue,
    layout: "/program-office",
  },
  {
    path: "/supervisee",
    name: "Supervisee",
    icon: SupervisorAccountIcon,
    component: Supervisee,
    layout: "/professor",
  },
  {
    path: "/committee",
    name: "Committees",
    icon: CardMembershipIcon,
    component: Committee,
    layout: "/admin",
  },
  ,
  {
    path: "/projects",
    name: "Projects",
    icon: LibraryBooksIcon,
    component: Projects,
    layout: "/admin",
  },
  {
    path: "/broadcast-messages",
    name: "Broadcasts",
    icon: AnnouncementIcon,
    component: BrodcastMessages,
    layout: "/admin",
  },

  {
    path: "/committee-projects",
    name: "Committee Projects",
    icon: AllInboxIcon,
    component: CommitteeProjects,
    layout: "/professor",
  },
  {
    path: "/taskboard",
    name: "Tasks",
    icon: AllInboxIcon,
    component: TaskBoard,
    layout: "/student",
  },
  {
    path: "/meetings",
    name: "Meetings",
    icon: Schedule,
    component: Meetings,
    layout: "/student",
  },
  {
    path: "/meetings",
    name: "Meetings",
    icon: Schedule,
    component: Meetings,
    layout: "/professor",
  },
  {
    path: "/broadcast-messages",
    name: "Broadcasts",
    icon: AnnouncementIcon,
    component: BrodcastMessages,
    layout: "/program-office",
  },
  {
    path: "/broadcast-messages",
    name: "Broadcasts",
    icon: AnnouncementIcon,
    component: BrodcastMessages,
    layout: "/professor",
  },
  {
    path: "/broadcast-messages",
    name: "Broadcasts",
    icon: AnnouncementIcon,
    component: BrodcastMessages,
    layout: "/student",
  },
  {
    path: "/resources",
    name: "Resources",
    icon: InsertDriveFileIcon,
    component: Resources,
    layout: "/student",
  },
  {
    path: "/resources",
    name: "Resources",
    icon: InsertDriveFileIcon,
    component: Resources,
    layout: "/admin",
  },
  {
    path: "/system-setting",
    name: "System Settings",
    icon: SettingsIcon,
    component: SystemSetting,
    layout: "/admin",
  },
];

export default dashboardRoutes;
