import React, { useState, useEffect } from "react";
//---
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";

import TableBody from "@material-ui/core/TableBody";
import { TableRow, TextField } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EditIcon from "@material-ui/icons/Edit";
import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import MuiTableCell from "@material-ui/core/TableCell";
import ProjectView from "../components/ProjectView";
import Snackbar from "@material-ui/core/Snackbar";
import RowGRCCommittee from "./RowGRCCommittee";
import RowUGPCCommittee from "./RowUGPCCommittee";

//---
import {
  projectsByCommittee,
  updateProjectStatus,
  appointEvaluators,
  projectMarks,
} from "../apiCalls/projectCalls";
import { isLoggedIn } from "../helpers/authenticationHelp";
import SearchProfessor from "./SearchProfessor";
import SearchEvaluator from "./SearchEvaluator";
import {
  WAIT,
  ERROR,
  SUCCESS,
  REJECTED,
  PENDING,
  APPROVED,
} from "../variables/enums";
const GreenButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
const materialTheme = createMuiTheme({
  palette: {
    primary: green,
  },
  overrides: {
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: "white",
      },
      toolbarTxt: {
        color: "#c8e6c9",
      },
    },
    MuiTabs: {
      scroller: {
        color: "#eeeeee",
      },
    },
  },
});
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  bold: {
    fontWeight: "bold",
  },
});

const TableCell = withStyles({
  root: {
    //borderBottom: "none",
    minWidth: "15rem",
    padding: ".8rem",
  },
})(MuiTableCell);

const styles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "green",
  },
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  "@keyframes delayedHide": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },

  autoHide: {
    animationName: "delayedHide",
    animationDuration: "300ms",
    animationDelay: (props) => props.delay || "2s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
}));

export default function ProjectsInCommittee({ committee }) {
  const classes = styles();

  const [projects, setProjects] = useState({ list: [], loaded: WAIT, msg: "" });
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    //we will get projects by committee
    const token = isLoggedIn().token;
    projectsByCommittee(token, committee._id).then((data) => {
      if (data) {
        if (data.error) {
          setProjects({ msg: data.error, loaded: ERROR });
        } else {
          setProjects({ list: data.projects, loaded: SUCCESS });
        }
      } else {
        setProjects({ msg: "Unabe to load data", loaded: ERROR });
      }
    });
  }, [committee._id, refresh]);

  return (
    <div styles={{ width: "100%", height: "100%" }}>
      {projects.loaded === WAIT ? (
        <Box className={classes.root} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : projects.loaded === ERROR ? (
        <div className={classes.root}>
          <Alert severity="error">{projects.msg}</Alert>
        </div>
      ) : (
        projects.loaded === SUCCESS && (
          <CardContent>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  {projects.list.length > 0 ? (
                    projects.list.map((project, key) => {
                      if (project.degreeLevel === "UGRD") {
                        return (
                          <RowUGPCCommittee
                            key={key}
                            project={project}
                            committee={committee}
                            refresh={refresh}
                            setRefresh={setRefresh}
                          />
                        );
                      } else {
                        return (
                          <RowGRCCommittee
                            key={key}
                            project={project}
                            committee={committee}
                            refresh={refresh}
                            setRefresh={setRefresh}
                          />
                        );
                      }
                    })
                  ) : (
                    <div>
                      <Alert severity="info">No projects at the moment</Alert>
                    </div>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        )
      )}
    </div>
  );
}
