const { serverURL } = require("./helper");
const { isLoggedIn } = require("../helpers/authenticationHelp");

export const generateMeetingLink = async (token, data) => {
  isLoggedIn();
  return await fetch(`${serverURL}/meetinglink`, {
    method: "POST",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const createMeeting = async (token, data) => {
  isLoggedIn();
  return await fetch(`${serverURL}/addnewmeeting`, {
    method: "POST",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const fetchMeetings = async () => {
  isLoggedIn();
  return await fetch(`${serverURL}/fetchMeetings/`, {
    method: "GET",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateMeeting = async (data, meetingId) => {
  isLoggedIn();
  return await fetch(
    `${serverURL}/updateMeeting?` +
      new URLSearchParams({ meetingId: meetingId }),
    {
      method: "PUT",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteMeeting = async (meetingId) => {
  isLoggedIn();
  return await fetch(
    `${serverURL}/deleteMeeting?` +
      new URLSearchParams({ meetingId: meetingId }),
    {
      method: "DELETE",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
