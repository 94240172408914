import React, { useEffect, useState } from "react";
import { Tabs, Tab, Button, Box } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import PageLayout from "../components/PageLayout";
import { isProject } from "../apiCalls/projectCalls.js";
import CreateProject from "../components/CreateProject";
import ProjectView from "../components/ProjectView.js";
import ProjectViewGRC from "../components/ProjectViewGRC.js";
import Alert from "@material-ui/lab/Alert";
import { isLoggedIn } from "../helpers/authenticationHelp";
import CircularProgress from "@material-ui/core/CircularProgress";
import CreateProjectGRC from "../components/CreateProjectGRC.js"
const a11yprops = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};
const defaultProps = {
  bgcolor: "background.paper",
  marginTop: 3,
  border: 1,
  borderColor: "grey.500",
  style: { width: "initial", height: "20rem", borderStyle: "dashed" },
};
const Documentation = ({ Data }) => {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [loadData, setLoadData] = useState("");
  const [loading, setLoading] = useState(false);
  const [projectExist, setProjectExist] = useState("");
  const [isEligible,setIsEligible]=useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    setLoading(true);
    isProject().then((data) => {
      if (data) {
        if (data.error) {
          setIsEligible(data.isEligible)
          setProjectExist("not-exist");
          setLoading(false);
        } else if (data.message === "Project Exists") {
          setProjectExist("exist");
          setLoading(false);
        }
      } else {
        setLoadData("Failed to connect to server");
        setLoading(false);
      }
    });
  }, [setProjectExist]);
  return (
    <PageLayout Title={Data.name} Icon={Data.icon}>
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : loadData === "" ? (
        <>
          {projectExist === "not-exist" && (
            <>
              {isEligible ? (
                <>
                  {" "}
                  <Box display="flex" justifyContent="flex-end" m={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClickOpen}
                    >
                      CREATE PROJECT
                    </Button>
                  </Box>
                  <Box {...defaultProps}></Box>
                </>
              ) : (
                <Box display="flex" justifyContent="center">
                  <Alert style={{ fontWeight: "bold" }} severity="warning">
                    You are not eligible to create a project. Please contact your program office to verify your eligibility.
                  </Alert>
                </Box>
              )}
            </>
          )}

          {projectExist === "exist" && (
            <>
              {isLoggedIn().user.level === "UGRD" ? (
                <ProjectView setProjectExist={setProjectExist} />
              ) : isLoggedIn().user.level === "GRAD" ||
                isLoggedIn().user.level === "PG" ? (
                <ProjectViewGRC setProjectExist={setProjectExist} />
              ) : (
                ""
              )}
            </>
          )}
        </>
      ) : (
        <Box>
          <Alert severity="error">{loadData}</Alert>
        </Box>
      )}

      {open ? (
        <>
          {isLoggedIn().user.level === "UGRD" ? (
            <CreateProject appear={setOpen} setProjectExist={setProjectExist} />
          ) : isLoggedIn().user.level === "GRAD" ||
            isLoggedIn().user.level === "PG" ? (
            <CreateProjectGRC
              appear={setOpen}
              setProjectExist={setProjectExist}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        ""
      )}
    </PageLayout>
  );
};

export default Documentation;