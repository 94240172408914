import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CodeIcon from "@material-ui/icons/Code";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { GetAppOutlined, Send } from "@material-ui/icons";
import { Toolbar, AppBar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { DropzoneArea } from "material-ui-dropzone";
import Chip from "@material-ui/core/Chip";
import HelpIcon from "@material-ui/icons/Help";
import { viewProject, deleteProject } from "../apiCalls/projectCalls";
import { CircularProgress } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import Hidden from "@material-ui/core/Hidden";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import Snackbar from "@material-ui/core/Snackbar";
import { AprrovalLetter } from "./ApprovalLetter";
import MuiAlert from "@material-ui/lab/Alert";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CommentSection from "./CommentSection";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { isLoggedIn, roleNow } from "../helpers/authenticationHelp";
import ApprovalLetter from "./ApprovalLetter";
import { approvalLetterData } from "../apiCalls/projectCalls";
import AddIcon from "@material-ui/icons/Add";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { uploadDocument } from "../apiCalls/projectCalls";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import MenuList from "@material-ui/core/MenuList";
import Moment from "react-moment";
import MuiTableCell from "@material-ui/core/TableCell";
import { withStyles} from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
const {serverURL} =require("../apiCalls/helper")

const TableCell = withStyles({
  root: {
    //borderBottom: "none",
    minWidth: "15rem",
    padding: ".8rem",
  },
})(MuiTableCell);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
    minHeight: "25rem",
  },
  dropzone: {
    marginTop: theme.spacing(2),

    width: "25rem",
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
    backgroundColor: "#eeeeee",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    textAlign: "center",
  },
  successIcon: {
    color: "#7cb342",
    fontSize: "8rem",
  },
  fileIcon: {
    fontSize: "5rem",
    color: "#37474f",
  },
  unsuccessIcon: {
    color: "#c62828",
    fontSize: "8rem",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    padding: theme.spacing(8),
    minHeight: "25rem",
  },
  loading2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(8),
  },
  section: {
    padding: theme.spacing(2),
    width: "200px",
  },
  bold: {
    fontWeight: "bold",
  },
  chip: {
    color: "white",
    fontSize: "16px",
    letterSpacing: "0.8px",
    backgroundColor: "#3576a5",
  },
  modulesChip: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    backgroundColor: "#ff8d00",
    color: "white",
    borderStyle: "solid",
    borderWidth: "1.5px",
    fontSize: "14px",
    letterSpacing: "1px",
  },
  card: {
    marginTop: theme.spacing(1),
  },
  cardHeader: {
    paddingBottom: theme.spacing(0.5),
    backgroundColor: "#757575",
    color: "white",
  },
  cardContent: {
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    backgroundColor: "#eeeeee",
  },
  pdfView: {
    marginTop: theme.spacing(8),
    width: "100%",
    height: "100%",
  },
}));
const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiTypography: {
      // Name of the rule
      colorTextSecondary: {
        // Some CSS
        color: "white",
      },
      body1: {
        fontWeight: "normal",
      },
    },
    MuiDropzoneArea: {
      root: {
        backgroundColor: "#f9fbe7",
      },
    },
  },
});



const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));



const ProjectView = ({ setProjectExist, project, userId }) => {
  const classes = useStyles();
  const [projectData, setProjectData] = useState("");
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [error, setError] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
  const [document, setDocument] = useState({
    open: false,
    name: "",
    type: "",
    file: [],
  });
  const [letterViewer, setLetterViewer] = useState(false);
  const [openI, setOpenI] = React.useState(false);
  const [errorIn, setErrorIn] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [res, setRes] = useState({
    error: false,
    loading: false,
    success: false,
    request: false,
  });
  const formData = new FormData();
  const [response, setResponse] = useState({
    loading: false,
    error: false,
    message: "",
    data: {
      chairmanName: "",
      committeeHeadName: "",
      departmentName:"",
      departmentFullName:"",
    },
  });

  const handleClickTest = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseTest = () => {
    setAnchorEl(null);
  };



  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenI(false);
    }
  }

  const handleClickOpenDoc = (name, type) => {
    setDocument({ open: true, name: name, type: type, file: [] });
  };

  const uploadDocumentClick = async (e) => {
    e.preventDefault();
    if (
      document.file.length === 0 ||
      document.type === "" ||
      document.name === ""
    ) {
      setErrorIn(true);
    } else {
      setRes({ error: false, loading: true, success: false, request: true });

      var location;
      if (document.name === "Propsal Presentation") location = "presentation";
      else if (document.name === "Final Documentation")
        location = "documentation";
      else if (document.name === "Code File") location = "code";
      else if (document.name === "Plagiarism Report") location = "plagiarism";
      else location = "other";

      formData.set("file", document.file[0]);
      uploadDocument(location, formData, projectData._id).then((data) => {
        if (data && !data.error) {
          setRes({
            error: false,
            loading: false,
            success: true,
            request: true,
          });
          setRefresh(!refresh);
        } else {
          setRes({
            error: true,
            loading: false,
            success: false,
            request: true,
          });
        }
      });
    }
  };
  const uploadOrNot = () => {
    if (projectData.groupMembers.length === 1) {
      if (projectData.groupMembers[0]._id === isLoggedIn().user._id)
        return true;
    }
    if (projectData.groupMembers.length === 2) {
      if (projectData.groupMembers[0]._id === isLoggedIn().user._id)
        return true;
      if (projectData.groupMembers[1]._id === isLoggedIn().user._id)
        return true;
    }
    if (projectData.projectStatus === "Approved") {
      if (projectData.supervisor._id === isLoggedIn().user._id) {
        return true;
      }
    } else return false;
    return false;
  };
  const handleCloseDoc = () => {
    setDocument({ open: false, name: "", type: "", file: [] });
    setRes({ error: false, loading: false, success: false, request: false });
  };

  const closeLetterViewer = () => {
    setLetterViewer(false);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const openLetterViewer = () => {
    setLetterViewer(true);
    setResponse({
      loading: true,
      error: false,
      message: "",
      data: {
        chairmanName: "",
        committeeHeadName: "",
        departmentName: "",
        departmentFullName: "",
      },
    });
    approvalLetterData(projectData.department)
      .then((result) => {
        if (
          result.department&&result.department.chairmanName&&result.department.departmentFullName&&result.committeeHeadName
      
        ) {
          setResponse({
            loading: false,
            error: false,
            message: "",
            data: {
              chairmanName: result.department.chairmanName,
              committeeHeadName: result.committeeHeadName,
              departmentName: result.department.departmentName,
              departmentFullName: result.department.departmentFullName,
            },
          });
        } else
          setResponse({
            loading: false,
            error: true,
            message: "Department/Committee info is invalid! Please try again",
            data: {
              chairmanName: "",
              committeeHeadName: "",
              departmentName: "",
              departmentFullName: "",
            },
          });
      })
      .catch((error) => {
        setResponse({
          loading: false,
          error: true,
          message: "Unable to fetch Chairman Info",
          data: {
            chairmanName: "",
            committeeHeadName: "",
            departmentName:"",
                  departmentFullName:"",

          },
        });
      });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const deleteProjectButton = (e) => {
    deleteProject(projectData._id).then((data) => {
      if (data) {
        if (!data.error) {
          setLoading(true);
          setProjectExist("not-exist");
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    });
    setOpen(false);
    setOpenAlert(true);
  };
  useEffect(() => {
    if (project) {
      setProjectData(project);

      setLoading(false);
    } else {
      if (userId) {
        viewProject(userId).then((data) => {
          if (data) {
            if (!data.error) {
              setProjectData(data);
              setLoading(false);
            }
          }
        });
      } else {
        viewProject().then((data) => {
          if (data) {
            if (!data.error) {
              setProjectData(data);
              setLoading(false);
            }
          }
        });
      }
    }
  }, [refresh, setRefresh]);
  return (
    <Paper className={classes.root} elevation={3}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        {error ? (
          <Alert onClose={handleCloseAlert} severity="error">
            Project Deletion Failed!
          </Alert>
        ) : (
          <Alert onClose={handleCloseAlert} severity="success">
            Project Deleted Successfully!
          </Alert>
        )}
      </Snackbar>
      {loading === true && (
        <Paper className={classes.loading} elevation={0}>
          <CircularProgress size={130} thickness={5} />
        </Paper>
      )}
      {loading === false && (
        <>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.section}>
              <Typography variant="h6">
                <Box textAlign="center" mb={2}>
                  GROUP DETAILS
                </Box>
              </Typography>
              <Box mb={2}>
                <Typography className={classes.bold}>Group Name</Typography>
                <p>{projectData.groupName}</p>
              </Box>
              <Box mb={2}>
                <Typography className={classes.bold}>Description</Typography>
                <Typography style={{ wordWrap: "break-word" }}>
                  {projectData.groupDescription}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography className={classes.bold}>Group Members</Typography>

                <Box m={1}>
                  <Grid container spacing={2}>
                    {projectData.groupMembers.length > 0 &&
                      projectData.groupMembers.map((m, index) => {
                        return (
                          <Grid item sm={12} xl={6}>
                            <Card key={index} className={classes.card}>
                              <ThemeProvider theme={theme}>
                                <CardHeader
                                  title={m.name}
                                  subheader={m.email}
                                  className={classes.cardHeader}
                                />
                              </ThemeProvider>
                              <CardContent
                                className={classes.cardContent}
                                style={{ paddingBottom: "0.3rem" }}
                              >
                                <Typography variant="button">
                                  <Box display="flex">
                                    <Box flexGrow={1}>
                                      Reg # {m.student_details.regNo}
                                    </Box>
                                    <Box>Batch: {m.student_details.batch}</Box>
                                  </Box>
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Box>
                <Box mt={3} mb={3}>
                  <Divider />
                </Box>
                <Typography variant="h6">
                  <Box textAlign="center" mb={2}>
                    PROJECT DETAILS
                    {typeof projectData.supervisor !== "undefined" &&
                      projectData.projectStatus !== "Pending" && (
                        <Box fontWeight="fontWeightLight" fontSize={16}>
                          {`(Under supervision of Mr. ${projectData.supervisor.name})`}
                        </Box>
                      )}
                  </Box>
                </Typography>
                <Box mb={2}>
                  <Typography className={classes.bold} gutterBottom>
                    Status
                  </Typography>

                  <Chip
                    label={projectData.projectStatus}
                    className={classes.chip}
                    style={
                      projectData.projectStatus === "Rejected"
                        ? { backgroundColor: "Red" }
                        : projectData.projectStatus === "Pending"
                        ? { backgroundColor: "Blue" }
                        : { backgroundColor: "Green" }
                    }
                  />
                </Box>

                <Box mb={2}>
                  <Typography className={classes.bold}>Abstract</Typography>
                  <Typography style={{ wordWrap: "break-word" }}>
                    {projectData.abstract}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography className={classes.bold}>Scope</Typography>
                  <Typography style={{ wordWrap: "break-word" }}>
                    {projectData.scope}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography className={classes.bold}>
                    Major Modules
                  </Typography>
                  <Box m={1}>
                    {projectData.majorModules.length > 0 &&
                      projectData.majorModules.map((m, index) => {
                        return (
                          <Chip
                            key={index}
                            label={m}
                            className={classes.modulesChip}
                          />
                        );
                      })}
                  </Box>
                </Box>

                {projectData.internal &&
                  projectData.external &&
                  projectData.internal.examiner &&
                  projectData.external.examiner && (
                    <>
                      <Box mt={3} mb={3}>
                        <Divider />
                      </Box>
                      <Typography variant="h6">
                        <Box textAlign="center" mb={2}>
                          EVLUATION SCHEDULE
                        </Box>
                      </Typography>
                      <Box mb={2}>
                        <Typography className={classes.bold} gutterBottom>
                          Internal Examiner
                        </Typography>
                        {`Mr. ${projectData.internal.examiner.name}`}
                        <br />
                        {`Venue: ${projectData.internal.venue}`}
                        <br />
                        {"Date: "}
                        <Moment format="MMM D, YYYY, h:mm A">
                          {projectData.internal.date}
                        </Moment>
                      </Box>
                      <Box mb={2}>
                        <Typography className={classes.bold} gutterBottom>
                          External Examiner
                        </Typography>
                        {`Mr. ${projectData.external.examiner.name}`}
                        <br />
                        {`Venue: ${projectData.external.venue}`}
                        <br />
                        {"Date: "}
                        <Moment format="MMM D, YYYY, h:mm A">
                          {projectData.external.date}
                        </Moment>
                      </Box>
                    </>
                  )}

                {projectData.projectStatus === "Completed" &&
                  projectData.marks &&
                  projectData.marks.external &&
                  projectData.marks.internal &&
                  projectData.marks.supervisor &&
                  isLoggedIn().user.roles.includes("Admin") && (
                    <>
                      <Box mt={3} mb={3}>
                        <Divider />
                      </Box>
                      <Typography variant="h6">
                        <Box textAlign="center" mb={2}>
                          MARKS
                        </Box>
                      </Typography>
                      <Table>
                        <TableRow style={{ width: "100%" }}>
                          <TableCell>Project Supervisor</TableCell>
                          <TableCell>{project.supervisor.name}</TableCell>
                          <TableCell style={{ paddingLeft: "5rem" }}>
                            {project.marks.supervisor}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Project Internal</TableCell>
                          <TableCell>
                            {project.internal.examiner.name}
                          </TableCell>
                          <TableCell style={{ paddingLeft: "5rem" }}>
                            {project.marks.internal}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Project 1 Marks</TableCell>
                          <TableCell>
                            {project.supervisor.name}
                          </TableCell>
                          <TableCell style={{ paddingLeft: "5rem" }}>
                            {project.marks.p1Marks ? project.marks.p1Marks : "N/A" }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Project External</TableCell>
                          <TableCell>
                            {project.external.examiner.name}
                          </TableCell>

                          <TableCell style={{ paddingLeft: "5rem" }}>
                            {project.marks.external}
                          </TableCell>
                        </TableRow>
                      </Table>
                    </>
                  )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.section}>
              <Box>
                <Typography variant="h6">
                  <Box textAlign="center" mb={2}>
                    DOCUMENTS
                  </Box>
                </Typography>
                <Box mb={2}>
                  <Grid container style={{ textAlign: "center" }}>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <Typography className={classes.bold}>Vision</Typography>
                      <Link
                        href={`${serverURL}/visionDocuments/${projectData.document.filename}`}
                        target="_blank"
                      >
                        <IconButton edge="start" size="medium">
                          <PictureAsPdfIcon className={classes.fileIcon} />
                        </IconButton>
                        <Typography
                          style={{ display: "block", lineBreak: "anywhere" }}
                          variant="caption"
                        >
                          {projectData.document.originalname}
                        </Typography>
                      </Link>
                    </Grid>

                    {typeof projectData.presentation.filename !==
                      "undefined" && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Typography className={classes.bold}>
                          Presentation
                        </Typography>
                        <Link
                          href={`${serverURL}/presentation/${projectData.presentation.filename}`}
                          target="_blank"
                        >
                          <IconButton edge="start" size="medium">
                            <SlideshowIcon className={classes.fileIcon} />
                          </IconButton>
                          <Typography
                            style={{ display: "block", lineBreak: "anywhere" }}
                            variant="caption"
                          >
                            {projectData.presentation.originalname}
                          </Typography>
                        </Link>
                      </Grid>
                    )}
                    {typeof projectData.documentation.filename !==
                      "undefined" && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Typography className={classes.bold}>
                          Documentation
                        </Typography>
                        <Link
                          href={`${serverURL}/documentation/${projectData.documentation.filename}`}
                          target="_blank"
                        >
                          <IconButton edge="start" size="medium">
                            <PictureAsPdfIcon className={classes.fileIcon} />
                          </IconButton>
                          <Typography
                            style={{ display: "block", lineBreak: "anywhere" }}
                            variant="caption"
                          >
                            {projectData.documentation.originalname}
                          </Typography>
                        </Link>
                      </Grid>
                    )}
                    {typeof projectData.code.filename !== "undefined" && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Typography className={classes.bold}>Code</Typography>
                        <Link
                          href={`${serverURL}/code/${projectData.code.filename}`}
                          target="_blank"
                        >
                          <IconButton edge="start" size="medium">
                            <CodeIcon className={classes.fileIcon} />
                          </IconButton>
                          <Typography
                            style={{ display: "block", lineBreak: "anywhere" }}
                            variant="caption"
                          >
                            {projectData.code.originalname}
                          </Typography>
                        </Link>
                      </Grid>
                    )}
                    {typeof projectData.plagiarismReport.filename !==
                      "undefined" && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Typography className={classes.bold}>
                          Plagiarism Report
                        </Typography>
                        <Link
                          href={`${serverURL}/plagiarism/${projectData.plagiarismReport.filename}`}
                          target="_blank"
                        >
                          <IconButton edge="start" size="medium">
                            <PictureAsPdfIcon className={classes.fileIcon} />
                          </IconButton>
                          <Typography
                            style={{ display: "block", lineBreak: "anywhere" }}
                            variant="caption"
                          >
                            {projectData.plagiarismReport.originalname}
                          </Typography>
                        </Link>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                {uploadOrNot() && (
                  <Box display="flex" flexDirection="row-reverse" p={2}>
                    {projectData.projectStatus!=="Completed"&&        <Button
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="contained"
                      onClick={handleClickTest}
                      variant="contained"
                      style={{ color: "white" }}
                      color="primary"
                    >
                      Upload New Document
                    </Button>}
            
                    <StyledMenu
                      id="customized-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseTest}
                    >
                      
                              <MenuList
                                autoFocusItem={openI}
                                id="menu-list-grow-view"
                                onKeyDown={handleListKeyDown}
                              >
                                <MenuItem
                                  onClick={() =>
                                    handleClickOpenDoc(
                                      "Propsal Presentation",
                                      ".pptx,.ppt"
                                    )
                                  }
                                >
                                  Propsal Presentation
                                </MenuItem>
                                {projectData.projectStatus === "Approved" && (
                                  <>
                                    <MenuItem
                                      onClick={() =>
                                        handleClickOpenDoc(
                                          "Final Documentation",
                                          ".pdf"
                                        )
                                      }
                                    >
                                      Final Documentation
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        handleClickOpenDoc("Code File", ".zip")
                                      }
                                    >
                                      Code File
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        handleClickOpenDoc(
                                          "Plagiarism Report",
                                          ".pdf"
                                        )
                                      }
                                    >
                                      Plagiarism Report
                                    </MenuItem>
                                  </>
                                )}
                              </MenuList>
                              
                              
                    </StyledMenu>
                  </Box>
                )}
                <Box mt={3}>
                  <Divider />
                </Box>
                <Box mt={3}>
                  <Typography variant="h6">
                    <Box textAlign="center" mb={2}>
                      COMMENT SECTION
                    </Box>
                  </Typography>
                  <CommentSection projectId={projectData._id} status={projectData.projectStatus} />
                </Box>
              </Box>
            </Grid>
          </Grid>
          {(roleNow() === "Student") &
          (projectData.projectStatus === "Pending" ||
            projectData.projectStatus === "Rejected") ? (
            <Box display="flex" flexDirection="row-reverse" p={2} pr={2}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                endIcon={<DeleteIcon />}
                onClick={handleClickOpen}
              >
                Delete Project
              </Button>
            </Box>
          ) : (
            ""
          )}
          {projectData.projectStatus &&
            projectData.projectStatus !== "Pending" &&
            projectData.projectStatus !== "Rejected" &&
            projectData.supervisor &&
            projectData.supervisor.name && (
              <Box display="flex" flexDirection="row-reverse" p={2} pr={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={openLetterViewer}
                >
                  Acceptance Letter
                </Button>
              </Box>
            )}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              alignText="center"
              id="alert-dialog-title"
              style={{ textAlign: "center" }}
            >
              <>
                <HelpIcon style={{ fontSize: "6rem", color: "#8e24aa" }} />
                <Typography variant="h6"> Delete Project?</Typography>
              </>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deleting this project will permanently remove it from the
                portal. You can not undo this action. Are you sure you want to
                delete this comment?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                cancel
              </Button>
              <Button
                onClick={deleteProjectButton}
                variant="contained"
                color="primary"
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={letterViewer} onClose={closeLetterViewer} fullScreen>
            <AppBar style={{ backgroundColor: "#09510c" }}>
              <Toolbar>
                <Box
                  display="flex"
                  flexGrow={1}
                  letterSpacing={2}
                  color="white"
                  fontSize="h6.fontSize"
                  fontWeight="fontWeightBold"
                >
                  ACCEPTANCE LETTER
                </Box>
                <Box>
                  {" "}
                  <IconButton
                    edge="start"
                    color="inherit"
                    style={{ color: "white" }}
                    onClick={closeLetterViewer}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Toolbar>
            </AppBar>

            {response.loading && (
              <Paper className={classes.loading} elevation={0}>
                <CircularProgress size={130} thickness={5} />
              </Paper>
            )}
            {!response.loading && response.error && (
              <Alert severity="error" style={{ margin: "100px" }}>
                {response.message}
              </Alert>
            )}
            {!response.loading &&
              !response.error &&
              response.data.chairmanName !== "" &&
              projectData.projectStatus &&
              (projectData.projectStatus !== "Pending" ||
                projectData.projectStatus !== "Rejected") &&
              projectData.supervisor.name && (
                <PDFViewer className={classes.pdfView}>
                  <ApprovalLetter
                    title={projectData.title}
                    students={projectData.groupMembers}
                    supervisor={projectData.supervisor.name}
                    date={projectData.acceptanceLetter.issueDate}
                    chairmanName={response.data.chairmanName}
                    committeeHeadName={response.data.committeeHeadName}
                    department={projectData.department}
                    fileName={projectData.acceptanceLetter.name}
                    departmentName={response.data.departmentName}
                    departmentFullName={response.data.departmentFullName}
                  />
                </PDFViewer>
              )}
          </Dialog>
          <Dialog open={document.open} onClose={handleCloseDoc}>
            <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
              Upload New Document
            </DialogTitle>
            <DialogContent className={classes.dropzone}>
              <Grid container spacing={1}>
                {errorIn && (
                  <Grid item xs={12}>
                    <Alert severity="error">
                      Error - Please select your file
                    </Alert>
                  </Grid>
                )}

                {res.loading && (
                  <Grid item xs={12}>
                    <Paper className={classes.loading2} elevation={0}>
                      <CircularProgress size={130} thickness={5} />
                    </Paper>
                  </Grid>
                )}

                {!res.loading && !res.request && (
                  <Grid item xs={12}>
                    <ThemeProvider theme={theme}>
                      <DropzoneArea
                        initialFiles={document.file}
                        onChange={(file) => {
                          setDocument({
                            open: document.open,
                            name: document.name,
                            type: document.type,
                            file: file,
                          });
                          setErrorIn(false);
                        }}
                        onDelete={() => {
                          setDocument({
                            open: document.open,
                            name: document.name,
                            type: document.type,
                            file: [],
                          });
                          setErrorIn(false);
                        }}
                        acceptedFiles={[document.type]}
                        filesLimit={1}
                        maxFileSize={20000000}
                        dropzoneText={`Upload ${document.name} \n (${document.type})`}
                        showPreviews={true}
                        showFileNamesInPreview={true}
                        showPreviewsInDropzone={false}
                        useChipsForPreview
                        previewGridProps={{
                          container: {
                            spacing: 1,
                            direction: "row",
                          },
                        }}
                        previewChipProps={{
                          classes: { root: classes.previewChip },
                        }}
                        previewText="Selected files"
                      />
                    </ThemeProvider>
                  </Grid>
                )}
                {res.success && (
                  <Grid item xs={12}>
                    <Paper
                      className={classes.paper}
                      style={{ backgroundColor: "#dcedc8" }}
                    >
                      {" "}
                      <CheckCircleIcon className={classes.successIcon} />
                      <Typography variant="h5">
                        File Uploaded Successfully!
                      </Typography>
                      <Typography>
                        Congratulations! You have successfully uploaded a new
                        file
                      </Typography>
                    </Paper>
                  </Grid>
                )}

                {res.error && !res.loading && (
                  <Grid item xs={12}>
                    <Paper
                      className={classes.paper}
                      style={{ backgroundColor: "#ffebee" }}
                    >
                      {" "}
                      <SentimentVeryDissatisfiedIcon
                        className={classes.unsuccessIcon}
                      />
                      <Typography variant="h5">System Error!</Typography>
                      <Typography>
                        File Upload failed! System is unable to upload this file
                        on database. Please contact IT support to report this
                        problem.
                      </Typography>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDoc} color="primary">
                Cancel
              </Button>
              {!res.request && !res.loading && (
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  style={{ color: "white" }}
                  onClick={uploadDocumentClick}
                >
                  Save
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </>
      )}
    </Paper>
  );
};

export default ProjectView;
