import React from "react";
import PageLayout from "../components/PageLayout";
import ResourceView from "../components/Resources/ResourceView";
import { isLoggedIn } from "../helpers/authenticationHelp";
const Resources = ({ Data }) => {
  return (
    <PageLayout Title={Data.name} Icon={Data.icon}>
      <ResourceView admin={isLoggedIn().user.roles.includes("Admin")} />
    </PageLayout>
  );
};

export default Resources;