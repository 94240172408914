import React, { useState, useEffect } from "react";
//---
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import PageLayout from "../components/PageLayout";
import MuiTableCell from "@material-ui/core/TableCell";

import Grid from "@material-ui/core/Grid";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import MuiAlert from "@material-ui/lab/Alert";
import TablePagination from "@material-ui/core/TablePagination/TablePagination";
//----
import { getSignupData } from "../apiCalls/settingCalls";
import {
  WAIT,
  ERROR,
  SUCCESS,
  REJECTED,
  PENDING,
  APPROVED,
} from "../variables/enums";
import SearchProfessor from "../components/SearchProfessor";
import { getAllProjects, updateProjectStatus } from "../apiCalls/projectCalls";
import Taskboard from "../components/TaskBoard/Taskboard.js";
import RowUGPCAdmin from "../components/RowUGPCAdmin";
import RowGRCAdmin from "../components/RowGRCAdmin"

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    overflow: "hidden",
  },
  bold: {
    fontWeight: "bold",
  },
});

const TableCell = withStyles({
  root: {
    //borderBottom: "none",
    padding: ".8rem",
  },
})(MuiTableCell);

const styles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "green",
  },
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  "@keyframes delayedHide": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },

  autoHide: {
    animationName: "delayedHide",
    animationDuration: "300ms",
    animationDelay: (props) => props.delay || "2s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  table: {
    minWidth: 650,
  },
  formControl: {
    width: "100%",
  },
}));

export default function Projects({ Data }) {
  const classes = styles();
  const [degree, setDegree] = useState("All");
  const [batch, setBatch] = useState("All");
  const [stage, setStage] = useState("All");

  const [defaultData, setDefaultData] = useState({
    degrees: [],
    batches: [],
    isLoaded: WAIT,
    msg: "",
  });
  const [projects, setProjects] = useState({ list: [], status: WAIT, msg: "" });
  const pages = [5, 10, 15, 20];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [totalRecord, setTotalRecords] = useState();
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (defaultData.degrees.length === 0 || defaultData.batches.length === 0) {
      getSignupData().then((data) => {
        if (data) {
          if (data.error) {
            setDefaultData({ isLoaded: ERROR, msg: data.error });
          } else {
            setDefaultData({
              degrees: data.degrees,
              batches: data.batches,
              isLoaded: SUCCESS,
            });
          }
        } else {
          setDefaultData({ isLoaded: ERROR, msg: "Unable to connect" });
        }
      });
    }
    getAllProjects(degree, batch, stage, page, rowsPerPage).then((data) => {
      if (data) {
        if (data.error) {
          setProjects({ status: ERROR, msg: data.error });
        } else
          setProjects({
            list: data.projects,
            status: SUCCESS,
            msg: data.message,
          });
        setTotalRecords(data.totalProjects);
      } else {
        setProjects({ status: ERROR, msg: "Unable to connect!" });
      }
    });
  }, [page, rowsPerPage, stage, degree, batch]);
  return (
    <PageLayout Title={Data.name} Icon={Data.icon}>
      <Grid container spacing={2} style={{ margin: "1rem 0", padding: "0rem" }}>
        <Grid item xs={4} sm={4}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
          >
            <InputLabel id="degree-input">Degree</InputLabel>
            <Select
              required
              labelId="degree"
              id="degreeId"
              name="degree"
              value={degree}
              onChange={(e) => {
                setDegree(e.target.value);
              }}
              label="degree"
            >
              <MenuItem value="All">
                <em>All</em>
              </MenuItem>
              {defaultData.degrees.map((degree, key) => (
                <MenuItem key={key} value={degree}>
                  {degree}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={4}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
          >
            <InputLabel id="batch">Batch</InputLabel>
            <Select
              required
              labelId="batch"
              id="batchId"
              value={batch}
              onChange={(e) => {
                setBatch(e.target.value);
              }}
              label="Batch"
            >
              <MenuItem value="All">
                <em>All</em>
              </MenuItem>
              {defaultData.batches.map((batch, key) => (
                <MenuItem key={key} value={batch}>
                  {batch}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={4}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
          >
            <InputLabel id="batch">Stage</InputLabel>
            <Select
              required
              labelId="stage"
              id="stageId"
              value={stage}
              onChange={(e) => {
                setStage(e.target.value);
              }}
              label="Stage"
            >
              <MenuItem value="All">
                <em>All</em>
              </MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Ready for Defence">Ready for Defence</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>

            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div styles={{ width: "100%", height: "100%", padding: "0px" }}>
        {projects.status === WAIT ? (
          <Box className={classes.root} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : projects.status === ERROR ? (
          <div className={classes.root} style={{display:"flex",justifyContent:"center"}}>
            <Alert style={{fontWeight:"bold"}} severity="warning">{projects.msg}</Alert>
          </div>
        ) : projects.status === SUCCESS && projects.list.length === 0 ? (
          <div className={classes.root}>
            <Alert severity="success">0 Project(s) Found!</Alert>
          </div>
        ) : (
          <CardContent style={{ padding: "0px", margin: "0px" }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  {projects.list.map((project, key) => {
                    if(project.degreeLevel==="UGRD")
                    {
                    return <RowUGPCAdmin key={key} project={project} />
                  }else{
                    return <RowGRCAdmin key={key} project={project}/>
                  }
                })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        )}
      </div>
      {projects.status === SUCCESS && (
        <TablePagination
          component="div"
          page={page}
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          count={totalRecord}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </PageLayout>
  );
}
