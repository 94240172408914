import React, { useState, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import { fetchMeetings } from "../../apiCalls/meetingCalls.js";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import MeetingList from "./MeetingList";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "30rem",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "30rem",
    padding: theme.spacing(8),
  },
}));
function sortMeeting(meetingList) {
  meetingList.sort(function compareFn(firstEl, secondEl) {
    const f = new Date(firstEl.date);
    const s = new Date(secondEl.date);
    if (f.getTime() < s.getTime()) return -1;
    if (f.getTime() > s.getTime()) return 1;

    return 0;
  });
}
const MeetingView = ({ admin }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [refreshMeeting, setRefreshMeeting] = useState(false);
  const classes = useStyles();
  const handleOpen = () => {
    setOpen(true);
  };

  const upcommingMeetings = useRef([]);
  const previousMeetings = useRef([]);
  const todayMeetings = useRef([]);

  useEffect(() => {
    setLoading(true);
    fetchMeetings().then((data) => {
      if (data) {
        if (data.error) {
          setLoading(false);
          setSuccess(false);
        } else {
          if (data.meetings) {
            previousMeetings.current = [];
            upcommingMeetings.current = [];
            todayMeetings.current = [];
            data.meetings.map((meeting) => {
              if (meeting != null) {
                let expire = new Date(meeting.date);
                let currentDate = Date.now();

                if (expire.getTime() < currentDate) {
                  previousMeetings.current.push(meeting);
                } else {
                  const h = expire.getHours(expire);
                  if (expire.setHours(h - 24) < currentDate) {
                    todayMeetings.current.push(meeting);
                  } else {
                    upcommingMeetings.current.push(meeting);
                  }
                }
              }
            });
            sortMeeting(previousMeetings.current);
            sortMeeting(upcommingMeetings.current);
            sortMeeting(todayMeetings.current);
            setLoading(false);
            setSuccess(true);
          } else {
            setLoading(false);
            setSuccess(false);
          }
        }
      } else {
        setLoading(false);
        setSuccess(false);
      }
    });
  }, [refreshMeeting]);
  return (
    <div>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress size={130} thickness={5} />
        </div>
      )}

      {!loading && !success && (
        <Alert severity="error" variant="filled">
          System is unable to fetch meetings, please try again later!
        </Alert>
      )}

      {!loading && success && (
        <Paper className={classes.root} elevation={3}>
          <Box pt={2} textAlign="center" fontWeight="fontWeightBold">
            Recent Meetings
          </Box>
          {todayMeetings.current.length > 0 ? (
            <MeetingList
              admin={admin}
              list={todayMeetings.current}
              refreshMeeting={refreshMeeting}
              setRefreshMeeting={setRefreshMeeting}
            />
          ) : (
            <Box pt={2} textAlign="center">
              You don't have any meeting in the next 24 hours.
            </Box>
          )}

          <Box pt={2} textAlign="center" fontWeight="fontWeightBold">
            Upcomming Meetings
          </Box>
          {upcommingMeetings.current.length > 0 ? (
            <MeetingList
              admin={admin}
              list={upcommingMeetings.current}
              refreshMeeting={refreshMeeting}
              setRefreshMeeting={setRefreshMeeting}
            />
          ) : (
            <Box pt={2} textAlign="center">
              You don't have any upcomming meetings.
            </Box>
          )}

          {previousMeetings.current.length > 0 && (
            <>
              <Box pt={2} textAlign="center" fontWeight="fontWeightBold">
                Previous Meetings
              </Box>
              <MeetingList
                admin={admin}
                list={previousMeetings.current}
                refreshMeeting={refreshMeeting}
                setRefreshMeeting={setRefreshMeeting}
              />
            </>
          )}
        </Paper>
      )}
    </div>
  );
};
export default MeetingView;
