const { isLoggedIn } = require("../helpers/authenticationHelp");
const { serverURL } = require("./helper");

export const getSettings = () => {
  return fetch(`${serverURL}/systemsettings`, {
    method: "GET",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const addNewBatch = (batch) => {
  isLoggedIn();
  return fetch(`${serverURL}/systemsettings/newbatch`, {
    method: "POST",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
    body: JSON.stringify(batch),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const addNewDepartment = (batch) => {
  isLoggedIn();
  return fetch(`${serverURL}/systemsettings/department`, {
    method: "POST",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
    body: JSON.stringify(batch),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const addNewProgram = (degree) => {
  isLoggedIn();
  return fetch(`${serverURL}/systemsettings/newprogram`, {
    method: "POST",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
    body: JSON.stringify(degree),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateProgram = (degree) => {
  isLoggedIn();
  return fetch(`${serverURL}/systemsettings/program`, {
    method: "PUT",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
    body: JSON.stringify(degree),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateDepartment = (chairman) => {
  isLoggedIn();
  return fetch(`${serverURL}/systemsettings/department`, {
    method: "PUT",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

      
    },
    body: JSON.stringify(chairman),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getSignupData = () => {
  return fetch(`${serverURL}/systemsettings/signup`, {
    method: "GET",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
