import React, { useEffect, useState } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import { TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import PageLayout from "../components/PageLayout";
import { Box, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiTableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import NewBatchForm from "../components/Settings/NewBatchForm";
import NewDegreeForm from "../components/Settings/NewDegreeForm";
import NewChairmanForm from "../components/Settings/UpdateDepartmentForm";
import NewDepartmentForm from "../components/Settings/NewDepartmentForm";
import FormDialog from "../components/FormDialog";
import UpdateDegreeForm from "../components/Settings/UpdateDegreeForm"
import UpdateDepartmentFrom from "../components/Settings/UpdateDepartmentForm"
import { getSettings } from "../apiCalls/settingCalls";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const TableCell = withStyles({
  root: {
    //borderBottom: "none",
    padding: ".8rem",
  },
})(MuiTableCell);
const TableCellWithoutBorder = withStyles({
  root: {
    borderBottom: "none",
    padding: ".8rem",
  },
})(MuiTableCell);
const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    minWidth: 650,
  },
  cardActions: {
    display: "flex",
    padding: "8px",
    alignItems: "center",
    justifyContent: "space-around",
  },
});

function Row(props) {
  const { data, name, subTitle } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {name}
        </TableCell>
        <TableCell>
          {data.length} {subTitle}
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                All {name}
              </Typography>

              <Table size="small" aria-label="purchases">
                {name === "Programs Offered" && (
                  <TableHead>
                    <TableRow>
                      <TableCell>Program Name</TableCell>
                      <TableCell align="left">Program Level</TableCell>
                      <TableCell align="left">Program Department</TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {data.map((value, key) =>
                    name === "Batches" ? (
                      <TableCell key={key}>{value}</TableCell>
                    ) : (
                      <TableRow>
                        <TableCell>{value.name}</TableCell>
                        <TableCell>
                          {value.level === "PG"
                            ? "Post Graduate"
                            : value.level === "GRAD"
                            ? "Master"
                            : "Graduate"}
                        </TableCell>
                        <TableCell>{value.department}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const SystemSetting = ({ Data }) => {
  const classes = useStyles();
  const [openDetail, setOpenDetail] = React.useState(false);
  const [setting, setSetting] = useState({ data: {}, dataLoaded: false });
  const [error, setError] = useState("");

  useEffect(() => {
    getSettings().then((data) => {
      if (data) {
        if (data.error) {
          setError(data.error);
        } else {
          setSetting({ data: data, dataLoaded: true });
        }
      } else {
        setError("Unable to retrieve settings");
      }
    });
  }, []);
  return (
    <PageLayout Title={Data.name} Icon={Data.icon}>
      <Card className={classes.root} variant="outlined">
        {error !== "" ? (
          <Box className={classes.root} m={5}>
            <Alert severity="error">{error}</Alert>
          </Box>
        ) : !setting.dataLoaded ? (
          <Box className={classes.container} m={5}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <CardContent>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow className={classes.root}>
                      <TableCell component="th" scope="row">
                        Departments
                      </TableCell>
                      <TableCell>{`${setting.data.Departments.length} Departments`}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpenDetail(!openDetail)}
                        >
                          {openDetail ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse in={openDetail} timeout="auto" unmountOnExit>
                          {setting.data.Departments.map((department) => (
                            <Box margin={1}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                              >
                                {department.departmentFullName}
                              </Typography>
                              <Table size="small" aria-label="purchases">
                                <TableBody>
                                  <TableRow>
                                    <TableCell>Chairman</TableCell>
                                    <TableCell>
                                      {department.chairmanName}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Abreviation</TableCell>
                                    <TableCell>
                                      {department.departmentName}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow component="th" scope="row">
                                    <TableCell>Date Appointed</TableCell>
                                    <TableCell>
                                      {" "}
                                      {new Date(
                                        department.chairmanAppointed
                                      ).toDateString()}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Box>
                          ))}
                        </Collapse>
                      </TableCell>
                    </TableRow>

                    <Row
                      data={setting.data.DegreeDetail}
                      name={"Programs Offered"}
                      subTitle={"Program"}
                    />

                    <Row
                      data={setting.data.Batches}
                      name={"Batches"}
                      subTitle={"Batches"}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <FormDialog
                Form={<NewBatchForm setting={setting} />}
                Title={"Add New Batch"}
                Heading={"Batch Form"}
              />
              <FormDialog
                Form={<NewDegreeForm setting={setting} />}
                Title={"Add New Degree"}
                Heading={"Program Form"}
              />
              <FormDialog
                Form={<NewDepartmentForm setting={setting} />}
                Title={"Add New Department"}
                Heading={"Add Department"}
              />
              <FormDialog
                Form={<UpdateDegreeForm setting={setting} />}
                Title={"Update Degree"}
                Heading={"Program Form"}
              />
              <FormDialog
                Form={<UpdateDepartmentFrom setting={setting} />}
                Title={"Update Department"}
                Heading={"Department Form"}
              />
            </CardActions>
          </>
        )}
      </Card>
    </PageLayout>
  );
};

export default SystemSetting;
