import React, { useEffect, useState } from "react";
import { CircularProgress, } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { isLoggedIn } from "../helpers/authenticationHelp";
import PageLayout from "../components/PageLayout";
import { isProject } from "../apiCalls/projectCalls.js";
import Taskboard from "../components/TaskBoard/Taskboard.js";
import Alert from "@material-ui/lab/Alert";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const myStyles = makeStyles((theme) =>
    createStyles({
        loading: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "30rem",
            padding: theme.spacing(8),
        },
    })
);
const TaskBoard = ({ Data }) => {
    const [projectExist, setProjectExist] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refresh, setRefresh] = useState(false)
    const classes = myStyles();

    useEffect(() => {
            if (!projectExist) {
            isProject().then((data) => {
                if (data) {
                    if (data.error) {
                        setLoading(false);
                        setError("You don't have any project at the moment, please create a project to use this feature");
                    } else if (data.message === "Project Exists") {
                        setProjectExist(data.projectId);
                    } else {
                        setError("System is unable to find project.");
                        setLoading(false);
                    }
                } else {

                    setLoading(false);
                    setError("Server is unable to respond! Please try again later.");
                }
            });
        }
        else {
            setLoading(true)
        }
    }, [projectExist, refresh]);

    return (
      <PageLayout Title={Data.name} Icon={Data.icon}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12}>
              {projectExist && (
                <Taskboard
                  projectId={projectExist}
                  admin={false}
                  setLoading={setLoading}
                  loading={loading}
                  refreshTaskboard={refresh}
                  setRefreshTaskboard={setRefresh}
                />
              )}
              {loading && (
                <div className={classes.loading}>
                  <CircularProgress size={130} thickness={5} />
                </div>
              )}
              {!loading && !projectExist && error && (
                <Alert style={{ fontWeight: "bold" }} severity="warning">
                  {error}
                </Alert>
              )}
            </Grid>
          </Grid>
  
      </PageLayout>
    );
};

export default TaskBoard;