import React, { useState } from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { updateProgram } from "../../apiCalls/settingCalls";

const styles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "green",
  },
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    width: "17rem",
  },
  submit: {
    color: "white",
    margin: theme.spacing(3, 0, 2),
  },
}));
const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});
const NewDegreeForm = ({ setting }) => {
  const classes = styles();
  const [degree, setDegree]= useState("")
  const [degreeLevel, setDegreeLevel] = useState("");
  const [department, setDepartment] = useState("");

  const [result, setResult] = useState({ message: "", error: "" });
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    var letters = /^[A-Za-z]+$/;
    // now the submission step will start
    if (degree === "" || !degree.match(letters)) {
      setResult({ error: "Enter Valid Degree" });
    } else if (degreeLevel === "") {
      setResult({ error: "Please choose a Degree Level" });
    } else if (department===""){
      setResult({ error: "Please choose a Department" });

    }else {
      setResult({ error: "", message: "" });
      setLoading(true);
      const degreeDetail = { degree, degreeLevel , department};
      updateProgram(degreeDetail).then((data) => {
        if (data) {
          if (data.error) {
            setResult({ error: data.error });
          } else {    
            setResult({ message: data.message });
          }
          setLoading(false);
        } else {
          setResult({ error: "Unable to Update" });
          setLoading(false);
        }
      });
    }
  };

  return (
    <div>
      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
             <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Degree
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={degree}
                onChange={(e) => setDegree(e.target.value)}
                label="Degree"
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {setting &&
                  setting.data.Departments &&
                  setting.data.DegreeDetail.map((degree) => (
                    <MenuItem value={degree.name}>{degree.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Degree Level
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={degreeLevel}
                onChange={(e) => setDegreeLevel(e.target.value)}
                label="Degree Level"
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"UGRD"}>Undergraduate</MenuItem>
                <MenuItem value={"GRAD"}>Graduate</MenuItem>
                <MenuItem value={"PG"}>Post Graduate</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Department
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                label="Department"
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {setting &&
                  setting.data.Departments &&
                  setting.data.Departments.map((department) => (
                    <MenuItem value={department.departmentName}>{department.departmentName}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {loading ? (
          <Box className={classes.root} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          ""
        )}
        {result.error ? (
          <div className={classes.root}>
            <Alert severity="error">{result.error}</Alert>
          </div>
        ) : (
          ""
        )}
        {result.message ? (
          <div className={classes.root}>
            <Alert severity="success">{result.message}</Alert>
          </div>
        ) : (
          ""
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Update Degree Program
        </Button>
      </form>
    </div>
  );
};

export default NewDegreeForm;
