import React from 'react';

import PageLayout from "../components/PageLayout"
import MemberCommittees from '../components/MemberCommittees';
const CommitteeProjects = ({ Data }) => {
    return (
        <PageLayout Title={Data.name} Icon={Data.icon}>
            <MemberCommittees />
        </PageLayout >
    );
};

export default CommitteeProjects;