import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { DropzoneArea } from "material-ui-dropzone";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SearchProfessor from "./SearchProfessor";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import ReactPDF from "@react-pdf/renderer";
import { Grid } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { CircularProgress } from "@material-ui/core";
import {
  createProjectGRCAPI,
  deleteProject,
  uploadDocument,
} from "../apiCalls/projectCalls";
import { projectValidationGRC } from "../helpers/documentationHelp";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
      minHeight: "25rem",
      width: "30rem",
    },
    success: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
      textAlign: "center",
      backgroundColor: "#dcedc8",
    },
    unsuccess: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
      textAlign: "center",
      backgroundColor: "#ffebee",
    },
    loading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      padding: theme.spacing(8),
      width: "25rem",
    },
    successIcon: {
      color: "#7cb342",
      fontSize: "8rem",
    },
    unsuccessIcon: {
      color: "#c62828",
      fontSize: "8rem",
    },
    saveIcon: {
      color: "#ff6f00",
      fontSize: "8rem",
    },

    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);
const theme = createMuiTheme({
  palette: {
    primary: green,
  },
  overrides: {
    // Style sheet name ⚛️

    MuiDropzoneArea: {
      root: {
        backgroundColor: "#f9fbe7",
      },
    },
  },
});

const CreateProjectGRC = ({ appear, setProjectExist }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState("");
  const [abstract, setAbstract] = useState("");
  const [supervisor, setSupervisor] = useState([]);
  const [res, setResponse] = useState({
    loading: false,
    request: false,
    success: false,
    response: false,
  });
  const [document, setDocument] = useState([]);
  const [error, setError] = useState({ in: "", message: "" });
  const formData = new FormData();

  const handleClose = () => {
    appear(false);
    setOpen(false);
    if (res.success) {
      setProjectExist("exist");
    }
  };

  const saveProject = async (e) => {
    e.preventDefault();
    const Error = projectValidationGRC(title, abstract, supervisor, document);
    if (Error != "") {
      setError({ in: Error.errorIn, message: Error.error });
    } else {
      setResponse({
        loading: true,
        request: true,
        success: false,
        response: false,
      });
      formData.set("file", document[0]);
      const data = { title, abstract, supervisor };
      createProjectGRCAPI(data).then((projectData) => {
        if (projectData && !projectData.error) {
          uploadDocument("visionDocuments", formData, projectData._id).then(
            (data) => {
              if (data || !data.error) {
                setResponse({
                  loading: false,
                  request: true,
                  success: true,
                  response: true,
                });
              } else {
                deleteProject(projectData._id);
                setResponse({
                  loading: false,
                  request: true,
                  success: false,
                  response: true,
                });
              }
            }
          );
        } else {
          setResponse({
            loading: false,
            request: true,
            success: false,
            response: true,
          });
        }
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Dialog open={open} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
            CREATE NEW PROJECT
          </DialogTitle>

          <DialogContent className={classes.root}>
           
            <Grid container spacing={3}>
              {!res.request && (
                <>
                  {" "}
                  <Grid item xs={12}>
                    <TextField
                      name="title"
                      variant="outlined"
                      required
                      fullWidth
                      id="title"
                      label="Title"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        setError({ in: "", message: "" });
                      }}
                      error={error.in === "title"}
                      helperText={
                        error.in === "title"
                          ? error.message
                          : `${title.length}/100`
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="abstract"
                      label="Abstract"
                      name="abstract"
                      value={abstract}
                      onChange={(e) => {
                        setAbstract(e.target.value);
                        error.in = "";
                      }}
                      error={error.in === "abstract"}
                      helperText={
                        error.in === "abstract"
                          ? error.message
                          : `${abstract.length}/1000`
                      }
                      autoComplete="abstract"
                      rows={4}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {error.in === "supervisor" && (
                      <Alert severity="error">{error.message}</Alert>
                    )}
                    <SearchProfessor
                      groupMember={supervisor}
                      setGroupMember={setSupervisor}
                      label={"Select Supervisor"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {error.in === "file" && (
                      <Alert severity="error">{error.message}</Alert>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <DropzoneArea
                      initialFiles={document}
                      onChange={(file) => {
                        setDocument(file);
                        setError({ in: "", message: "" });
                      }}
                      onDelete={() => {
                        setDocument([]);
                      }}
                      acceptedFiles={["application/pdf", ".doc", ".docx"]}
                      filesLimit={1}
                      maxFileSize={20000000}
                      dropzoneText={
                        <p>
                          Upload Vision Document
                          <br />
                          (doc, docx, pdf)
                        </p>
                      }
                      showPreviews={true}
                      showFileNamesInPreview={true}
                      showPreviewsInDropzone={false}
                      useChipsForPreview
                      previewGridProps={{
                        container: {
                          spacing: 1,
                          direction: "row",
                        },
                      }}
                      previewChipProps={{
                        classes: { root: classes.previewChip },
                      }}
                      previewText="Selected files"
                    />
                  </Grid>
                </>
              )}
              {res.loading && (
                <Grid item xs={12}>
                  <Paper className={classes.loading} elevation={0}>
                    <CircularProgress size={130} thickness={5} />
                  </Paper>
                </Grid>
              )}

              {res.request && res.response && (
                <Grid item xs={12}>
                  <Paper
                    elevation={3}
                    className={
                      res.success ? classes.success : classes.unsuccess
                    }
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        {res.success ? (
                          <CheckCircleIcon className={classes.successIcon} />
                        ) : (
                          <SentimentVeryDissatisfiedIcon
                            className={classes.unsuccessIcon}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h5">
                          {res.success
                            ? "Project Saved Successfully!"
                            : "System Error!"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {res.success
                            ? "Congratulations! You have successfully submitted your proposal for Approval."
                            : "Project creation failed! System is unable to create this project on database. Please contact IT support to report this problem."}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            {!res.request && (
              <Button
                color="primary"
                type="submit"
                variant="contained"
                className={classes.submit}
                onClick={saveProject}
              >
                Save
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};
export default CreateProjectGRC;