import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Column from "./Column.js";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CreateTask from "./CreateTask";
import { makeStyles } from "@material-ui/core";
import { isLoggedIn } from "../../helpers/authenticationHelp.js";
import { fetchTasks } from "../../apiCalls/taskCalls.js";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
    button: {
        color: "white",
        fontWeight: "500",
        fontSize: "1rem",
        letterSpacing: "0.12rem",
    },
}));

const Taskboard = ({
    projectId,
    admin,
    setLoading,
    loading,
    refreshTaskboard,
    setRefreshTaskboard,
}) => {
    const [open, setOpen] = useState(false);
    // const [loading,setLoading]=useState(true);
    const [success, setSuccess] = useState(false);
    const classes = useStyles();
    const handleOpen = () => {
        setOpen(true);
    };

    const pendingTask = useRef([]);
    const delayedTask = useRef([]);
    const completedTask = useRef([]);
    useEffect(() => {
        fetchTasks(projectId).then((data) => {
            if (data) {
                if (data.error) {
                    setLoading(false);
                    setSuccess(false);
                } else {
                    if (data.tasks) {
                        pendingTask.current = [];
                        completedTask.current = [];
                        delayedTask.current = [];
                        for (let i = 0; i < data.tasks.length; i++) {
                            if (data.tasks[i].status === "pending")
                                pendingTask.current.push(data.tasks[i]);
                            else if (data.tasks[i].status === "delayed")
                                delayedTask.current.push(data.tasks[i]);
                            else if (data.tasks[i].status === "completed")
                                completedTask.current.push(data.tasks[i]);
                        }
                        pendingTask.current.reverse();
                        completedTask.current.reverse();
                        delayedTask.current.reverse();
                        setLoading(false);
                        setSuccess(true);
                    }
                }
            } else {
                setLoading(false);
                setSuccess(false);
            }
        });
    }, [refreshTaskboard]);
    return (
        <div >
            {admin && !loading && success && (
                <Box ml={1} mr={1} display="flex">
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.button}
                        onClick={handleOpen}
                    >
                        + Create Task
                    </Button>
                </Box>
            )}
            {!loading && !success && (
                <Alert severity="error" variant="filled">
                    System is unable to fetch tasks, please try again later!
                </Alert>
            )}

            {!loading && success && (
                <div style={{ display: "flex", overflow: "auto" }}>
                    <Column
                        id="pending"
                        tasks={pendingTask.current}
                        admin={admin}
                        projectId={projectId}
                        refreshTaskboard={refreshTaskboard}
                        setRefreshTaskboard={setRefreshTaskboard}
                    />
                    <Column
                        id="completed"
                        tasks={completedTask.current}
                        admin={admin}
                        projectId={projectId}
                        refreshTaskboard={refreshTaskboard}
                        setRefreshTaskboard={setRefreshTaskboard}
                    />
                    <Column
                        id="delayed"
                        tasks={delayedTask.current}
                        admin={admin}
                        projectId={projectId}
                        refreshTaskboard={refreshTaskboard}
                        setRefreshTaskboard={setRefreshTaskboard}
                    />
                </div>
            )}

            {open && (
                <CreateTask
                    open={open}
                    setOpen={setOpen}
                    projectId={projectId}
                    refreshTaskboard={refreshTaskboard}
                    setRefreshTaskboard={setRefreshTaskboard}
                />
            )}
        </div>
    );
};
export default Taskboard;
