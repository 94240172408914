import React, { useEffect, useState } from "react";

//---
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  withStyles,
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputBase from "@material-ui/core/InputBase";
import FormGroup from "@material-ui/core/FormGroup";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import NativeSelect from "@material-ui/core/NativeSelect";
import Card from "@material-ui/core/Card";
import TableRow from "@material-ui/core/TableRow";
import MuiTableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
//---
import { validationAdmin } from "../helpers/userHelp";
import { editStudent } from "../apiCalls/userCalls";
import { isLoggedIn } from "../helpers/authenticationHelp";
import {
  WAIT,
  ERROR,
  SUCCESS,
  REJECTED,
  PENDING,
  APPROVED,
} from "../variables/enums";
import SearchProfessor from "./SearchProfessor";

import { viewProject, updateProjectStatus } from "../apiCalls/projectCalls";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: ".3rem",
  },
})(MuiTableCell);
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
const GreenButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);

const styles = makeStyles((theme) => ({
  paper: {
    //marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "green",
  },
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  formControl: {
    width: "100%  ",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

const EditStudentForm = ({ user }) => {
  const classes = styles();
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [department, setDepartment] = useState("");
  const [status, setStatus] = useState("");
  const [batch, setBatch] = useState("");
  const [isEligible, setEligible] = useState("");
  const [regNo, setRegNo] = useState("");
  const [editable, setEditable] = useState(true);
  const [errorIn, setErrorIn] = useState("");
  const [addUserError, setAddUserError] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [supervisor, setSupervisor] = useState({});
  const [requestStatus, setRequestStatus] = useState({ msg: "", status: "" });

  const [project, setProject] = useState({
    data: {},
    loaded: WAIT,
    message: "",
  });

  useEffect(() => {
    setEmail(user.email);
    setFullName(user.name);
    setStatus(user.status);
    setDepartment(user.department);
    setRegNo(user.student_details.regNo);
    setBatch(user.student_details.batch);
    setEligible(user.student_details.isEligible);
    viewProject(user._id).then((data) => {
      if (data) {
        if (data.error) {
          if (data.error) setProject({ loaded: ERROR, message: data.error });
        } else {
          setProject({ data: data, loaded: SUCCESS });
      
        }
      } else {
        setProject({
          loaded: ERROR,
          message: "Unable to connect to server",
        });
      }
    });
  }, [user]);
  const changeProjectStatus = (projectId, status) => {
    const projectStatus = { status, supervisor };
    setRequestStatus({ status: WAIT, msg: "" });
    updateProjectStatus(projectId, projectStatus).then((data) => {
      if (data) {
        project.data.projectStatus = data.status;
        project.data.supervisor.name = supervisor.name;
        setRequestStatus({ status: SUCCESS, msg: data.message });
      } else {
        setRequestStatus({ status: ERROR, msg: "Unable to connect!" });
      }
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setAddUserError("");
    setSuccessMessage("");

    const Error = validationAdmin(fullName, email);
    setErrorIn(Error.errorIn);
    setErrorMessage(Error.error);
    const token = isLoggedIn().token;
    const editedUser = {
      fullName,
      email,
      regNo,
      department,
      batch,
      status,
      isEligible,
    };
    //now is the time we will be submitting users.
    setLoading(true);
    editStudent(user._id, token, editedUser).then((data) => {
      if (data) {
        if (data.error) {
          setAddUserError(data.error);
          setLoading(false);
        } else {
          setSuccessMessage(data.message);
          setLoading(false);
        }
      } else {
        setAddUserError("Failed to connect to server");
        setLoading(false);
      }
    });
  };
  const makeEditable = (e) => {
    e.preventDefault();
    setEditable(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <ThemeProvider theme={theme}>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="fname"
                  name="fullName"
                  variant="outlined"
                  required
                  fullWidth
                  id="fullName"
                  label="Name"
                  disabled={editable}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  error={errorIn === "fullName"}
                  helperText={errorIn === "fullName" ? errorMessage : ""}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={email}
                  disabled={editable}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrorIn("");
                    setSuccessMessage("");
                  }}
                  error={errorIn === "email"}
                  helperText={errorIn === "email" ? errorMessage : ""}
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="regNo"
                  label="Registration No"
                  name="regNo"
                  type="number"
                  value={regNo}
                  disabled={editable}
                  onChange={(e) => {
                    setRegNo(e.target.value);
                    setErrorIn("");
                    setSuccessMessage("");
                  }}
                  error={errorIn === "regNo"}
                  helperText={errorIn === "regNo" ? errorMessage : ""}
                  autoComplete="regNo"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="department"
                  label="Degree"
                  name="degree"
                  value={department}
                  disabled={editable}
                  onChange={(e) => {
                    setDepartment(e.target.value);
                    setErrorIn("");
                    setSuccessMessage("");
                  }}
                  error={errorIn === "department"}
                  helperText={errorIn === "department" ? errorMessage : ""}
                  autoComplete="department"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="batch"
                  label="Batch"
                  name="btach"
                  value={batch}
                  disabled={editable}
                  onChange={(e) => {
                    setBatch(e.target.value);
                    setErrorIn("");
                    setSuccessMessage("");
                  }}
                  error={errorIn === "btach"}
                  helperText={errorIn === "batch" ? errorMessage : ""}
                  autoComplete="batch"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  required
                  component="fieldset"
                  className={classes.formControl}
                  disabled={editable}
                >
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"Active"}>Active</MenuItem>
                    <MenuItem value={"Pending"}>Pending</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  required
                  component="fieldset"
                  className={classes.formControl}
                  disabled={editable}
                >
                  <InputLabel>Eligibility</InputLabel>
                  <Select
                    value={isEligible}
                    onChange={(e) => {
                      setEligible(e.target.value);
                    }}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={true}>Eligible</MenuItem>
                    <MenuItem value={false}>Uneligible</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {/* project details start here */}

            {loading ? (
              <Box
                className={classes.root}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              ""
            )}
            {addUserError ? (
              <div className={classes.root}>
                <Alert severity="error">{addUserError}</Alert>
              </div>
            ) : (
              ""
            )}
            {successMessage ? (
              <div className={classes.root}>
                <Alert severity="success">{successMessage}</Alert>
              </div>
            ) : (
              ""
            )}
            {editable ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={makeEditable}
                color="gray"
                className={classes.submit}
              >
                Enable Editing
              </Button>
            ) : (
              <GreenButton
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                color="primary"
                className={classes.submit}
              >
                Update User
              </GreenButton>
            )}
          </form>
        </ThemeProvider>
      </div>
    </Container>
  );
};
export default EditStudentForm;
