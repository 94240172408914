import React,{useEffect} from "react";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Resource from "./Resource";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
}));

const ResourceList = ({
  admin,
  list,
  refreshResources,
  setRefreshResources,
}) => {
  const classes = useStyles();
  return (
    <Grid container style={{ textAlign: "center" }} className={classes.root}>
      {list &&
        list.length > 0 &&
        list.map((resource, index) => (
          <Resource
            resource={resource}
            index={index}
            admin={admin}
            refreshResources={refreshResources}
            setRefreshResources={setRefreshResources}
          />
        ))}
    </Grid>
  );
};
export default ResourceList;
