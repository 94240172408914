import React, { useEffect, useState } from "react";

//---
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import MuiTableCell from "@material-ui/core/TableCell";
import {
  withStyles,
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import { green } from "@material-ui/core/colors";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";

//---
import { WAIT, ERROR, SUCCESS } from "../variables/enums";
import { validationAdmin } from "../helpers/userHelp";
import { editAdmin } from "../apiCalls/userCalls";
import { isLoggedIn } from "../helpers/authenticationHelp";
import {
  projectsByProfessor,
  updateProjectStatus,
} from "../apiCalls/projectCalls";
import SearchProfessor from "./SearchProfessor";

const GreenButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: ".3rem",
  },
})(MuiTableCell);
const styles = makeStyles((theme) => ({
  paper: {
    //marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "green",
  },
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  formControl: {
    width: "100%  ",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  change: {
    margin: theme.spacing(2, 2, 2, 2),
    width: "90%",
  },
}));
const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

const EditAdminForm = ({ user }) => {
  const classes = styles();
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [editable, setEditable] = useState(true);
  const [roles, setRoles] = useState({
    Professor: true,
    PO: false,
    Admin: false,
    EE: false,
  });
  const [projects, setProjects] = useState({
    list: [],
    loaded: WAIT,
    message: "",
  });
  const [errorIn, setErrorIn] = useState("");
  const [addUserError, setAddUserError] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [toUpdate, setToUpdate] = useState("");
  const [newSupervisor, setNewSupervisor] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [supervisorUpdated, setSupervisorUpdated] = useState({
    result: "",
    message: "",
  });
  useEffect(() => {
    projectsByProfessor(user._id).then((data) => {
      if (data) {
        if (data.projects) {
          setProjects({ list: data.projects, loaded: SUCCESS });
        }
        if (data.error) setProjects({ loaded: ERROR, message: data.error });
      } else {
        setProjects({
          loaded: ERROR,
          message: "Unable to connect to server",
        });
      }
    });
    setEmail(user.email);
    setFullName(user.name);
    if (user.roles) {
      setRoles({
        Professor: user.roles.includes("Professor"),
        PO: user.roles.includes("Program-Office"),
        Admin: user.roles.includes("Admin"),
        EE: user.roles.includes("External-Evaluator"),
      });
    }
  }, [user, refresh]);

  const handleChange = (event) => {
    setSuccessMessage("");
    setRoles({ ...roles, [event.target.name]: event.target.checked });
  };
  const { Professor, PO, Admin, EE } = roles;
  const error = [Professor, PO, Admin, EE].filter((v) => v).length === 0;

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setAddUserError("");
    const Error = validationAdmin(fullName, email);
    setErrorIn(Error.errorIn);
    setErrorMessage(Error.error);
    const token = isLoggedIn().token;
    const editedUser = { fullName, email, roles };
    //now is the time we will be submitting users.
    setLoading(true);
    editAdmin(user._id, token, editedUser).then((data) => {
      if (data) {
        if (data.error) {
          setAddUserError(data.error);
          setLoading(false);
        } else {
          setSuccessMessage(data.message);
          setLoading(false);
        }
      } else {
        setAddUserError("Failed to connect to server");
        setLoading(false);
      }
    });
  };
  const makeEditable = (e) => {
    e.preventDefault();
    setEditable(false);
  };

  const updateSupervisor = (e, projectId) => {
    e.preventDefault();
    if (newSupervisor == "") {
      setSupervisorUpdated({
        message: "Must Select A Supervisor",
        result: ERROR,
      });
    } else {
      const projectStatus = { status: "Approved", supervisor: newSupervisor };
      updateProjectStatus(projectId, projectStatus).then((data) => {
        if (data) {
          if (data.error) {
            setSupervisorUpdated({ status: ERROR, msg: data.error });
          } else {
            setSupervisorUpdated({ status: SUCCESS, msg: data.message });
            setRefresh(refresh + 1);
          }
        } else {
          setSupervisorUpdated({ status: ERROR, msg: "Unable to connect!" });
        }
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <ThemeProvider theme={theme}>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="fname"
                  name="fullName"
                  variant="outlined"
                  required
                  fullWidth
                  id="fullName"
                  label="Name"
                  disabled={editable}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  error={errorIn === "fullName"}
                  helperText={errorIn === "fullName" ? errorMessage : ""}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={email}
                  disabled={editable}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrorIn("");
                    setSuccessMessage("");
                  }}
                  error={errorIn === "email"}
                  helperText={errorIn === "email" ? errorMessage : ""}
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  required
                  error={error}
                  component="fieldset"
                  className={classes.formControl}
                  disabled={editable}
                >
                  <FormLabel component="legend">
                    Pick at-least one role
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Professor}
                          onChange={handleChange}
                          name="Professor"
                        />
                      }
                      label="Professor"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Admin}
                          onChange={handleChange}
                          name="Admin"
                        />
                      }
                      label="Admin"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={PO}
                          onChange={handleChange}
                          name="PO"
                        />
                      }
                      label="Program Office"
                    />
                  </FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={EE}
                        onChange={handleChange}
                        name="EE"
                      />
                    }
                    label="External Evaluator"
                  />
                </FormControl>
              </Grid>
            </Grid>
            {projects.loaded === SUCCESS ? (
              projects.list.map((project, index) => {
                return (
                  <Card
                    style={{ marginBottom: "1rem", padding: "1rem" }}
                    key={index}
                  >
                    <TableRow>
                      <TableCell>
                        <h4 style={{ margin: "0px" }}>{`Project no ${
                          index + 1
                        }`}</h4>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.root}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ minWidth: "8rem" }}
                      >
                        Project Title
                      </TableCell>
                      <TableCell>
                        {project.documentation.visionDocument.title}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.root}>
                      <TableCell component="th" scope="row">
                        Group Name
                      </TableCell>
                      <TableCell>{project.groupName}</TableCell>
                    </TableRow>
                    <TableRow className={classes.root}>
                      <TableCell component="th" scope="row">
                        Department
                      </TableCell>
                      <TableCell>{project.department}</TableCell>
                    </TableRow>
                    <TableRow className={classes.root}>
                      <TableCell component="th" scope="row">
                        Group Memeber(s)
                      </TableCell>
                      <TableCell>
                        {project.groupMembers.length === 2
                          ? `${project.groupMembers[0].name} and  ${project.groupMembers[1].name}`
                          : `${project.groupMembers[0].name}`}
                      </TableCell>
                    </TableRow>
                    {toUpdate === project._id && (
                      <>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "90%",
                            marginLeft: "1rem",
                          }}
                        >
                          <SearchProfessor
                            groupMember={newSupervisor}
                            setGroupMember={setNewSupervisor}
                            label={"New Supervisor"}
                          />
                        </Box>
                        {supervisorUpdated.result === (ERROR || SUCCESS) ? (
                          <Box style={{ margin: "0 1rem", color: "red" }}>
                            {supervisorUpdated.message}
                          </Box>
                        ) : (
                          supervisorUpdated.result === WAIT && (
                            <CircularProgress />
                          )
                        )}
                        <Button
                          type="submit"
                          variant="outlined"
                          onClick={(e) => {
                            updateSupervisor(e, project._id);
                          }}
                          color="primary"
                          className={classes.change}
                        >
                          Update Supervisor
                        </Button>
                      </>
                    )}
                    {!editable && toUpdate !== project._id && (
                      <Button
                        type="submit"
                        variant="outlined"
                        onClick={(e) => {
                          e.preventDefault();
                          setToUpdate(project._id);
                        }}
                        color="default"
                        className={classes.change}
                      >
                        Update Supervisor
                      </Button>
                    )}
                  </Card>
                );
              })
            ) : projects.loaded === ERROR ? (
              <div className={classes.root}>
                <Alert severity="error">{projects.message}</Alert>
              </div>
            ) : (
              projects.loaded === WAIT && (
                <Box
                  className={classes.root}
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              )
            )}
            {loading && (
              <Box
                className={classes.root}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            )}
            {addUserError && (
              <div className={classes.root}>
                <Alert severity="error">{addUserError}</Alert>
              </div>
            )}
            {successMessage && (
              <div className={classes.root}>
                <Alert severity="success">{successMessage}</Alert>
              </div>
            )}
            {projects.loaded === SUCCESS && (
              <>
                {editable ? (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={makeEditable}
                    color="gray"
                    className={classes.submit}
                  >
                    Enable Editing
                  </Button>
                ) : (
                  <GreenButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={handleSubmit}
                    color="primary"
                    className={classes.submit}
                  >
                    Update User
                  </GreenButton>
                )}
              </>
            )}
          </form>
        </ThemeProvider>
      </div>
    </Container>
  );
};
export default EditAdminForm;
