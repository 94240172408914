import React, { useEffect, useState } from 'react'

//---
import Grid from "@material-ui/core/Grid"
//import { makeStyles } from '@material-ui/core/styles';

//---
import PageLayout from '../components/PageLayout'
import CommitteetoDegree from '../components/CommitteetoDegree';
import FormDialog from "../components/FormDialog"
import AddCommitteeForm from '../components/AddCommitteeForm';
//---

// const styles = makeStyles((theme) => ({
//     formControl: {
//         minWidth: 120,
//     },
//     buttonGrid: {
//         alignContent: "flex-end"
//     }
// }));


export default function Committee({ Data }) {
    //const classes = styles();
    const [updated, setUpdated] = useState(0);
    useEffect(() => { }, [updated])
    return (
        <PageLayout Title={Data.name} Icon={Data.icon} >
            <Grid container direction="row" justify="flex-end" alignItems="center" style={{ marginBottom: "1rem", }}>
                <FormDialog Form={<AddCommitteeForm updated={updated} setUpdated={setUpdated} />} Title={"Add Committee"} Heading={"Add Committee Form"} />
            </Grid>
            <CommitteetoDegree updated={updated} setUpdated={setUpdated}/>
        </PageLayout>
    )
}
