import {
    Box,
    CircularProgress,
    Collapse,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import React, { Fragment, useState, useEffect } from "react";
import { ErrorOutline, ExpandLess, ExpandMore } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { fetchNotEnrolledStudents } from "../apiCalls/studentCalls";
import Alert from "@material-ui/lab/Alert";
import { projectsByCommittee } from "../apiCalls/projectCalls";
import { isLoggedIn } from "../helpers/authenticationHelp";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        overflow: "auto",
        maxHeight: 200,
    },
    list: {
        backgroundColor: "#fafafa",
    },

    load: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(2),
    },
}));

const SelectProjects = (props) => {
    const { error, selectedProjects, setSelectedProjects, committeeId } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [projectsList, setProjects] = useState({
        list: [],
        dataLoaded: false,
    });

    const [fetchStudentError, setFetchStudentError] = useState(false);
    const handleClick = () => {
        setSearch("");
        setOpen(!open);
    };

    const handleToggle = (value) => () => {
        const currentIndex = selectedProjects.indexOf(value);
        const newChecked = [...selectedProjects];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedProjects(newChecked);
        error.current = "";
    };

    useEffect(() => {
        const token = isLoggedIn().token;
        projectsByCommittee(token, committeeId).then((projects) => {
            if (projects) {
                if (projects.error || !projects.projects) {
                    setProjects({ list: [], dataLoaded: false });
                } else {
                    setProjects({ list: projects.projects, dataLoaded: true });
                }
            } else {
                setFetchStudentError(true);
                setProjects({ list: [], dataLoaded: false });
            }
        });
    }, []);
    return (
        <Grid item xs={12}>
            {fetchStudentError && (
                <Alert severity="error" className={classes.root}>
                    System is not working properly. Please contact IT support :(
                </Alert>
            )}
            {!projectsList.dataLoaded ? (
                <Box className={classes.load}>
                    <CircularProgress />
                </Box>
            ) : (
                <List>
                    <TextField
                        id="auto"
                        placeholder="search by degree"
                        onClick={handleClick}
                        label="Select Projects"
                        value={search}
                        error={error.current === "project"}
                        onChange={(event) => setSearch(event.target.value)}
                        fullWidth
                        helperText={
                            error.current === "project"
                                ? "Please select atleast one project"
                                : `${selectedProjects.length} project(s) selected`
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {!open ? <ExpandMore /> : <ExpandLess />}
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List className={classes.root} component="div" disablePadding>
                            {projectsList.list.filter(
                                ({ department }) =>
                                    department.toLowerCase().indexOf(search.toLowerCase()) > -1
                            ).length > 0 ? (
                                projectsList.list
                                    .filter(
                                        ({ department }) =>
                                            department.toLowerCase().indexOf(search.toLowerCase()) >
                                            -1
                                    )
                                    .map((project, index) => (
                                        <Fragment key={index}>
                                            <ListItem
                                                className={classes.list}
                                                alignItems="flex-start"
                                            >
                                                <ListItemText
                                                    primary={project.title}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Box>
                                                                <Box flexGrow={1}>
                                                                    {`Project Status: ${project.projectStatus}`}
                                                                </Box>
                                                                <Box>{`Degree: ${project.department}`}</Box>
                                                            </Box>
                                                        </React.Fragment>
                                                    }
                                                />
                                                <ListItemSecondaryAction>
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={handleToggle(project._id)}
                                                        color="primary"
                                                        checked={
                                                            selectedProjects.indexOf(project._id) !== -1
                                                        }
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider component="li" />
                                        </Fragment>
                                    ))
                            ) : (
                                <ListItem>
                                    <ListItemText
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        No data found
                                    </ListItemText>
                                </ListItem>
                            )}
                        </List>
                    </Collapse>
                </List>
            )}
        </Grid>
    );
};
export default SelectProjects;