import React, { useState, useEffect } from "react";
//---
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  withStyles,
  makeStyles,
  ThemeProvider,
  createMuiTheme,
  useTheme,
} from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//---
import { getSignupData } from "../apiCalls/settingCalls";
import SearchProfessor from "./SearchProfessor";
import { addCommittee } from "../apiCalls/committeeCalls";
import { isLoggedIn } from "../helpers/authenticationHelp";
import Checkbox from "@material-ui/core/Checkbox";
import { fetchProfessors } from "../apiCalls/userCalls";
import { editCommittee } from "../apiCalls/committeeCalls";
import Committee from "../pages/Committee";

const GreenButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(degree, degreesSelected, theme) {
  return {
    fontWeight:
      degreesSelected.indexOf(degree) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const signinStyles = makeStyles((theme) => ({
  paper: {
    //marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "primary",
  },
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  formControl: {
    width: "100%  ",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

const AddCommitteeForm = ({ Committee, setUpdated, updated }) => {
  const classes = signinStyles();
  const theme = useTheme();
  const [forEdit, setForEdit] = useState(false);
  const [committeeId, setCommitteeId] = useState("");
  const [committeeName, setCommitteeName] = useState("");
  const [committeeType, setCommitteeType] = useState("");
  const [degreesSelected, setDegreesSelected] = useState([]);
  const [membersSelected, setMembersSelected] = useState([]);
  const [chairman, setChairman] = useState({});
  const [coordinator, setCoordinator] = useState({});

  const [addCommitteeError, setAddCommitteeError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [degrees, setDegrees] = useState([]);
  const [members, setMembers] = useState([]);
  const [names, setNames] = useState([]);

  const handleSelectedDegreeChange = (event) => {
    setDegreesSelected(event.target.value);
  };
  const handleSelectedMemberChange = (event) => {
    setMembersSelected(event.target.value);
    const nameData = [];
    for (let i = 0; i < event.target.value.length; i++) {
      for (let j = 0; j < members.length; j++) {
        if (event.target.value[i] === members[j]._id) {
          nameData.push(members[j].name);
        }
      }
    }
    setNames(nameData);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setAddCommitteeError("");
    setSuccessMessage("");
    setLoading(true);
    // if(committeeName==="" || !committeeType==="" || degreesSelected===[] || chairman==={}|| coordinator==={}){
    //     setAddCommitteeError("All Fields are required")
    // }
    if (forEdit) {
      const data = {
        committeeName,
        committeeType,
        degreesSelected,
        membersSelected,
        chairman,
        coordinator,
        committeeId,
      };
      const token = isLoggedIn().token;
 
      editCommittee(token, data).then((data) => {
        if (data) {
          if (data.error) {
            setAddCommitteeError(data.error);
            setLoading(false);
          } else {
            setSuccessMessage(data.message);
            setLoading(false);
            setUpdated(updated + 1);
          }
        } else {
          setAddCommitteeError("Connection Error!");
          setLoading(false);
        }
      });
    } else {
      const committee = {
        committeeName,
        committeeType,
        degreesSelected,
        membersSelected,
        chairman,
        coordinator,
      };
      const token = isLoggedIn().token;
      
      addCommittee(token, committee).then((data) => {
        if (data) {
          if (data.error) {
            setAddCommitteeError(data.error);
            setLoading(false);
          } else {
            setDegreesSelected([]);
            setCommitteeName("");
            setCommitteeType("");
            setLoading(false);
            setSuccessMessage(data.message);
            setUpdated(++updated);
          }
        } else {
          setLoading(false);
          setAddCommitteeError("Connection Error!");
        }
      });
    }
  };
  useEffect(() => {
    if (Committee) {
      setForEdit(true);
      setCommitteeId(Committee._id);
      setCommitteeName(Committee.name);
      setCommitteeType(Committee.committeeType);
      setDegreesSelected(Committee.degree);
      setCoordinator(Committee.coordinator);
      setChairman(Committee.chairman);

      if(Committee.committeeType!=="GRC"){
        const membersS = Committee.committeeMembers.map((mem) => {
          return mem._id;
        });
        setMembersSelected(membersS);
      }else{
      const membersS = Committee.grcCoordinator.map((mem) => {
        return mem._id;
      });
      setMembersSelected(membersS);
    }
    }
    const token = isLoggedIn().token;
    fetchProfessors(token).then((data) => {
      if (data) {
        if (data.professors) {
          setMembers(data.professors);
          if (Committee) {
            const nameData = [];
            for (let i = 0; i < Committee.committeeMembers.length; i++) {
              for (let j = 0; j < data.professors.length; j++) {
                if (
                  Committee.committeeMembers[i]._id === data.professors[j]._id
                ) {
                  nameData.push(data.professors[j].name);
                }
              }
            }

            setNames(nameData);
           
          }
        
        }
      }
    });
    getSignupData().then((data) => {
      if (data) {
        if (data.degrees) setDegrees(data.degrees);
      }
    });
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <ThemeProvider theme={theme}>
          <form
            className={classes.form}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  required
                >
                  <InputLabel id="committeeTypeId">Committee Type</InputLabel>
                  <Select
                    required
                    label="Committee Type"
                    labelId="committeeTypeId"
                    disabled={forEdit}
                    value={committeeType}
                    onChange={(e) => {
                      setCommitteeType(e.target.value);
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="PC">Proposal Committee</MenuItem>
                    <MenuItem value="DC">Defence Committee</MenuItem>
                    <MenuItem value="GRC">GRC Committee</MenuItem>
                    <MenuItem value="OC">Other Committee</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="cname"
                  name="cname"
                  variant="outlined"
                  required
                  fullWidth
                  id="cname"
                  label="Committee Name"
                  value={committeeName}
                  onChange={(e) => setCommitteeName(e.target.value)}
                  autoFocus
                />
              </Grid>
              {committeeType !== "GRC" && (
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-chip-label">
                      Degree Programs
                    </InputLabel>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      required
                      value={degreesSelected}
                      onChange={handleSelectedDegreeChange}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {degrees.map((degree) => (
                        <MenuItem key={degree} value={degree}>
                          {degree}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <SearchProfessor
                groupMember={chairman}
                setGroupMember={setChairman}
                label={"Chairman Committee"}
              />
              {committeeType === "GRC" ? (
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-chip-label">
                      Committee Coordinators
                    </InputLabel>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      required
                      value={membersSelected}
                      onChange={handleSelectedMemberChange}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {names.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {members.map((member) => (
                        <MenuItem
                          key={member._id}
                          value={member._id}
                          style={getStyles(member, membersSelected, theme)}
                        >
                          {member.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                <>
                  <SearchProfessor
                    groupMember={coordinator}
                    setGroupMember={setCoordinator}
                    label={"Coordinator Committee"}
                  />
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-mutiple-chip-label">
                        Committee Members
                      </InputLabel>
                      <Select
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        multiple
                        required
                        value={membersSelected}
                        onChange={handleSelectedMemberChange}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {names.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                className={classes.chip}
                              />
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {members.map((member) => (
                          <MenuItem
                            key={member._id}
                            value={member._id}
                            style={getStyles(member, membersSelected, theme)}
                          >
                            {member.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
            {loading ? (
              <Box
                className={classes.root}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              ""
            )}
            {addCommitteeError ? (
              <div className={classes.root}>
                <Alert severity="error">{addCommitteeError}</Alert>
              </div>
            ) : (
              ""
            )}
            {successMessage ? (
              <div className={classes.root}>
                <Alert severity="success">{successMessage}</Alert>
              </div>
            ) : (
              ""
            )}
            <GreenButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {forEdit ? "Update Committee" : "Add Committee"}
            </GreenButton>
          </form>
        </ThemeProvider>
      </div>
    </Container>
  );
};
export default AddCommitteeForm;
