const { isLoggedIn } = require("../helpers/authenticationHelp");
const { serverURL } = require("./helper");

export const createTask = async (data, projectId) => {
  isLoggedIn();
  return await fetch(
    `${serverURL}/create-task/${isLoggedIn().user._id}?` +
      new URLSearchParams({ projectId: projectId }),
    {
      method: "PUT",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchTasks = async (projectId) => {
  isLoggedIn();
  return await fetch(
    `${serverURL}/fetchTasks/${isLoggedIn().user._id}?` +
      new URLSearchParams({ projectId: projectId }),
    {
      method: "GET",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteTask = async (taskId, projectId) => {
  isLoggedIn();
  return await fetch(
    `${serverURL}/deleteTask/${isLoggedIn().user._id}?` +
      new URLSearchParams({ taskId: taskId, projectId: projectId }),
    {
      method: "DELETE",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateTask = async (data, projectId, taskId) => {
  isLoggedIn();
  return await fetch(
    `${serverURL}/updateTask/${isLoggedIn().user._id}?` +
      new URLSearchParams({ projectId: projectId, taskId: taskId }),
    {
      method: "PUT",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateTaskStatus = async (data, projectId, taskId) => {
  isLoggedIn();
  return await fetch(
    `${serverURL}/updateTaskStatus/${isLoggedIn().user._id}?` +
      new URLSearchParams({ projectId: projectId, taskId: taskId }),
    {
      method: "PUT",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",      Authorization: `Bearer ${isLoggedIn().token}`,

      },
      body: JSON.stringify({ status: data }),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
