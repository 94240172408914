import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { TableRow } from '@material-ui/core';
import MuiTableCell from "@material-ui/core/TableCell";
import { userSetting } from "../apiCalls/userCalls"
import TextField from '@material-ui/core/TextField';

import Alert from "@material-ui/lab/Alert"
import Box from "@material-ui/core/Box"
import CircularProgress from '@material-ui/core/CircularProgress';
import { isLoggedIn } from "../helpers/authenticationHelp"
import { addphoneNumber } from "../apiCalls/userCalls"

const TableCell = withStyles({
    root: {
        borderBottom: "none",
        padding: ".3rem"
    }
})(MuiTableCell);

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        padding: "0px",
        margin: "0px"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});
const user = {
    "student_details": {
        "isEligible": false,
        "batch": "Loading...",
        "regNo": "Loading...",
        "status": "Loading..."
    },
    "roles": [
        "Student"
    ],
    "_id": "Loading...",
    "email": "Loading...",
    "department": "Loading...",
    "created": "2021-06-18T19:16:32.781Z",
    "name": "Loading..."
}


const UserSetting = () => {
    const [user, setUser] = useState({});
    const [error, setError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [errorAddNumber, setErrorAddNumber] = useState("");
    const [loading, setLoading] = useState(true)
    const classes = useStyles();


    const addNumber = (e) => {
        e.preventDefault()
        setErrorAddNumber("")
        var phoneno = /^\d{11}$/;
        if (phoneNumber.match(phoneno)) {
            setErrorAddNumber("Adding phone number...")
            const token = isLoggedIn().token
            const number = { phoneNumber }
            addphoneNumber(number, token).then(data => {
                if (data) {
                    if (data.error) {
                        setErrorAddNumber(data.error)
                    } else {
                        user.phone_number = phoneNumber
                        setErrorAddNumber("")
                    }
                } else {
                    setErrorAddNumber("Connection Error")
                }
            })
        } else {
            setErrorAddNumber("Enter Valid 11 digit number")
        }

    }
    useEffect(() => {
        const token = isLoggedIn().token
        userSetting(token).then(data => {
            if (data) {
                if (data.error) {
                    setError(data.error)
                    setLoading(false)
                } else {
                    setUser(data)
                    setLoading(false)
                }
            } else {
                setError("Connection Error")
                setLoading(false)
            }
        })
    }, [])


    return (
      <Card className={classes.root}>
        {loading ? (
          <Box className={classes.root} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <div className={classes.root}>
            <Alert severity="error">{error}</Alert>
          </div>
        ) : (
          <CardContent>
            <TableRow>
              <TableCell style={{ fontWeight: 500 }}>Name:</TableCell>
              <TableCell>{user.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 500 }}>Email:</TableCell>
              <TableCell>{user.email}</TableCell>
            </TableRow>
            {user.phone_number && (
              <TableRow>
                <TableCell style={{ fontWeight: 500 }}>Phone:</TableCell>
                <TableCell>{user.phone_number}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell style={{ fontWeight: 500 }}>Created:</TableCell>
              <TableCell>{new Date(user.created).toDateString()}</TableCell>
            </TableRow>

            {user.roles && user.roles.includes("Student") && (
              <>
                <TableRow>
                  <TableCell>Reg No</TableCell>
                  <TableCell>{user.student_details.regNo}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Batch</TableCell>
                  <TableCell>{user.student_details.batch}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Eligible</TableCell>
                  <TableCell>
                    {user.student_details.isEligible ? "True" : "False"}
                  </TableCell>
                </TableRow>
              </>
            )}
            {!user.phone_number && (
              <form
                className={classes.form}
                onSubmit={addNumber}
                mt={2}
                noValidate
              >
                <TextField
                  style={{ marginTop: "0.5rem" }}
                  autoComplete="number"
                  name="number"
                  variant="outlined"
                  required
                  fullWidth
                  size="small"
                  id="phoneNumber"
                  label="Add WhatsApp Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  error={errorAddNumber}
                  helperText={errorAddNumber}
                  autoFocus
                />
              </form>
            )}
          </CardContent>
        )}
      </Card>
    );
}

export default UserSetting;
