import React, { useRef, useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import { isLoggedIn } from "../../helpers/authenticationHelp";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import { Paper } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import green from "@material-ui/core/colors/green";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { CircularProgress } from "@material-ui/core";
import Announcement from "@material-ui/icons/Announcement";
import { generateMeetingLink,updateMeeting } from "../../apiCalls/meetingCalls";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link"

const materialTheme = createMuiTheme({
  palette: {
    primary: green,
  },
  overrides: {
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: "white",
      },
      toolbarTxt: {
        color: "#c8e6c9",
      },
    },
    MuiTabs: {
      scroller: {
        color: "#eeeeee",
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  success: {
    alignItems: "center",
    padding: theme.spacing(3),
    textAlign: "center",
    backgroundColor: "#dcedc8",
  },
  unsuccess: {
    alignItems: "center",
    padding: theme.spacing(3),
    textAlign: "center",
    backgroundColor: "#ffebee",
  },
  save: {
    alignItems: "center",
    padding: theme.spacing(3),
    textAlign: "center",
    backgroundColor: "#ffecb3",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    padding: theme.spacing(8),
  },
  successIcon: {
    color: "#7cb342",
    fontSize: "8rem",
  },
  unsuccessIcon: {
    color: "#c62828",
    fontSize: "8rem",
  },
  saveIcon: {
    color: "#ff6f00",
    fontSize: "8rem",
  },
  button: {
    color: "white",
    fontWeight: "400",
    letterSpacing: "0.04957em",
  },
}));

const UpdateMeeting = ({
    openUpdate,
    setOpenUpdate,
    meeting,
    refreshMeeting,
    setRefreshMeeting
}) => {
  const classes = useStyles();
  const [description, setDescription] = useState(meeting.description);
  const [date, setDate] = useState(new Date(meeting.date));
  const [link, setLink] = useState(meeting.link);
  const [meetingType, setMeetingType] = useState(meeting.meetingType);
  const [venue, setVenue] = useState(meeting.venue);

 

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [step, setStep] = useState(1);
  const errorIn = useRef("");
  const errorMessage = useRef("");
  const [refresh, setRefresh] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    if (success) {
      setRefreshMeeting(!refreshMeeting);
    }
    setOpenUpdate(false);
  };
  const validate = (forLink) => {        errorIn.current = "";
  errorMessage.current = "";
  const currentDate = Date.now();
  const exDate = new Date(date);

  if (meetingType === "Physical") setLink("");
  else setVenue("");
  var expression =
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%.\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%\+.~#?&//=]*)/;
  var regex = new RegExp(expression);
  if (!forLink) {
    if (meetingType === "Remote" && (link.trim().length < 5 || link.trim().length > 500))
        return {
            errorIn: "link",
            error: "Link must be between 5-500 characters",
        };
    if (meetingType === "Remote" && !link.match(regex))
        return {
            errorIn: "link",
            error: "Invalid link",
        };
 
}
  if (description.trim().length < 5 || description.trim().length > 500)
      return {
          errorIn: "description",
          error: "Description must be between 5-500 characters",
      };

   else if (exDate.getFullYear > 5000 || isNaN(exDate.getTime()))
      return {
          errorIn: "date",
          error: "Invalid Date",
      };
  else if (currentDate > exDate.getTime())
      return {
          errorIn: "date",
          error: "Schedule time must be greater than present time",
      };
  else if (
      meetingType === "Physical" &&
      (venue.trim().length < 5 || venue.trim().length > 500)
  )
      return {
          errorIn: "venue",
          error: "Venue must be between 5-500 characters",
      };
 else return ""
  };
  const generateLink = () => {
    const Errors = validate(true);
    if (Errors !== "") {
        errorIn.current = Errors.errorIn;
        errorMessage.current = Errors.error;
        setRefresh(!refresh);
    } else {
        const token = isLoggedIn().token
        const data = { description, date }
        generateMeetingLink(token, data).then(data => {
            if (data) {
                if (data.error) {

                } else if (data.link) {
                    setLink(data.link)
                }
            } 
        })
    }
}
  const handleYes = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = { description, date,link,meetingType,venue };
    updateMeeting(data, meeting._id).then((data) => {
      if (data) {
        if (data.error) {
          setLoading(false);
          setStep(3);
        } else {
          setSuccess(true);
          setLoading(false);
          setStep(3);
        }
      } else {
        setLoading(false);
        setStep(3);
      }
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    
    const Errors = validate(false);
    if (Errors != ""){
        errorIn.current = Errors.errorIn;
        errorMessage.current = Errors.error;
        setRefresh(!refresh);
    } 
    else {
        setStep(2);
    }
  };
  const handleBack = (e) => {
    e.preventDefault();
    setStep(1);
  };
  useEffect(() => {
    return () => { };
  }, [errorIn.current]);
  return (
    <>
      <Dialog
        open={openUpdate}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {step === 1 && (
          <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
            Edit Meeting
          </DialogTitle>
        )}

        <DialogContent>
          <Grid container spacing={2} style={{ maxWidth: "25rem" }}>
            {step === 1 && (
              <>
                <Grid item xs={12}>
                <TextField
                            autoFocus
                            margin="dense"
                            id="description"
                            label="Description"
                            multiline
                            rows={1}
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                                errorIn.current = "";
                            }}
                            error={errorIn.current === "description"}
                            helperText={
                                errorIn.current === "description"
                                    ? errorMessage.current
                                    : `${description.length}/500`
                            }
                            variant="outlined"
                            fullWidth
                        />
                </Grid>
                <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                                <DateTimePicker
                                    disablePast
                                    value={date}
                                    onChange={(e) => {
                                        setDate(e);
                                        errorIn.current = "";
                                    }}
                                    error={errorIn.current === "date"}
                                    helperText={
                                        errorIn.current === "date" ? errorMessage.current : ""
                                    }
                                    label="Meeting Time"
                                    fullWidth
                                    format="MMMM d, yyyy, h:mm a"
                                    variant="inline"
                                    allowKeyboardControl
                                    animateYearScrolling
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                <FormControl component="fieldset">
                            <FormLabel component="legend">Meeting Type</FormLabel>
                            <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                value={meetingType}
                                onChange={(e) => {
                                    setMeetingType(e.target.value);
                                    errorIn.current = "";
                                }}
                            >
                                <FormControlLabel
                                    value="Physical"
                                    control={<Radio color="primary" />}
                                    label="Physical"
                                    style={{ marginRight: "5rem" }}
                                />
                                <FormControlLabel
                                    value="Remote"
                                    control={<Radio color="primary" />}
                                    label="Remote"
                                />
                            </RadioGroup>
                        </FormControl>
                </Grid>
                {meetingType === "Physical" && (
                        <Grid item xs={12} >
                            <TextField
                                margin="dense"
                                id="venue"
                                label="Venue"
                                value={venue}
                                multiline
                                rows={1}
                                onChange={(e) => {
                                    setVenue(e.target.value);
                                    errorIn.current = "";
                                }}
                                error={errorIn.current === "venue"}
                                helperText={
                                    errorIn.current === "venue"
                                        ? errorMessage.current
                                        : `${venue.length}/500`
                                }
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    )}
                    {meetingType === "Remote" && (
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                id="link"
                                label="Link"
                                value={link}
                                onChange={(e) => {
                                    setLink(e.target.value);
                                    errorIn.current = "";
                                }}
                                error={errorIn.current === "link"}
                                helperText={
                                    errorIn.current === "link"
                                        ? errorMessage.current
                                        : `${link.length}/500`
                                }
                                variant="outlined"
                                fullWidth
                            />
                            <Box display="flex" justifyContent="flex-end" m={0} p={0}>
                                <Link href="#" onClick={() => generateLink()}>Generate Link</Link>
                            </Box>
                        </Grid>
                    )}
              </>
            )}
            {step === 2 && (
              <Grid item xs={12}>
                <Paper elevation={3} className={classes.save}>
                  <Grid item xs={12}>
                    <Announcement className={classes.saveIcon} />
                  </Grid>
                  {!loading ? (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h6">Update Meeting?</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          Are you sure you want to update this meeting? This action
                          cannot be undone!
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h6">Updating Meeting...</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <CircularProgress />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          Please wait while we are processing your request.
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Paper>
              </Grid>
            )}
            {step === 3 && (
              <>
                {success ? (
                  <Grid item xs={12}>
                    <Paper elevation={3} className={classes.success}>
                      <Grid item xs={12}>
                        <CheckCircleIcon className={classes.successIcon} />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6">Meeting Updated!</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          Congratulations! You have successfully updated this
                          meeting.
                        </Typography>
                      </Grid>
                    </Paper>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Paper elevation={3} className={classes.unsuccess}>
                      <Grid item xs={12}>
                        <SentimentVeryDissatisfiedIcon
                          className={classes.unsuccessIcon}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6">System Error!</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          System is unable to update this meeting. Please contact IT
                          support to report this problem.
                        </Typography>
                      </Grid>
                    </Paper>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {!loading && (
            <>
              <Button
                onClick={handleClose}
                variant={step == 3 ? "contained" : "text"}
                color="primary"
                className={step === 3 ? classes.button : ""}
              >
                {step === 2 ? "Close" : "Cancel"}

              </Button>
              {step === 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleUpdate}
                >
                  UPDATE
                </Button>
              )}
              {step === 2 && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleBack}
                >
                  Back
                </Button>
              )}
              {step === 2 && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleYes}
                >
                  Yes
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
export default UpdateMeeting;
