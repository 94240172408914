import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import Logo from "../../assets/images/iiui-logo.jpg";

// Create styles
const styles = StyleSheet.create({
  body: {
    padding: "30 50",
    fontFamily: "Times-Roman",
    textAlign: "justify",
    whiteSpace: "normal",
    textJustify: "inter-word",
    fontSize: 12,
    lineHeight: 1.6,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderStyle: "none none solid none",
    textAlign: "center",
    justifyContent: "center",
    padding: 5,
  },
  logo: {
    width: 70,
    height: 70,
  },
  headerTitle: {
    padding: 0,
    display: "flex",
    textAlign: "center",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    fontSize: "12px",
  },
  divider: {
    backgroundColor: "#000",
    height: "1px",
    marginBottom: 10,
  },


  field: {
    borderBottom: "1px solid bottom",
    lineHeight: 0,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginBottom: "15px",
    marginLeft: 4,
    paddingLeft: 30,
  },

  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableColGRC: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColComment: {
    width: "60%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColDecision: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex",
    textAlign: "center",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCell: { margin: 5 },





});

const CommentsLetter = ({
  title,
  students,
  department,
  comments,
  setting,
  fileName,
}) => (
  <Document title={`${fileName}`}>
    <Page size="A4" style={styles.body}>
      <View style={styles.header}>
        <Image src={Logo} style={styles.logo} />
        <View style={styles.headerTitle}>
          <Text style={{ fontFamily: "Times-Bold" }}>
            International Islamic University, Islamabad
          </Text>
          <Text>Faculty of Basic and Applied Sciences</Text>
          <Text
            style={{ fontFamily: "Times-Bold", textDecoration: "underline" }}
          >
            Graduate Research Committee
          </Text>
          <Text
            style={{ fontFamily: "Times-Bold", textDecoration: "underline" }}
          >
            Evaluation Form (MS/PhD Research Thesis/Proposal)
          </Text>
          <Text>{setting.departmentFullName}</Text>
        </View>
      </View>
      <View style={styles.divider} />

      <View
        style={{ paddingTop: "20px", display: "flex", flexDirection: "row" }}
      >
        <Text style={{ paddingTop: "6px" }}>Name of Candidate:-</Text>
        <Text style={styles.field}>{students[0].name}</Text>
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <Text style={{ paddingTop: "6px" }}>Registration Number:-</Text>
        <Text style={styles.field}>
          {`${students[0].student_details.regNo}-FBAS/${department}/${students[0].student_details.batch}`}
        </Text>
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <Text style={{ paddingTop: "6px" }}>Program Discipline:-</Text>
        <Text style={styles.field}>{department}</Text>
        <Text style={{ paddingLeft: 80, paddingRight: 40 }}>
          (Proposal/Thesis)
        </Text>
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <Text style={{ paddingTop: "6px" }}>Thesis Title:-</Text>
        <Text style={[styles.field, { paddingLeft: "20px" }]}>{title}</Text>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableColGRC, { fontFamily: "Times-Bold" }]}>
            <Text style={styles.tableCell}>GRC Member</Text>
          </View>
          <View style={styles.tableColComment}>
            <Text style={styles.tableCell}>Comments</Text>
          </View>
          <View style={styles.tableColDecision}>
            <Text style={styles.tableCell}>Decision</Text>
          </View>
        </View>

        {comments.map((comment) => (
          <>
            {!comment.final && (
              <View style={styles.tableRow}>
                <View style={styles.tableColGRC}>
                  <Text style={styles.tableCell}>{comment.author.name}</Text>
                </View>
                <View style={styles.tableColComment}>
                  <Text style={styles.tableCell}>{comment.text}</Text>
                </View>
                <View style={styles.tableColDecision}>
                  <Text style={styles.tableCell}>
                    {comment.decision === "Represent"
                      ? "Accepted with Major Changes"
                      : comment.decision === "Approved with Minor Changes"
                      ? "Accepted with Minor Changes"
                      : comment.decision === "Approved"
                      ? "Accepted"
                      : comment.decision === "Rejected"
                      ? "Rejected"
                      : ""}
                  </Text>
                </View>
              </View>
            )}
          </>
        ))}

        {/* these are the comments from GRC Coordinator */}
        {comments.map((comment) => (
          <>
            {comment.final && (
              <View style={styles.tableRow}>
                <View
                  style={{
                    width: "100%",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderLeftWidth: 0,
                    borderTopWidth: 0,
                    padding: 5,
                  }}
                >
                  <Text>
                    COMMENTS: (Additional Comments from GRC Coordinator)
                  </Text>
                  <Text>{comment.text}</Text>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 20,
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "50%",
                        paddingRight: 70,
                      }}
                    >
                      <Text>Date:</Text>
                      <Text style={styles.field}>{`${moment(
                        comment.createdAt
                      ).format("DD-MM-YYYY")}`}</Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "right",
                        width: "50%",
                      }}
                    >
                      <Text>Coordinator's Name:</Text>
                      <Text style={styles.field}>{comment.author.name}</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "60%",
                    }}
                  >
                    <Text>Final Decision: </Text>
                    <Text style={{ fontFamily: "Times-Bold" }}>
                      {comment.decision === "Represent"
                        ? "Accepted with Major Changes"
                        : comment.decision === "Approved with Minor Changes"
                        ? "Accepted with Minor Changes"
                        : comment.decision === "Approved"
                        ? "Accepted"
                        : comment.decision === "Rejected"
                        ? "Rejected"
                        : ""}
                    </Text>
                  </View>
                </View>
              </View>
            )}
          </>
        ))}
      </View>

      <View>
        <Text style={{ marginTop: 20 }}>
          Graduate Research Committee, 
          {setting.departmentFullName} International Islamic University Islamabad
          Pakistan Email: coordinator.grc@iiu.edu.pk
        </Text>
      </View>
    </Page>
  </Document>
);

export default CommentsLetter;