import React from "react";
import PageLayout from "../components/PageLayout";
import MeetingView from "../components/Meetings/MeetingView.js";
import { roleNow } from "../helpers/authenticationHelp";


const Meetings = ({ Data }) => {

    
    return (
        <PageLayout Title={Data.name} Icon={Data.icon}>
               <MeetingView admin={false}/>
        </PageLayout>
    );
};

export default Meetings;