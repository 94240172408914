import React, { useState, useEffect } from "react";
//---
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import { TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import PageLayout from "./PageLayout";
import MuiTableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Chip from "@material-ui/core/Chip";
import FormDialog from "./FormDialog";
import ProjectView from "./ProjectView";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import ProjectViewGRC from "./ProjectViewGRC";

//----
import {
  WAIT,
  ERROR,
  SUCCESS,
  REJECTED,
  PENDING,
  APPROVED,
} from "../variables/enums";
import { updateGRCProjectStatus } from "../apiCalls/projectCalls";
import Taskboard from "./TaskBoard/Taskboard.js";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    overflow: "hidden",
  },
  bold: {
    fontWeight: "bold",
  },
});

const TableCell = withStyles({
  root: {
    //borderBottom: "none",
    padding: ".8rem",
  },
})(MuiTableCell);

const styles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "green",
  },
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  "@keyframes delayedHide": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },

  autoHide: {
    animationName: "delayedHide",
    animationDuration: "300ms",
    animationDelay: (props) => props.delay || "2s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  table: {
    minWidth: 650,
  },
}));
export default function RowGRCSupervision({ project }) {
  const classes101 = styles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const classes = useRowStyles();
  const [marks, setMarks] = useState();
  const [requestStatus, setRequestStatus] = useState({ msg: "", status: "" });
  const [openSnack, setOpenSnack] = React.useState(false);
  const [change, setChange] = useState(0);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const changeProjectStatus = (projectId, status) => {
    setRequestStatus({ msg: "", status: WAIT });
    const projectStatus = { status };
    updateGRCProjectStatus(projectId, projectStatus).then((data) => {
      if (data) {
        if (data.error) {
          setRequestStatus({ msg: data.error, status: ERROR });
          setOpenSnack(true);
        } else {
          // when it was successsful
          project.projectStatus = status;
          setRequestStatus({ msg: "Status Updated!", status: SUCCESS });
          setOpenSnack(true);
        }
      } else {
        setRequestStatus({
          msg: "Failed to connect to server",
          status: ERROR,
        });
        setOpenSnack(true);
      }
    });
  };

    useEffect(() => {
      setLoading(true);
    }, [setRefresh, refresh]);

  return (
    <React.Fragment>
      <TableRow className={classes.root} style={{ backgroundColor: "#EDF7ED" }} onClick={() => setOpen(!open)}>
        <TableCell component="th" scope="row">
          <h3>{project.title}</h3>
        </TableCell>
        <TableCell>
          <h4>{project.groupName}</h4>
        </TableCell>
        <TableCell>
          <h4>{project.department}</h4>
        </TableCell>
        <TableCell>
          <h4>
            <Chip
              label={
                project.projectStatus === "Represent"
                  ? "Accepted with Major Changes"
                  : project.projectStatus === "Approved with Minor Changes"
                  ? "Accepted with Minor Changes"
                  : project.projectStatus === "Approved"
                  ? "Accepted"
                  : project.projectStatus === "Rejected"
                  ? "Rejected"
                  : project.projectStatus
              }
              style={{ color: "white", backgroundColor: `${project.projectStatus === "Pending" ? "#3d5afe" : project.projectStatus === "Approved" ? "#357a38" : project.projectStatus === "Ready for Defence" ? "#ff5722" : project.projectStatus === "Completed" ? "#004d40" :"#212121"}`}}
            />
          </h4>
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={classes.root}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Card
              style={{ width: "100%", marginTop: "1rem", marginBottom: "1rem" }}
            >
              <div>
                <Box
                  m={1}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography className={classes.bold}>
                    Supervisor Task Board
                  </Typography>
                </Box>
                {loading && (
                  <Box
                    mt={3}
                    mb={3}
                    className={classes.root}
                    display="flex"
                    justifyContent="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
                <Taskboard
                  projectId={project._id}
                  admin={true}
                  setLoading={setLoading}
                  loading={loading}
                  refreshTaskboard={refresh}
                  setRefreshTaskboard={setRefresh}
                />
              </div>
            </Card>
            {/* showing project */}
            <Box mb={2}>
              <ProjectViewGRC userId={project.groupMembers[0]._id} />
            </Box>
            {/* shwoing supervisor controls */}
            <Card style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <Box style={{ margin: "1rem" }}>
                <Typography className={classes.bold}>
                  Supervisor Controls
                </Typography>
                {/* we have some conditions here 
            when the project is in pending state we will forward it for initial evaluation
            and then when it is approved then we will forward it for  Final evaluation*/}
                {requestStatus.status === WAIT ? (
                  <Box style={{ disply: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {project.projectStatus === "Pending" ||
                    project.projectStatus === "Represent" ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: "1rem" }}
                        onClick={() => {
                          changeProjectStatus(
                            project._id,
                            "Ready for Initial Approval"
                          );
                        }}
                      >
                        Forward for Initial Evaluation
                      </Button>
                    ) : project.projectStatus ===
                      "Ready for Initial Approval" ? (
                      <Table>
                        <TableRow>
                          <TableCell>Project Status</TableCell>
                          <TableCell>{project.projectStatus}</TableCell>
                        </TableRow>
                      </Table>
                    ) : project.projectStatus === "Approved" ||
                      project.projectStatus ===
                        "Approved with Minor Changes" ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: "1rem" }}
                        onClick={() => {
                          changeProjectStatus(project._id, "Ready for Defence");
                        }}
                      >
                        Forward for Final Evaluation
                      </Button>
                    ) : project.projectStatus === "Ready for Defence" ? (
                      <p>Ready for Defence</p>
                    ) : (
                      project.projectStatus === "Completed" && (
                        <Table>
                          <TableRow>
                            <TableCell>Project Status</TableCell>
                            <TableCell>{project.projectStatus}</TableCell>
                          </TableRow>
                        </Table>
                      )
                    )}
                  </>
                )}
              </Box>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                open={openSnack}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
              >
                <Alert severity="error">{requestStatus.msg}</Alert>
              </Snackbar>
            </Card>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
