import React, { useState, useEffect } from "react";
//---
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import { TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import PageLayout from "../components/PageLayout";
import MuiTableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import FormDialog from "../components/FormDialog";
import ProjectView from "../components/ProjectView";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";


//----
import {
  WAIT,
  ERROR,
  SUCCESS,
  REJECTED,
  PENDING,
  APPROVED,
} from "../variables/enums";
import {
  projectsBySupervisor,
  forwardForDefence,
  assignP1MarksCall
} from "../apiCalls/projectCalls";
import Taskboard from "../components/TaskBoard/Taskboard.js";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    overflow: "hidden",
  },
  bold: {
    fontWeight: "bold",
  },
});

const TableCell = withStyles({
  root: {
    //borderBottom: "none",
    padding: ".8rem",
  },
})(MuiTableCell);

const styles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "green",
  },
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  "@keyframes delayedHide": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },

  autoHide: {
    animationName: "delayedHide",
    animationDuration: "300ms",
    animationDelay: (props) => props.delay || "2s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  table: {
    minWidth: 650,
  },
}));
export default function RowUGPCSupervision({ project }) {
  const classes101 = styles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const classes = useRowStyles();
  const [marks, setMarks] = useState(0);
  const [p1Marks, setP1Marks] = useState(0);

  const [requestStatus, setRequestStatus] = useState({ msg: "", status: "" });

  const [openSnack, setOpenSnack] = React.useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };
  const assignP1Marks = (projectId) => {
    if (parseInt(p1Marks) < 1 || parseInt(p1Marks) > 98) {
      setRequestStatus({ status: ERROR, msg: "Invalid P1 Marks" });
      setOpenSnack(true)
    } else {
      //we will send request to backend to improve marks
      setRequestStatus({ status: WAIT, msg: "" });
      console.log(projectId, p1Marks)
      const updateDetails = { p1Marks, projectId }
      assignP1MarksCall(updateDetails).then(data => {
        if (data) {
          if (data.error) {
            setRequestStatus({ status: ERROR, msg: data.error });
            setOpenSnack(true)
          } else {
            project.marks.p1Marks=p1Marks;
            setRequestStatus({ status: ERROR, msg: data.message });
          }
        } else {
          setRequestStatus({ status: ERROR, msg: "Unable to connect to server" });
          setOpenSnack(true)

        }
      })
    }
  }
  const changeProjectStatus = (projectId, status) => {
    setRequestStatus({ status: WAIT, msg: "" });
    if (status) {
      if (parseInt(marks) > 3 && parseInt(marks) < 30) {
        const dataToUpdate = { status, marks };
        forwardForDefence(projectId, dataToUpdate).then((data) => {
          if (data) {
            if (data.error) {
              setRequestStatus({ status: ERROR, msg: data.error });
              setOpenSnack(true)

            } else {
              project.projectStatus = data.status;
              project.marks.supervisor = marks;
              setRequestStatus({ status: SUCCESS, msg: data.message });
            }
          } else {
            setRequestStatus({ status: ERROR, msg: "Unable to connect!" });
            setOpenSnack(true)

          }
        });
      } else {
        setRequestStatus({ status: ERROR, msg: "Invalid Marks" });
        setOpenSnack(true)

      }
    } else {
      const dataToUpdate = { status, marks };
      setRequestStatus({ status: WAIT, msg: "" });

      forwardForDefence(projectId, dataToUpdate).then((data) => {
        if (data) {
          if (data.error) {
            setRequestStatus({ status: ERROR, msg: data.error });
            setOpenSnack(true)
          } else {
            project.projectStatus = data.status;
            setRequestStatus({ status: SUCCESS, msg: data.message });
          }
        } else {
          setRequestStatus({ status: ERROR, msg: "Unable to connect!" });
          setOpenSnack(true)

        }
      });
    }
  };

  useEffect(() => {
    setLoading(true);
  }, [setRefresh, refresh]);

  return (
    <React.Fragment>
      <TableRow className={classes.root} style={{ backgroundColor: "#EDF7ED" }} onClick={() => setOpen(!open)}>
        <TableCell component="th" scope="row">
          <h3>{project.title}</h3>
        </TableCell>
        <TableCell>
          <h4>{project.groupName}</h4>
        </TableCell>
        <TableCell>
          <h4>{project.department}</h4>
        </TableCell>
        <TableCell>
          <h4></h4>
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={classes.root}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Card
              style={{ width: "100%", marginTop: "1rem", marginBottom: "1rem" }}
            >
              <div>
                <Box
                  m={1}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography className={classes.bold}>
                    Supervisor Task Board
                  </Typography>
                </Box>
                {loading && (
                  <Box
                    mt={3}
                    mb={3}
                    className={classes.root}
                    display="flex"
                    justifyContent="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
                <Taskboard
                  projectId={project._id}
                  admin={true}
                  setLoading={setLoading}
                  loading={loading}
                  refreshTaskboard={refresh}
                  setRefreshTaskboard={setRefresh}
                />
              </div>
            </Card>
            <Box mb={2}>
              <ProjectView project={project} />
            </Box>
            <Card style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <div style={{ margin: "1rem" }}>
                <Box>
                  <Typography className={classes.bold}>
                    Supervisor Controls
                  </Typography>
                </Box>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell>Project Status</TableCell>
                      <TableCell>{project.projectStatus}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>P1 Marks</TableCell>
                      <TableCell>
                        {!project.marks.p1Marks ? (
                          <form
                            className={classes.root}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              id="standard-basic"
                              label="Marks"
                              type="number"
                              min="0"
                              value={p1Marks}
                              onChange={(e) => setP1Marks(e.target.value)}
                              InputProps={{ inputProps: { min: 1, max: 100 } }}
                            />
                          </form>
                        ) : (
                          project.marks.p1Marks
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Internal Marks</TableCell>
                      <TableCell>
                        {project.projectStatus === "Approved" ? (
                          <form
                            className={classes.root}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              id="standard-basic"
                              label="Marks"
                              type="number"
                              min="0"
                              value={marks}
                              onChange={(e) => setMarks(e.target.value)}
                              InputProps={{ inputProps: { min: 1, max: 29 } }}
                            />
                          </form>
                        ) : (
                          project.marks.supervisor
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {requestStatus.status === WAIT ? (
                  <Box
                    className={classes101.root}
                    display="flex"
                    justifyContent="center"
                  >
                    <CircularProgress />
                  </Box>
                ) : project.projectStatus === "Approved" ? (
                  <Box mt={1} mb={1}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {!project.marks.p1Marks &&
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => assignP1Marks(project._id)}
                          style={{ marginRight: "1rem" }}
                        >
                          Assign P1 Marks
                        </Button>
                      }
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => changeProjectStatus(project._id, true)}
                      >
                        Forward for Defence
                      </Button>
                    </div>
                  </Box>
                ) : (
                  <Box mt={1} mb={1}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => changeProjectStatus(project._id, false)}
                      >
                        Revert Decision
                      </Button>
                    </div>
                  </Box>
                )}
              </div>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                open={openSnack}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
              >
                <Alert severity="error">{requestStatus.msg}</Alert>
              </Snackbar>
            </Card>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

