const { serverURL } = require("./helper");
const { isLoggedIn } = require("../helpers/authenticationHelp");

export const uploadResource = (location, data, name) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/uploadResource/${location}?` +
      new URLSearchParams({ name: name }),
    {
      method: "POST",
  
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,
        "Content-Type": "application/json",

      },
      body: data,
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchResources = async () => {
  isLoggedIn();
  return await fetch(`${serverURL}/resources/fetch`, {
    method: "GET",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
    });
};

export const deleteResource = async (resourceId, type, filename) => {
  isLoggedIn();
  return await fetch(
    `${serverURL}/deleteResource?` +
      new URLSearchParams({
        resourceId: resourceId,
        type: type,
        filename: filename,
      }),
    {
      method: "DELETE",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
