import React, { useState, useRef } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from "@material-ui/core/styles"
import green from "@material-ui/core/colors/green";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import { RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import SelectProjects from "./SelectProjects";
import Link from "@material-ui/core/Link"
import { isLoggedIn } from '../helpers/authenticationHelp';
import { generateMeetingLink, createMeeting } from "../apiCalls/meetingCalls"
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const materialTheme = createMuiTheme({
    palette: {
        primary: green,
    },
    overrides: {
        MuiPickersToolbarText: {
            toolbarBtnSelected: {
                color: "white",
            },
            toolbarTxt: {
                color: "#c8e6c9",
            },
        },
        MuiTabs: {
            scroller: {
                color: "#eeeeee",
            },
        },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));

const MeetingsScheduler = ({ committee }) => {
    const classes = useStyles();
    const [description, setDescription] = useState("");
    const [date, setDate] = useState(new Date());
    const [link, setLink] = useState("");
    const [meetingType, setMeetingType] = useState("Physical");
    const [venue, setVenue] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [projects, setProjects] = useState([]);

    const [message, setMessage] = useState("")
    const errorIn = useRef("");
    const errorMessage = useRef("");
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const validate = (forLink) => {
        errorIn.current = "";
        errorMessage.current = "";
        const currentDate = Date.now();
        const exDate = new Date(date);
        if (meetingType === "Physical") setLink("");
        else setVenue("");
        var expression =
            /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%.\+#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%\+.#?&//=]*)/;
        var regex = new RegExp(expression);
      
        if (!forLink) {
            if (meetingType === "Remote" && (link.trim().length < 5 || link.trim().length > 500))
                return {
                    errorIn: "link",
                    error: "Link must be between 5-500 characters",
                };
            if (meetingType === "Remote" && !link.match(regex))
                return {
                    errorIn: "link",
                    error: "Invalid link",
                };
            
        }
        if (description.trim().length < 5 || description.trim().length > 500)
        return {
            errorIn: "description",
            error: "Description must be between 5-500 characters",
        };
        else if (exDate.getFullYear > 5000 || isNaN(exDate.getTime()))
            return {
                errorIn: "date",
                error: "Invalid Date",
            };
        else if (currentDate > exDate.getTime())
            return {
                errorIn: "date",
                error: "Schedule time must be greater than present time",
            };
        else if (
            meetingType === "Physical" &&
            (venue.trim().length < 5 || venue.trim().length > 500)
        )
            return {
                errorIn: "venue",
                error: "Venue must be between 5-500 characters",
            };
        else if (projects.length < 1) {
            return {
                errorIn: "project",
                error: "Please select atleast one project",
            };
        } else return ""
    };
    const generateLink = () => {
        const Errors = validate(true);
        if (Errors !== "") {
            errorIn.current = Errors.errorIn;
            errorMessage.current = Errors.error;
            setRefresh(!refresh);
        } else {
            const token = isLoggedIn().token
            const data = { description, date }
            generateMeetingLink(token, data).then(data => {
                if (data) {
                    if (data.error) {

                    } else if (data.link) {
                        setLink(data.link)
                    }
                } 
            })
        }
    }
    const handleSchedule = (e) => {

        e.preventDefault();
        const Errors = validate(false);
        if (Errors != "") {
            errorIn.current = Errors.errorIn;
            errorMessage.current = Errors.error;
            setRefresh(!refresh);
        } else {
            const committeeId = committee._id
            const data = { description, link, meetingType, date, venue, projects, committeeId };
            
            const token = isLoggedIn().token
            setLoading(true)
            createMeeting(token, data).then(data => {
                if (data) {
                    if (data.error) {
                        setMessage(data.error)
                        setLoading(false);
                        setOpen(true);
                    }
                    else {
                        setMessage(data.message)
                        setSuccess(true);
                        setLoading(false);
                        setOpen(true);
                    }
                }
                else {
                    setMessage("Unable to connect")
                    setLoading(false);
                    setOpen(true);
                }
            })
        }

    };
    return (
        <Card elevation={2}>
            <CardContent>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    {success ? (
                        <Alert onClose={handleClose} severity="success">
                            {message === "" ? "Meeting Scheduled Successfully!" : message}
                        </Alert>
                    ) : (
                        <Alert onClose={handleClose} severity="error">
                            {message === "" ? "Meeting Scheduled Successfully!" : message}
                        </Alert>
                    )}
                </Snackbar>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="description"
                            label="Description"
                            multiline
                            rows={1}
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                                errorIn.current = "";
                            }}
                            error={errorIn.current === "description"}
                            helperText={
                                errorIn.current === "description"
                                    ? errorMessage.current
                                    : `${description.length}/500`
                            }
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                                <DateTimePicker
                                    disablePast
                                    value={date}
                                    onChange={(e) => {
                                        setDate(e);
                                        errorIn.current = "";
                                    }}
                                    error={errorIn.current === "date"}
                                    helperText={
                                        errorIn.current === "date" ? errorMessage.current : ""
                                    }
                                    label="Meeting Time"
                                    fullWidth
                                    format="MMMM d, yyyy, h:mm a"
                                    variant="inline"
                                    allowKeyboardControl
                                    animateYearScrolling
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <SelectProjects
                            error={errorIn}
                            selectedProjects={projects}
                            setSelectedProjects={setProjects}
                            committeeId={committee._id}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Meeting Type</FormLabel>
                            <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                value={meetingType}
                                onChange={(e) => {
                                    setMeetingType(e.target.value);
                                    errorIn.current = "";
                                }}
                            >
                                <FormControlLabel
                                    value="Physical"
                                    control={<Radio color="primary" />}
                                    label="Physical"
                                    style={{ marginRight: "5rem" }}
                                />
                                <FormControlLabel
                                    value="Remote"
                                    control={<Radio color="primary" />}
                                    label="Remote"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {meetingType === "Physical" && (
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                id="venue"
                                label="Venue"
                                value={venue}
                                multiline
                                rows={1}
                                onChange={(e) => {
                                    setVenue(e.target.value);
                                    errorIn.current = "";
                                }}
                                error={errorIn.current === "venue"}
                                helperText={
                                    errorIn.current === "venue"
                                        ? errorMessage.current
                                        : `${venue.length}/500`
                                }
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    )}
                    {meetingType === "Remote" && (
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                id="link"
                                label="Link"
                                value={link}
                                onChange={(e) => {
                                    setLink(e.target.value);
                                    errorIn.current = "";
                                }}
                                error={errorIn.current === "link"}
                                helperText={
                                    errorIn.current === "link"
                                        ? errorMessage.current
                                        : `${link.length}/500`
                                }
                                variant="outlined"
                                fullWidth
                            />
                            <Box display="flex" justifyContent="flex-end" m={0} p={0}>
                                <Link href="#" onClick={() => generateLink()}>Generate Link</Link>
                            </Box>
                        </Grid>
                    )}
                    {loading && (
                        <Grid item xs={12} sm={12}>
                            <Box display="flex" justifyContent="center">
                                <CircularProgress />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
            <CardActions style={{ display: "flex", justifyContent: "flex-end", padding: "1rem" }}>

                <Button onClick={handleSchedule} variant="outlined" color="primary">
                    Create Meeting
                </Button>

            </CardActions>
        </Card>
    );
};

export default MeetingsScheduler;