import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CircularProgress, TableRow } from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";

import { findProject } from "../apiCalls/projectCalls";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ProjectView from "./ProjectView"
import ProjectViewGRC from "./ProjectViewGRC";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


  
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


  


const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: ".3rem",
  },
})(MuiTableCell);

const useStyles = makeStyles({
    increaseSize:{
        minWidth:"70rem",
        maxWidth:"200rem"
    },
  root: {
    minWidth: 275,
    padding: "0px",
    margin: "0px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    width: "100%",
  },
});


export default function StudentDetail({ user }) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projectExist, setProjectExist] = useState("");
  const [projectId, setProjectId]= useState("")
  const { created, department, email, name, phone_number, status, _id } = user;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getProjectData = () => {
      setLoading(true)
    findProject(_id).then(data => {
        if (data) {
            if (data.error) {
                setProjectExist("not-exist")
                setLoading(false)
            }
            else if (data.message === "Project Exists") {
                setProjectExist("exist");
                setProjectId(data.projectId);
                setLoading(false)
                handleClickOpen()
            }
        } else {
            setProjectExist("can't find")
            setLoading(false)
        }
    });
  };
  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <TableRow>
            <TableCell style={{ fontWeight: 500 }}>Name:</TableCell>
            <TableCell>{name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: 500 }}>Email:</TableCell>
            <TableCell>{email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: 500 }}>Degree:</TableCell>
            <TableCell>{department}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: 500 }}>Status:</TableCell>
            <TableCell>{status}</TableCell>
          </TableRow>
          {phone_number && (
            <TableRow>
              <TableCell style={{ fontWeight: 500 }}>Phone:</TableCell>
              <TableCell>{phone_number}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell style={{ fontWeight: 500 }}>Created:</TableCell>
            <TableCell>{new Date(created).toDateString()}</TableCell>
          </TableRow>
          {loading ? (
            <div
              className={classes.root}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress />
            </div>
          ) : projectExist === "not-exist" ? (
            <TableRow style={{ display: "flex", justifyContent: "center" }}>
              <TableCell style={{ color: "red", textAlign: "center" }}>
                No Project Found
              </TableCell>
            </TableRow>
          ) : projectExist === "can't find" ? (
            <TableRow style={{ display: "flex", justifyContent: "center" }}>
              <TableCell style={{ color: "red", textAlign: "center" }}>
                Unable to find project
              </TableCell>
            </TableRow>
          ) : (
            <Button
              className={classes.button}
              onClick={getProjectData}
              variant="contained"
            >
              Student Project
            </Button>
          )}
        </CardContent>
      </Card>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paperWidthSm: classes.increaseSize }}
      >
        <DialogTitle
          onClose={handleClose}
          style={{ maxWidth: "600rem", minWidth: "90rem" }}
        >
          Student Data
        </DialogTitle>
        <DialogContent
          dividers
          style={{ maxWidth: "600rem", minWidth: "90rem" }}
        >
          {user.degreeLevel === "UGRD" ? (
            <ProjectView userId={_id} />
          ) : (
            <ProjectViewGRC userId={_id} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
