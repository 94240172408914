import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DeleteIcon from "@material-ui/icons/Delete";
import Link from "@material-ui/core/Link";
import {deleteResource} from "../../apiCalls/resourceCalls";
const {serverURL} =require("../../apiCalls/helper")
const useStyles = makeStyles((theme) => ({
  list: {
    boxShadow:"0px 0px 1px 0px rgb(0 0 0 / 20%), 0px 0px 5px 1px rgb(0 0 0 / 14%), 1px -1px 0px 0px rgb(0 0 0 / 12%)",
    borderRadius: "4px",
    marginBottom: theme.spacing(1),
    marginRight:theme.spacing(1)
  },
  bold: {
    fontSize: "12px",
    display: "block",
    lineBreak: "anywhere",
  },
  fileIcon: {
    fontSize: "5rem",
    color: "#37474f",
  },
}));

const Resource = ({
  resource,
  index,
  admin,
  refreshResources,
  setRefreshResources,
}) => {
  const classes = useStyles();

  const handleDelete = (e) => {
    deleteResource(resource._id, resource.level,resource.fileName).then(data=>{
      if(data && !data.error&&data.message){
        setRefreshResources(!refreshResources);
      }
    })
  };
 

  return (
  
      <Grid
        item
        xs={12}
        sm={4}
        md={3}
        lg={2}
        xl={1}
        key={index}
        className={admin?classes.list:""}
      >
        <Link
          href={`${serverURL}/${resource.level}/${resource.fileName}`}
          target="_blank"
        >
          <IconButton edge="start" size="medium">
            <PictureAsPdfIcon className={classes.fileIcon} />
          </IconButton>
          <Typography className={classes.bold}>
            {resource.displayName}
          </Typography>
        </Link>
        {admin && (
          <IconButton
            onClick={handleDelete}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>


  );
};
export default Resource;
