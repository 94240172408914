import React, { useEffect, useState } from "react";
import EditAdminForm from "./EditAdminForm";
import EditProfessorForm from "./EditProfessorForm";
import EditStudentForm from "./EditStudentForm";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import AdminDetail from "./AdminDetail";
import StudentDetail from "./StudentDetail";
import ProfessorDetail from "./ProfessorDetail";
import { isLoggedIn } from "../helpers/authenticationHelp";

const styles = makeStyles((theme) => ({
    paper: {
        //marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "green",
    },
    root: {
        marginTop: theme.spacing(2),
        minWidth: "200px",
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));

const UserDetail = (props) => {
    const classes = styles();
    //const [id, setId] = useState(record._id);
    const { record, recordType, view } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [userData, setUserData] = useState(record);


    if (error) {
        return (
            <div className={classes.root}>
                <Alert severity="error">{error}</Alert>
            </div>
        );
    }
    if (!view)
        return (
            <>
                {
                    (loading)
                    &&
                    <Box
                        className={classes.root}
                        display="flex"
                        justifyContent="center">
                        <CircularProgress />
                    </Box>
                }
                <Box
                    className={classes.root}
                    display="flex"
                    justifyContent="center">
                    {
                        (recordType === "Admin" && (<EditAdminForm user={userData} />))
                        ||
                        (recordType === "Student" && (<EditStudentForm user={userData} />))
                        ||
                        (recordType === "Professor" && (<EditProfessorForm user={userData} />))
                        ||
                        (recordType === "Program-Office" && (<EditAdminForm user={userData} />))
                        ||
                        (recordType === "External-Evaluator" && (<EditAdminForm user={userData} />))
                    }
                </Box>
            </>
        );
    if (view)
        return (
            <>
                {
                    (loading)
                    &&
                    <Box
                        className={classes.root}
                        display="flex"
                        justifyContent="center">
                        <CircularProgress />
                    </Box>
                }
                <Box
                    className={classes.root}
                    display="flex"
                    justifyContent="center">
                    {
                        (recordType === "Admin" && (<AdminDetail user={userData} />))
                        ||
                        (recordType === "Student" && (<StudentDetail user={userData} />))
                        ||
                        (recordType === "Professor" && (<ProfessorDetail user={userData} />))
                        ||
                        (recordType === "Program-Office" && (<AdminDetail user={userData} />))
                        ||
                        (recordType === "External-Evaluator" && (<AdminDetail user={userData} />))
                    }
                </Box>
            </>
        );
};

export default UserDetail;
