import React, { useState, useEffect } from "react";
//---
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";

import TableBody from "@material-ui/core/TableBody";
import { TableRow, TextField } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EditIcon from "@material-ui/icons/Edit";
import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import MuiTableCell from "@material-ui/core/TableCell";
import ProjectView from "./ProjectView";
import Snackbar from "@material-ui/core/Snackbar";

//---
import {
  projectsByCommittee,
  updateProjectStatus,
  appointEvaluators,
  projectMarks,
} from "../apiCalls/projectCalls";
import { isLoggedIn } from "../helpers/authenticationHelp";
import SearchProfessor from "./SearchProfessor";
import SearchEvaluator from "./SearchEvaluator";
import {
  WAIT,
  ERROR,
  SUCCESS,
  REJECTED,
  PENDING,
  APPROVED,
} from "../variables/enums";
import { CallMerge } from "@material-ui/icons";
const GreenButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
const materialTheme = createMuiTheme({
  palette: {
    primary: green,
  },
  overrides: {
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: "white",
      },
      toolbarTxt: {
        color: "#c8e6c9",
      },
    },
    MuiTabs: {
      scroller: {
        color: "#eeeeee",
      },
    },
  },
});
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  bold: {
    fontWeight: "bold",
  },
});

const TableCell = withStyles({
  root: {
    //borderBottom: "none",
    minWidth: "15rem",
    padding: ".8rem",
  },
})(MuiTableCell);

const styles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "green",
  },
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  "@keyframes delayedHide": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },

  autoHide: {
    animationName: "delayedHide",
    animationDuration: "300ms",
    animationDelay: (props) => props.delay || "2s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
}));

export default function Row({ project, committee, refresh, setRefresh }) {
  const classes101 = styles();
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const [supervisor, setSupervisor] = useState({});
  const [requestStatus, setRequestStatus] = useState({ msg: "", status: "" });
  const [Internal, setInternal] = useState({});
  const [InternalDate, setInternalDate] = useState(Date.now());
  const [InternalVenue, setInternalVenue] = useState("");

  const [External, setExternal] = useState({});
  const [ExternalDate, setExternalDate] = useState(Date.now());
  const [ExternalVenue, setExeternalVenue] = useState("");
  const [internalMarks, setInternalMarks] = useState(0);
  const [externalMarks, setExternalMarks] = useState(0);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const changeProjectStatus = (projectId, status) => {
    const projectStatus = { status, supervisor };
    setRequestStatus({ status: WAIT, msg: "" });
    updateProjectStatus(projectId, projectStatus).then((data) => {
      if (data) {
        if (data.error) {
          setRequestStatus({ status: ERROR, msg: data.error });
        } else {
          project.projectStatus = data.status;
          project.supervisor = { name: supervisor.name };
          setRequestStatus({ status: SUCCESS, msg: data.message });
        }
      } else {
        setRequestStatus({ status: ERROR, msg: "Unable to connect!" });
      }
    });
  };
  const markAsComplete = (e) => {
    e.preventDefault();
    // we check if the marks are correctly distributed
    if (
      parseInt(internalMarks) < 1 ||
      parseInt(internalMarks) > 80 ||
      parseInt(externalMarks) < 1 ||
      parseInt(externalMarks) > 80 ||
      parseInt(internalMarks) +
      parseInt(externalMarks) +
      parseInt(project.marks.supervisor) <
      3 ||
      parseInt(internalMarks) +
      parseInt(externalMarks) +
      parseInt(project.marks.supervisor) >
      98
    ) {
      setError("Invalid Marks Distribution!");
      setOpenSnack(true);
    } else {
      // now we can update the marks
      setLoading(true);
      const projectMarking = { internalMarks, externalMarks };
      projectMarks(project._id, projectMarking).then((data) => {
        if (data) {
          if (data.error) {
            setError(data.error);
            setOpenSnack(true);
            setLoading(false);
          } else {
            setError(data.message);
            project.marks.internal = internalMarks;
            project.marks.external = externalMarks;
            project.projectStatus = "Completed"
            setLoading(false);
          }
        } else {
          setError("Connection Error!");
          setOpenSnack(true);
          setLoading(false);
        }
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const currentDate = Date.now();
    const inDate = new Date(ExternalDate);
    const exDate = new Date(InternalDate);
    //incase the dates are older than now
    if (currentDate > inDate.getTime() || currentDate > exDate.getTime()) {
      setError("Invalid Dates");
      setOpenSnack(true);
    } else if (ExternalVenue === "" || InternalVenue === "") {
      setError("Invalid Venue provided!");
      setOpenSnack(true);
    } else {
      setLoading(true);
      setError("")
      const evaluationData = {
        internal: {
          examiner: Internal._id,
          date: InternalDate,
          venue: InternalVenue,
        },
        external: {
          examiner: External._id,
          date: ExternalDate,
          venue: ExternalVenue,
        },
      };
      appointEvaluators(project._id, evaluationData).then((data) => {
        if (data) {
          if (data.error) {
            setError(data.error);
            setLoading(false);
            setOpenSnack(true);
          } else {
            setRefresh(refresh + 1);
            setLoading(false);

          }
        } else {
          setError("Failed to connect to server!");
          setOpenSnack(true);
          setLoading(false);
        }
      });
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root} style={{ backgroundColor: "#e2ffde" }} onClick={() => setOpen(!open)}>
        <TableCell component="th" scope="row">
          <h3>{project.title}</h3>
        </TableCell>
        <TableCell>
          <h4>{project.groupName}</h4>
        </TableCell>
        <TableCell>
          <h4>{project.department}</h4>
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {committee.chairman === isLoggedIn().user._id && (
              <Card style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <div style={{ margin: "1rem" }}>
                  <Box>
                    <Typography
                      className={classes.bold}
                      style={{ marginBottom: "2rem" }}
                    >
                      Chariman Controls
                    </Typography>
                  </Box>

                  {/* place where we will show the controls in case of defence committee */}
                  {/* project will be in defence committee when it is ready for defence */}
                  {project.projectStatus === "Ready for Defence" &&
                    // these should be another check to find out if they are already appointed
                    (project.internal.examiner && project.external.examiner ? (
                      <>
                        <Table>
                          <TableRow style={{ width: "100%" }}>
                            <TableCell>Project Supervisor</TableCell>
                            <TableCell>{project.supervisor.name}</TableCell>
                            <TableCell style={{ paddingLeft: "5rem" }}>
                              {project.marks.supervisor}
                            </TableCell>
                          </TableRow>
                          {project.marks.p1Marks &&
                            <TableRow style={{ width: "100%" }}>
                              <TableCell>P1 Marks</TableCell>
                              <TableCell>{project.supervisor.name}</TableCell>
                              <TableCell style={{ paddingLeft: "5rem" }}>
                                {project.marks.p1Marks}
                              </TableCell>
                            </TableRow>
                          }
                          <TableRow>
                            <TableCell>Project Internal</TableCell>
                            <TableCell>
                              {project.internal.examiner.name}
                            </TableCell>
                            {project.marks.internal ? (
                              <TableCell style={{ paddingLeft: "5rem" }}>
                                {project.marks.internal}
                              </TableCell>
                            ) : (
                              <TableCell style={{ paddingLeft: "4rem" }}>
                                <form
                                  className={classes.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    id="standard-basic"
                                    label="Marks"
                                    type="number"
                                    min="0"
                                    value={internalMarks}
                                    onChange={(e) =>
                                      setInternalMarks(e.target.value)
                                    }
                                    InputProps={{
                                      inputProps: { min: 1, max: 60 },
                                    }}
                                  />
                                </form>
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell>Project External</TableCell>
                            <TableCell>
                              {project.external.examiner.name}
                            </TableCell>
                            {project.marks.external ? (
                              <TableCell style={{ paddingLeft: "5rem" }}>
                                {project.marks.external}
                              </TableCell>
                            ) : (
                              <TableCell style={{ paddingLeft: "4rem" }}>
                                <form
                                  className={classes.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    id="standard-basic"
                                    label="Marks"
                                    type="number"
                                    min="0"
                                    value={externalMarks}
                                    onChange={(e) =>
                                      setExternalMarks(e.target.value)
                                    }
                                    InputProps={{
                                      inputProps: { min: 1, max: 60 },
                                    }}
                                  />
                                </form>
                              </TableCell>
                            )}
                          </TableRow>
                        </Table>
                        <Box
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <>
                              {!(
                                project.marks.internal && project.marks.external
                              ) && (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    href="#contained-buttons"
                                    style={{ color: "white", marginTop: "1rem" }}
                                    onClick={markAsComplete}
                                  >
                                    Mark Project as Complete
                                  </Button>
                                )}
                            </>
                          )}
                        </Box>
                      </>
                    ) : (
                      <form
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        style={{ margin: "2rem" }}
                      >
                        {/* an internal examiner will be assigned */}
                        <SearchProfessor
                          groupMember={Internal}
                          setGroupMember={setInternal}
                          label={"Internal Evaluator"}
                          style={{ margin: "1rem 1rem 1rem 0rem" }}
                        />
                        {/* date and venu is also  required */}
                        <Box style={{ display: "flex" }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                              <DateTimePicker
                                style={{ width: "14rem", marginRight: "auto" }}
                                disablePast
                                value={InternalDate}
                                onChange={(e) => {
                                  setInternalDate(e);
                                }}
                                label="Internal Time"
                                fullWidth
                                format="MMMM d, yyyy, h:mm a"
                                variant="inline"
                                allowKeyboardControl
                                animateYearScrolling
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                          <TextField
                            value={InternalVenue}
                            onChange={(e) => setInternalVenue(e.target.value)}
                            multiline
                            rows="1"
                            variant="outlined"
                            size="small"
                            label="Internal Venue"
                            style={{ marginTop: ".5rem", minWidth: "14rem" }}
                          />
                        </Box>
                        {/* an external examiner will be assigned */}
                        <SearchEvaluator
                          groupMember={External}
                          setGroupMember={setExternal}
                          label={"External Evaluator"}
                          style={{ marginTop: "1rem" }}
                        />
                        {/* date and venu is also  required */}
                        <Box style={{ display: "flex" }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                              <DateTimePicker
                                style={{ width: "14rem", marginRight: "auto" }}
                                disablePast
                                value={ExternalDate}
                                onChange={(e) => {
                                  setExternalDate(e);
                                }}
                                label="External Time"
                                fullWidth
                                format="MMMM d, yyyy, h:mm a"
                                variant="inline"
                                allowKeyboardControl
                                animateYearScrolling
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                          <TextField
                            value={ExternalVenue}
                            onChange={(e) => setExeternalVenue(e.target.value)}
                            variant="outlined"
                            size="small"
                            label="External Venue"
                            style={{ marginTop: ".5rem", minWidth: "14rem" }}
                          />
                        </Box>
                        {loading ? (
                          <Box display="flex" justifyContent="center">
                            <CircularProgress />
                          </Box>
                        ) : (
                          <GreenButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            style={{ marginTop: "1rem" }}
                            disabled={
                              Internal._id && External._id ? false : true
                            }
                          >
                            Assign Evaluators
                          </GreenButton>
                        )}
                      </form>
                    ))}
                  {/* these things will show up in proposal committee only */}
                  {project.projectStatus !== "Ready for Defence" && (
                    <Table className={classes.table} aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell>Project Status</TableCell>
                          <TableCell>{project.projectStatus}</TableCell>
                        </TableRow>
                        {project.projectStatus === "Approved" && (
                          <TableRow>
                            <TableCell>Supervisor</TableCell>
                            <TableCell>{project.supervisor.name}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  )}
                  {project.projectStatus === PENDING && (
                    <SearchProfessor
                      groupMember={supervisor}
                      setGroupMember={setSupervisor}
                      label={"Project Supervisor"}
                    />
                  )}
                  {requestStatus.status === WAIT && (
                    <Box
                      className={classes101.root}
                      display="flex"
                      justifyContent="center"
                    >
                      <CircularProgress />
                    </Box>
                  )}
                  {project.projectStatus === PENDING && (
                    <Box mt={1} mb={1}>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() =>
                            changeProjectStatus(project._id, REJECTED)
                          }
                        >
                          Reject Project
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ marginLeft: "1rem" }}
                          disabled={supervisor._id ? false : true}
                          onClick={() =>
                            changeProjectStatus(project._id, APPROVED)
                          }
                        >
                          Approve Project
                        </Button>
                      </div>
                    </Box>
                  )}
                  {(project.projectStatus === APPROVED ||
                    project.projectStatus === REJECTED) && (
                      <Box mt={1} mb={1}>
                        <div
                          style={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                              changeProjectStatus(project._id, PENDING)
                            }
                          >
                            Revert Decision
                          </Button>
                        </div>
                      </Box>
                    )}
                </div>
              </Card>
            )}
            <Box mb={2}>
              <ProjectView project={project} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}

