const { isLoggedIn } = require("../helpers/authenticationHelp");
const { serverURL } = require("./helper");

export const broadCast = (message) => {
  isLoggedIn();
  return fetch(`${serverURL}/broadcast`, {
    method: "POST",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${isLoggedIn().token}`,

    },
    body: JSON.stringify(message),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getbroadCasts = (page, rowsPerPage) => {
  isLoggedIn();
  return fetch(
    `${serverURL}/broadcastmessages?` +
      new URLSearchParams({ page: page, rowsperpage: rowsPerPage }),
    {
      method: "GET",
   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${isLoggedIn().token}`,

      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
