const {serverURL}=require("../apiCalls/helper")

export const verifyUser = (code) => {
    return fetch(`${serverURL}/confirm/${code}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
};

export const deleteUser = (code) => {
    return fetch(`${serverURL}/delete/${code}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => console.log(err));
};