import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import FormDialog from "./FormDialog";
import UserSetting from "./UserSetting";

//---
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Box from "@material-ui/core/Box";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
//---
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";

//---
import Button from "./CustomButton";
import styles from "../../assets/jss/headerlinkStyles";
//---
import { signout } from "../apiCalls/authCalls";
import { ERROR, WAIT, SUCCESS } from "../variables/enums";
import { isLoggedIn, roleNow, changeRole } from "../helpers/authenticationHelp";
import { clearCache } from "../helpers/logoutHelp";
import { fetchNotifications } from "../apiCalls/userCalls";
const useStyles = makeStyles(styles);

const StudentNavbarLinks = () => {
  const classes = useStyles();
  const ref = useRef();

  const history = useHistory();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [notifications, setNotifications] = useState({
    list: [],
    isLoaded: WAIT,
    msg: "",
  });
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const isActive = (role) => {
    if (roleNow() === role) return classes.activeDropdownItem;
    else return classes.dropdownItem;
  };
  const handleLogout = () => {
    signout(() => {
      clearCache();
      history.push("/signin");
    });
    clearCache();
    history.push("/signin");
  };
  const handleRoleChange = (role) => {
    changeRole(role);
    history.push(`/${role.toLowerCase()}`);
    window.location.reload(false);
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(ref.current)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(ref.current);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const openPage = (e, notification) => {
    const role = roleNow();
    history.push(`/${role.toLowerCase()}${notification.redirectTo}`);
    setOpenNotification(null);
  };
  useEffect(() => {
    if (!isLoggedIn() || !isLoggedIn().user || !isLoggedIn().user.name) {
      handleLogout();
    }
    const token = isLoggedIn().token;
    fetchNotifications(token).then((data) => {
      if (data) {
        if (!data.error)
          setNotifications({ list: data.notifications, isLoaded: SUCCESS });
      }
    });
  }, [openProfile, setOpenProfile]);

  return (<>
    {isLoggedIn()?(
    <div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          <span className={classes.notifications}>
            {notifications.isLoaded === SUCCESS
              ? notifications.list.length
              : ""}
          </span>
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {notifications.isLoaded === SUCCESS ? (
                      notifications.list.map((notification, index) => (
                        <>
                          <MenuItem
                            onClick={(e) => openPage(e, notification)}
                            className={classes.dropdownItem}
                          >
                            {notification.message}
                          </MenuItem>
                          {index !== notifications.list.length - 1 && (
                            <Divider />
                          )}
                        </>
                      ))
                    ) : (
                      <MenuItem
                        onClick={handleCloseNotification}
                        className={classes.dropdownItem}
                      >
                        No Notifications
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <div className={classes.manager}>
        <div ref={ref} onClick={handleClickProfile}>
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={openProfile ? "profile-menu-list-grow" : null}
            aria-haspopup="true"
            className={classes.buttonLink}
          >
            <Person className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Profile</p>
            </Hidden>
          </Button>
        </div>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <FormDialog
                      Form={<UserSetting />}
                      Title={
                        <>
                          {isLoggedIn().user.name}
                          <SettingsIcon style={{ marginLeft: "0.5rem" }} />{" "}
                        </>
                      }
                      Heading={`${isLoggedIn().user.name}'s Profile`}
                      buttonType={"MenuItem"}
                    />
                    {!isLoggedIn().user.roles.includes("Student") && (
                      <Box>
                        <Divider light />
                        <MenuList role="menu">
                          {isLoggedIn().user.roles.map((role, index) => {
                            return (
                              <MenuItem
                                key={index}
                                onClick={() => handleRoleChange(role)}
                                className={isActive(role)}
                              >
                                {role}
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                        <Divider light />
                      </Box>
                    )}
                    <MenuItem onClick={handleLogout}>
                      {"Logout"}
                      <ExitToAppIcon style={{ marginLeft: "0.5rem" }} />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>):(handleLogout)
  
  }</>
  );
};
export default StudentNavbarLinks;
