import React, { useState, useEffect } from "react";
//---
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import { TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import PageLayout from "../components/PageLayout";
import MuiTableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import FormDialog from "../components/FormDialog";
import ProjectView from "../components/ProjectView";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import RowGRCSupervision from "./RowGRCSupervision";
import RowUGPCSupervision from "./RowUGPCSupervision"

//----
import {
  WAIT,
  ERROR,
  SUCCESS,
  REJECTED,
  PENDING,
  APPROVED,
} from "../variables/enums";
import {
  projectsBySupervisor,
  forwardForDefence,
} from "../apiCalls/projectCalls";
import Taskboard from "../components/TaskBoard/Taskboard.js";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    overflow: "hidden",
  },
  bold: {
    fontWeight: "bold",
  },
});

const TableCell = withStyles({
  root: {
    //borderBottom: "none",
    padding: ".8rem",
  },
})(MuiTableCell);

const styles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "green",
  },
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  "@keyframes delayedHide": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },

  autoHide: {
    animationName: "delayedHide",
    animationDuration: "300ms",
    animationDelay: (props) => props.delay || "2s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  table: {
    minWidth: 650,
  },
}));

export default function ProjectsUnderSupervision({ degreeLevel }) {
  const classes = styles();
  const [projects, setProjects] = useState({ list: [], status: WAIT, msg: "" });
  useEffect(() => {
    projectsBySupervisor(degreeLevel).then((data) => {
      if (data) {
        if (data.error) {
          setProjects({ status: ERROR, msg: data.error });
        } else
          setProjects({
            list: data.projects,
            status: SUCCESS,
            msg: data.message,
          });
      } else {
        setProjects({ status: ERROR, msg: "Unable to connect!" });
      }
    });
  }, [degreeLevel]);
  return (
    <div styles={{ width: "100%", height: "100%", padding: "0px" }}>
      {projects.status === WAIT ? (
        <Box className={classes.root} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : projects.status === ERROR ? (
        <div className={classes.root}>
          <Alert severity="info">{projects.msg}</Alert>
        </div>
      ) : projects.status === SUCCESS && projects.list.length === 0 ? (
        <div className={classes.root}>
          <Alert severity="info">{projects.msg}</Alert>
        </div>
      ) : (
        <CardContent style={{ padding: "0px", margin: "0px" }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                {projects.list.map((project, key) => {
                  if (project.degreeLevel === "UGRD") {
                    return <RowUGPCSupervision key={key} project={project} />;
                  } else {
                    return <RowGRCSupervision key={key} project={project} />;
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      )}
    </div>
  );
}
