const { serverURL } = require("./helper");
export const signup = (student) => {
  return fetch(`${serverURL}/signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(student),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const signin = (user) => {
  return fetch(`${serverURL}/signin`, {
    method: "POST",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const signout = () => {
  return fetch(`${serverURL}/signout`, {
    method: "GET",
 
  })
    .then((response) => {
      return response.json;
    })
    .catch((err) => console.log(err));
};
export const forgotPassword = (email) => {
  return fetch(`${serverURL}/forgot-password`, {
    method: "PUT",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(email),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const newPassword = (resetId, Password) => {
  return fetch(`${serverURL}/reset-password/${resetId}`, {
    method: "PUT",
 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(Password),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
